<template>
  <v-app id="keep">
    <template>
      <v-dialog
        v-model="birthdaypopup"
        transition="dialog-top-transition"
        max-width="600"
      >
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="primary">
              <v-card-title
                :class="{
                  'white--text': !$vuetify.theme.dark,
                  'black--text': $vuetify.theme.dark,
                }"
                >People celebrating their birthday today</v-card-title
              >
            </v-toolbar>
            <v-card-text class="pa-0">
              <v-img
                :src="require('src/assets/bdayimg.jpg')"
                aspect-ratio="3"
              ></v-img>
              <v-card-text class="text-center font-weight-medium">
                Go ahead and wish your friends who are celebrating their
                birthday today
              </v-card-text>
              <h5
                class="primary--text text-center font-weight-bold text-uppercase"
              >
                Happy Birthday
              </h5>
              <div v-for="birthday in birthdayusers" :key="birthday._id">
                <div class="text-center">
                  <v-row class="justify-center">
                    <v-card-title
                      class="birthdaylink pa-0 pt-2 font-weight-bold"
                      @click="CreateChatroomChangeurl(birthday)"
                    >
                      <span
                        style="cursor: pointer"
                        class="capitalizeuser pointeravatar"
                      >
                        {{
                          " " +
                          birthday.firstName +
                          " " +
                          birthday.lastName +
                          " (" +
                          birthday.bdaydate +
                          ")"
                        }}
                      </span>
                    </v-card-title>
                  </v-row>
                </div>
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialog.value = false" class="red--text"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </template>
    <div
      class="test-link"
      :class="[switch1 == true ? 'activeMember' : 'inactiveMember']"
    >
      <v-overlay
        v-if="usersMemberOk == true && dontShowTool != false && switch1 == true"
        style="top: 56px"
        @click="usersMemberOk = false"
      ></v-overlay>
      <div
        v-if="usersMemberOk == true && dontShowTool != false && switch1 == true"
        class="common-tooltip tooltip-top primary"
      >
        <div
          class="common-class common-tooltip-two"
          :class="{
            'white--text': !$vuetify.theme.dark,
            'black--text': $vuetify.theme.dark,
          }"
        >
          The timer logged for today for any tasks will be displayed once you go
          offline for the day<br />
          <v-btn @click="cancelTooltip()" class="ma-1" small>OK</v-btn>
          <v-btn @click="dontShowAgain()" class="ma-1" small
            >Don't show again</v-btn
          >
        </div>
      </div>
    </div>
    <v-app-bar app clipped-right color="primary" class="header-app-bar">
      <v-row class="flex-nowrap">
        <v-col
          class="d-flex align-center justify-start pa-0"
          cols="5"
          sm="7"
          md="7"
        >
          <v-app-bar-nav-icon
            @click="toggleDrawer"
            :class="{
              'white--text': !$vuetify.theme.dark,
              white: $vuetify.theme.dark,
            }"
          ></v-app-bar-nav-icon>
          <div @click="resetfilters" v-if="windowWidth > 500">
            <div @click="gotomywork">
              <img
                v-show="layoutcolor == 'Dark'"
                class="logo-header-dark"
                src="@/assets/dark-mode-logo.png"
                alt="Focts Logo"
                style="border: 0"
              />
              <img
                v-show="layoutcolor != 'Dark'"
                class="logo-header-light"
                src="@/assets/foctus-logo-whitebg-newbg.png"
                alt="Focts Logo"
                style="border: 0"
              />
            </div>
          </div>

          <v-autocomplete
            v-if="user.isAdmin == false && searchMobile == false"
            v-model="model"
            prepend-inner-icon="search"
            :items="allprojects"
            item-text="name"
            item-value="_id"
            :search-input.sync="search"
            class="mx-4 d-none d-sm-flex"
            flat
            hide-no-data
            hide-details
            label="Search..."
            solo
            @keydown.enter="gotosearchpage(search)"
            @focus="focusInput()"
            @blur="focusInput()"
            id="autoComplete"
            ref="autoComplete"
          >
            <template v-slot:item="{ item }">
              <div
                v-if="item.project"
                class="d-flex align-center flex-grow-1"
                @click="gotoredirect(item, 'task')"
              >
                <div class="flex-grow-1">
                  {{ item.name }}
                </div>
                <div class="flex-grow-1 d-flex justify-end">
                  <v-chip class="text-capitalize font-weight-medium"
                    >task</v-chip
                  >
                </div>
              </div>
              <div
                v-if="!item.project"
                class="d-flex align-center flex-grow-1"
                @click="gotoredirect(item, 'project')"
              >
                <div class="flex-grow-1">
                  {{ item.name }}
                </div>
                <div class="flex-grow-1 d-flex justify-end">
                  <v-chip left class="text-capitalize font-weight-medium"
                    >project</v-chip
                  >
                </div>
              </div>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col class="d-flex align-center justify-end" cols="7" sm="5" md="5">
          <div class="icon-life">
            <v-btn
              regular
              icon
              @click="searchMobile = !searchMobile"
              class="transparent elevation-0 search-icon-button d-flex d-sm-none d-md-none"
            >
              <v-icon
                :class="{
                  'white--text': !$vuetify.theme.dark,
                  'black--text': $vuetify.theme.dark,
                }"
                >search</v-icon
              >
            </v-btn>
          </div>
          <v-tooltip
            bottom
            v-if="
              user.allcompanies.find(
                (data) => data.id == $route.query.companyId
              ) &&
              (user.allcompanies.find(
                (data) => data.id == $route.query.companyId
              ).isAdmin == true ||
                user.allcompanies.find(
                  (data) => data.id == $route.query.companyId
                ).isManager == true)
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="quick-start-section ma-2">
                <div class="quick-start-but">
                  <div class="quick-waves-block">
                    <div class="quick-waves quick-wave-1"></div>
                    <div class="quick-waves quick-wave-2"></div>
                    <div class="quick-waves quick-wave-3"></div>
                  </div>
                </div>
                <v-btn
                  icon
                  v-bind="attrs"
                  class="quick-start-button white"
                  v-on="on"
                  @click="createProjectAndWorkspace = true"
                >
                  <v-icon
                    :class="{
                      'primary--text': !$vuetify.theme.dark,
                      'black--text': $vuetify.theme.dark,
                    }"
                    >add</v-icon
                  >
                </v-btn>
              </div>
            </template>
            <span>Quick Start</span>
          </v-tooltip>
          <!-- <v-tooltip bottom v-if="apsanticonshowstatus != 0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                v-bind="attrs"
                v-on="on"
                x-small
                @click="sheet = !sheet"
                icon
              >
                <v-icon
                  :class="{
                    'white--text': !$vuetify.theme.dark,
                    'black--text': $vuetify.theme.dark,
                  }"
                  >person_off</v-icon
                >
              </v-btn>
            </template>
            <span>Members Absent</span>
          </v-tooltip> -->

          <template>
            <div class="text-center d-flex align-center quick-start">
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="brightness_icon">
                    <v-btn
                      icon
                      @click="toggle_dark_mode"
                      v-bind="attrs"
                      v-on="on"
                      class="d-none d-sm-flex"
                    >
                      <v-icon
                        v-if="$vuetify.theme.dark"
                        :class="{
                          'white--text': !$vuetify.theme.dark,
                          'black--text': $vuetify.theme.dark,
                        }"
                        >brightness_4</v-icon
                      >
                      <v-icon
                        v-if="!$vuetify.theme.dark"
                        :class="{
                          'white--text': !$vuetify.theme.dark,
                          'black--text': $vuetify.theme.dark,
                        }"
                        >brightness_7</v-icon
                      >
                    </v-btn>
                  </div>
                </template>

                <span v-if="$vuetify.theme.dark"
                  >Switch to the light theme</span
                >
                <span v-if="!$vuetify.theme.dark"
                  >Switch to the dark theme</span
                >
              </v-tooltip> -->
              <v-tooltip left v-if="user.isAdmin == false">
                <template v-slot:activator="{ on, attrs }">
                  <div>
                    <v-btn
                      icon
                      @click.stop="drawerPinned = !drawerPinned"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                        :class="{
                          'white--text': !$vuetify.theme.dark,
                          'black--text': $vuetify.theme.dark,
                        }"
                        >push_pin</v-icon
                      >
                    </v-btn>
                  </div>
                </template>
                <span>Pinned Task</span>
              </v-tooltip>
            </div>
          </template>
          <v-tooltip left v-if="!user.isAdmin">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                class="ml-1 text-uppercase white"
                v-bind="attrs"
                v-on="on"
                small
                @click.stop="drawerWorkspace()"
              >
                {{
                  $route.meta.section &&
                  ($route.meta.section == "workspace" ||
                    $route.meta.section == "project") &&
                  localworkspacename
                    ? localworkspacename.substr(0, 1)
                    : "W"
                }}</v-btn
              >
            </template>
            <span>
              {{
                $route.meta.section &&
                ($route.meta.section == "workspace" ||
                  $route.meta.section == "project") &&
                localworkspacename
                  ? localworkspacename
                  : "Workspace Name"
              }}</span
            >
          </v-tooltip>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawerPinned"
      temporary
      fixed
      app
      right
      class="elevation-5 navigation-drawer-scroll"
      width="300"
      :touchless="true"
    >
      <v-card class="pa-2 transparent elevation-0" v-if="user.isAdmin == false">
        <div
          class="text-body-2 font-weight-bold text-uppercase pa-2"
          :class="{
            'primary--text': !$vuetify.theme.dark,
            'black--text': $vuetify.theme.dark,
          }"
        >
          Pinned Tasks
        </div>
        <v-divider></v-divider>
        <div v-for="pinnedTask in pinnedTasksList" :key="pinnedTask.id">
          <v-row class="d-flex align-center pa-2">
            <v-col cols="12" class="pa-0">
              <div class="d-flex align-center">
                <div>
                  <v-icon
                    small
                    :class="{
                      'black--text': !$vuetify.theme.dark,
                      'black--text': $vuetify.theme.dark,
                    }"
                    >{{ gettaskicon(pinnedTask) }}</v-icon
                  >
                </div>
                <router-link
                  :to="{
                    name: 'single-task',
                    params: {
                      id: pinnedTask.project,
                      taskId: pinnedTask.id,
                    },
                    query: {
                      companyId: $route.query.companyId,
                    },
                  }"
                  class="black--text text-decoration-none pl-2 text-subtitle-1 flex-grow-1"
                  style="width: 150px"
                  >{{ pinnedTask.title }}
                </router-link>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </div>
      </v-card>
    </v-navigation-drawer>

    <!-- TODO: DO A MINI VARIANT FOR JUST SHOWING ICONS -->
    <v-navigation-drawer
      app
      color="primary"
      class="elevation-5 navigation-drawer-scroll toggle-add-sidebar mobile-menu-leftBar"
      :mini-variant="miniVariant"
      v-model="drawer"
      :touchless="true"
    >
      <v-list dense class="primary pt-0">
        <v-list-item v-if="windowWidth <= 500">
          <div @click="resetfilters" class="pt-2">
            <div @click="gotomywork">
              <img
                v-show="layoutcolor == 'Dark'"
                class="logo-header-dark"
                src="@/assets/dark-mode-logo.png"
                alt="Focts Logo"
                style="border: 0"
              />
              <img
                v-show="layoutcolor != 'Dark'"
                class="logo-header-light"
                src="@/assets/foctus-logo-whitebg-newbg.png"
                alt="Focts Logo"
                style="border: 0"
              />
            </div>
          </div>
        </v-list-item>
        <!-- <v-list-item class="d-flex d-sm-none d-md-none">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="toggle_dark_mode"
                v-bind="attrs"
                v-on="on"
                class="brightness_icon transparent elevation-0 pl-0"
              >
                <v-icon
                  v-if="$vuetify.theme.dark"
                  :class="{
                    'white--text': !$vuetify.theme.dark,
                    'black--text': $vuetify.theme.dark,
                  }"
                  >brightness_4</v-icon
                >
                <v-icon
                  v-if="!$vuetify.theme.dark"
                  :class="{
                    'white--text': !$vuetify.theme.dark,
                    'black--text': $vuetify.theme.dark,
                  }"
                  >brightness_7</v-icon
                >
                <div
                  v-if="$vuetify.theme.dark"
                  class="pl-7 text-body-2 font-weight-medium"
                  :class="{
                    'white--text': !$vuetify.theme.dark,
                    'black--text': $vuetify.theme.dark,
                  }"
                >
                  Dark Mode
                </div>
                <div
                  v-if="!$vuetify.theme.dark"
                  class="pl-7 text-body-2 font-weight-medium"
                  :class="{
                    'white--text': !$vuetify.theme.dark,
                    'black--text': $vuetify.theme.dark,
                  }"
                >
                  Light Mode
                </div>
              </v-btn>
            </template>
            <span v-if="$vuetify.theme.dark">Switch to the light theme</span>
            <span v-if="!$vuetify.theme.dark">Switch to the dark theme</span>
          </v-tooltip>
        </v-list-item> -->

        <companychange
          @openleaveuser="openleaveuser"
          :apsanticonshowstatus="apsanticonshowstatus"
          place="dashboardlayout"
        ></companychange>
        <v-divider color="#ffffff"></v-divider>

        <v-list>
          <v-list-item-group>
            <template v-for="(item, index) in items">
              <v-list-item
                @click="resetmyworkFilter(item)"
                :key="index"
                link
                :active-class="
                  $vuetify.theme.dark ? 'black--text' : 'white--text'
                "
                light
                class="left-navigation-item"
                :to="{
                  name: item.routeName,
                  query: item.query,
                }"
              >
                <v-list-item-action class="text--center">
                  <v-icon>{{ item.icon }}</v-icon>
                  <div
                    v-if="chatnotificationcount && item.routeName == 'chat'"
                    class="smallportiononicon"
                  ></div>
                  <div
                    v-if="
                      notificationcount > 0 && item.routeName == 'notifications'
                    "
                    class="smallportiononicon"
                  ></div>
                  <div
                    v-if="
                      leavenotifycount > 0 && item.routeName == 'leave-control'
                    "
                    class="smallportiononicon"
                  ></div>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="text-uppercase">
                    <div class="d-flex flex-row align-center">
                      {{ item.text }}
                      <div
                        v-if="item.text == 'Inbox' && notificationcount"
                        class="flex-grow-1 d-flex justify-end"
                      >
                        <v-btn class="transparent elevation-0">
                          <v-icon small>notifications</v-icon>
                          <span
                            :class="{
                              'white--text text-caption': !$vuetify.theme.dark,
                              'black--text text-caption': $vuetify.theme.dark,
                            }"
                            >{{ notificationcount }}</span
                          >
                        </v-btn>
                      </div>
                      <div
                        v-else-if="item.text == 'chat' && chatnotificationcount"
                        class="flex-grow-1 d-flex justify-end"
                      >
                        <v-btn class="transparent elevation-0">
                          <v-icon small>notifications_active</v-icon>
                          <span
                            :class="{
                              'white--text text-caption': !$vuetify.theme.dark,
                              'black--text text-caption': $vuetify.theme.dark,
                            }"
                            >{{ chatnotificationcount }}</span
                          >
                        </v-btn>
                      </div>

                      <div
                        v-else-if="
                          item.text == 'Holiday management' &&
                          leavenotifycount > 0
                        "
                        class="flex-grow-1 d-flex justify-end"
                      >
                        <v-btn class="transparent elevation-0">
                          <v-icon small>notifications_active</v-icon>
                          <span
                            :class="{
                              'white--text text-caption': !$vuetify.theme.dark,
                              'black--text text-caption': $vuetify.theme.dark,
                            }"
                            >{{ leavenotifycount }}</span
                          >
                        </v-btn>
                      </div>
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </v-list>

      <template v-slot:append>
        <v-divider></v-divider>
        <v-list>
          <v-list-item>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="200"
              offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-action>
                  <v-avatar
                    v-if="user.Profileimage"
                    size="30"
                    class="assigned-user header-assigned-menu"
                    @click="cancelTooltip()"
                  >
                    <v-img aspect-ratio="1" :src="user.Profileimage"></v-img>
                  </v-avatar>
                  <v-avatar
                    v-else
                    size="30"
                    dark
                    v-bind="attrs"
                    icon
                    @click="cancelTooltip()"
                    class="header-assigned-menu"
                  >
                    <v-icon
                      style="font-size: 30px"
                      :class="[
                        switch1 == true ? 'activeMember' : 'inactiveMember',
                      ]"
                      dark
                      >account_circle</v-icon
                    >
                  </v-avatar>
                </v-list-item-action>

                <v-btn icon v-on="on">
                  <v-icon
                    :class="{
                      'white--text': !$vuetify.theme.dark,
                      'black--text': $vuetify.theme.dark,
                    }"
                    >chevron_right</v-icon
                  >
                </v-btn>

                <v-list-item-title
                  class="text-uppercase"
                  :class="{
                    'white--text': !$vuetify.theme.dark,
                    'black--text': $vuetify.theme.dark,
                  }"
                  >{{ user.firstName }}</v-list-item-title
                >
              </template>

              <v-card>
                <v-list>
                  <v-list-item>
                    <v-list-item-avatar
                      @click="gotosingleuser(user.id)"
                      v-if="
                        user.Profileimage != '' &&
                        user.Profileimage != undefined
                      "
                    >
                      <v-img
                        class="pointeravatar"
                        :src="user.Profileimage"
                        aspect-ratio="1"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-avatar
                      @click="gotosingleuser(user.id)"
                      v-else
                      class="text-uppercase"
                      :class="{
                        'white--text primary': !$vuetify.theme.dark,
                        'black--text secondary': $vuetify.theme.dark,
                      }"
                    >
                      <span class="pointeravatar">{{
                        getShortName(user.firstName, user.lastName)
                      }}</span>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        class="font-weight-medium text-capitalize"
                      >
                        {{ user.firstName + " " + user.lastName }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="user.isAdmin == false">
                        <span
                          v-if="
                            user.allcompanies.find(
                              (data) => data.id == $route.query.companyId
                            ) &&
                            (user.allcompanies.find(
                              (data) => data.id == $route.query.companyId
                            ).isAdmin == true ||
                              user.allcompanies.find(
                                (data) => data.id == $route.query.companyId
                              ).isManager == true)
                          "
                        >
                          <p
                            v-if="
                              user.allcompanies.find(
                                (data) => data.id == $route.query.companyId
                              ).isAdmin
                            "
                          >
                            Admin
                          </p>
                          <p
                            v-else-if="
                              user.allcompanies.find(
                                (data) => data.id == $route.query.companyId
                              ).isManager
                            "
                          >
                            Manager
                          </p>
                        </span>
                        <span v-else>
                          <p>User</p>
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        :class="fav ? 'red--text' : ''"
                        icon
                        @click="fav = !fav"
                      >
                        <v-icon>mdi-heart</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <div class="pa-2 pt-3" v-if="user.isAdmin == false">
                  Shift hours :
                  <v-icon small>schedule</v-icon>&nbsp;
                  <b>{{
                    user.shiftHour + " h : " + user.shiftMinutes + " m"
                  }}</b>
                </div>
                <v-card-actions @click="logout">
                  <v-spacer></v-spacer>
                  <v-btn
                    block
                    type="submit"
                    class="mt-2"
                    :class="{
                      'gradient-button white--text': !$vuetify.theme.dark,
                      'white black--text': $vuetify.theme.dark,
                    }"
                    tile
                    color="#9e9e9ed1"
                    >log out
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid class="pa-0 lighten-1">
        <div class="mobile-search-background">
          <template>
            <v-form class="d-flex align-center">
              <v-autocomplete
                v-if="user.isAdmin == false && searchMobile == true"
                v-model="model"
                prepend-inner-icon="search"
                :items="allprojects"
                item-text="name"
                item-value="_id"
                :search-input.sync="search"
                class="mx-4 d-flex d-sm-none mt-3 mb-3 elevation-2 mobile-search-bar"
                :menu-props="{ bottom: true, offsetY: true }"
                flat
                hide-no-data
                hide-details
                label="Search..."
                solo
                @keydown.enter="gotosearchpage(search)"
                @focus="focusInput()"
                @blur="focusInput()"
                :class="{
                  white: !$vuetify.theme.dark,
                  black: $vuetify.theme.dark,
                }"
                id="autoComplete"
                ref="autoComplete"
              >
                <template v-slot:item="{ item }">
                  <div
                    v-if="item.project"
                    class="d-flex align-center flex-grow-1 pa-2"
                    @click="gotoredirect(item, 'task')"
                  >
                    <div class="flex-grow-1">
                      {{ item.name }}
                    </div>
                    <div class="flex-grow-1 d-flex justify-end">
                      <v-chip class="text-capitalize font-weight-medium"
                        >task</v-chip
                      >
                    </div>
                  </div>
                  <div
                    v-if="!item.project"
                    class="d-flex align-center flex-grow-1 pa-2"
                    @click="gotoredirect(item, 'project')"
                  >
                    <div class="flex-grow-1">
                      {{ item.name }}
                    </div>
                    <div class="flex-grow-1 d-flex justify-end">
                      <v-chip left class="text-capitalize font-weight-medium"
                        >project</v-chip
                      >
                    </div>
                  </div>
                </template>
              </v-autocomplete>
              <div
                v-if="user.isAdmin == false && searchMobile == true"
                class="pr-3"
              >
                <v-btn icon @click="searchMobile = !searchMobile">
                  <v-icon>cancel</v-icon>
                </v-btn>
              </div>
            </v-form>
          </template>
        </div>
        <router-view />
        <router-view name="modal" />
      </v-container>
    </v-main>

    <v-navigation-drawer
      v-model="drawerRight"
      id="workspace-navbar-sidebar"
      class="elevation-5 navigation-drawer-scroll workspace-navbar-sidebar"
      @transitionend="drawerWorkspaceToggle()"
      :touchless="true"
      temporary
      fixed
      right
      width="330"
    >
      <Workspaces />
    </v-navigation-drawer>

    <template>
      <div class="text-center">
        <v-bottom-sheet v-model="sheet">
          <v-sheet height="200px" class="overflow-y-auto">
            <div class="text-center">
              <v-btn class="mt-6" text color="red" @click="sheet = !sheet"
                >close</v-btn
              >
            </div>
            <h5 class="text-center font-weight-medium text-h6">
              Members Absent Today
            </h5>
            <v-divider></v-divider>
            <div class="actionsheetcontent">
              <div
                v-for="Absanduser in todayAbsandusers"
                :key="Absanduser.id"
                class="pa-3"
              >
                <span sm="2" v-if="checkusercanshow(Absanduser)">
                  <v-avatar
                    @click="gotosingleuser(Absanduser.id)"
                    size="30"
                    class="pointeravatar assigned-user mr-4"
                    :class="{
                      primary: !$vuetify.theme.dark,
                      black: $vuetify.theme.dark,
                    }"
                  >
                    <v-img
                      @click="gotosingleuser(Absanduser.id)"
                      v-if="Absanduser.Profileimage != ''"
                      :src="Absanduser.Profileimage"
                      aspect-ratio="1"
                    ></v-img>
                    <span
                      @click="gotosingleuser(Absanduser.id)"
                      v-else
                      class="white--text text-uppercase font-weight-medium"
                      :class="{
                        'white--text': !$vuetify.theme.dark,
                        'white--text': $vuetify.theme.dark,
                      }"
                    >
                      {{
                        getShortName(Absanduser.firstName, Absanduser.lastName)
                      }}
                    </span>
                  </v-avatar>
                </span>
                <span
                  v-if="checkusercanshow(Absanduser)"
                  sm="10"
                  class="text-capitalize font-weight-medium"
                >
                  {{ Absanduser.firstName + " " + Absanduser.lastName }}
                </span>
                <v-chip
                  v-if="
                    Absanduser.leaveData.halfDaystatus == true &&
                    checkusercanshow(Absanduser)
                  "
                  class="text-capitalize chip-class-wrap"
                  outlined
                  label
                  :class="{
                    'primary--text': !$vuetify.theme.dark,
                    white: $vuetify.theme.dark,
                  }"
                  >half day
                </v-chip>
                <span
                  v-if="
                    Absanduser.leaveData.halfDaystatus == true &&
                    checkusercanshow(Absanduser)
                  "
                >
                  from
                  {{
                    moment(
                      Absanduser.leaveData.date +
                        " " +
                        Absanduser.leaveData.startTime,
                      "YYYY-MM-DD hh:mm"
                    ).format("hh:mm a") +
                    " to " +
                    moment(
                      Absanduser.leaveData.date +
                        " " +
                        Absanduser.leaveData.endTime,
                      "YYYY-MM-DD hh:mm"
                    ).format("hh:mm a")
                  }}
                </span>
              </div>
            </div>
          </v-sheet>
        </v-bottom-sheet>
      </div>
    </template>
    <!-- SideBar Tooltip -->
    <div class="sidebar-tooltip-common d-none d-sm-flex d-md-flex">
      <div v-if="skipStartTooltip != true">
        <div class="tooltip-background" v-if="tooltipStart !== false"></div>
        <!-- Workspace -->
        <div
          class="common-tooltip tooltip-top primary"
          v-if="tooltipStart == true && pinndeTooltip == true"
        >
          <div
            class="common-class common-tooltip-one"
            :class="{
              'white--text': !$vuetify.theme.dark,
              'black--text': $vuetify.theme.dark,
            }"
          >
            Workspace - Manage all of your workspaces from here <br />
            <v-checkbox
              v-model="checkboxTooltip"
              class="checktooltip mt-0"
              :color="$vuetify.theme.dark ? 'black' : 'white'"
              :class="{
                'white--text': !$vuetify.theme.dark,
                'black--text': $vuetify.theme.dark,
              }"
            >
              <template v-slot:label>
                <span
                  id="checkboxLabel"
                  class="text-caption"
                  :class="{
                    'white--text': !$vuetify.theme.dark,
                    'black--text': $vuetify.theme.dark,
                  }"
                  >Don't show again</span
                >
              </template>
            </v-checkbox>

            <v-btn @click="pinnedTooltip" class="ma-1" small>NEXT</v-btn>
            <v-btn @click="closeTooltip" class="ma-1 primary lighten-2" small
              >SKIP</v-btn
            >
          </div>
        </div>
        <!-- Workspace -->
        <div
          class="common-tooltip tooltip-top primary"
          v-if="tooltipStart == true && tooltipFirst == true"
        >
          <div
            class="common-class common-tooltip-two"
            :class="{
              'white--text': !$vuetify.theme.dark,
              'black--text': $vuetify.theme.dark,
            }"
          >
            Pinned tasks - Act as a shortcut to your single task. You can pin
            any of your task from the single task page. All your pinned tasks
            will be displayed here.<br />
            <v-checkbox
              v-model="checkboxTooltip"
              class="checktooltip mt-0"
              :color="$vuetify.theme.dark ? 'black' : 'white'"
              :class="{
                'white--text': !$vuetify.theme.dark,
                'black--text': $vuetify.theme.dark,
              }"
            >
              <template v-slot:label>
                <span
                  id="checkboxLabel"
                  class="text-caption"
                  :class="{
                    'white--text': !$vuetify.theme.dark,
                    'black--text': $vuetify.theme.dark,
                  }"
                  >Don't show again</span
                >
              </template>
            </v-checkbox>
            <div class="d-flex">
              <div
                v-if="
                  user.isAdmin !== false ||
                  user.isCompanyAdmin !== false ||
                  user.isCompanyManager !== false
                "
              >
                <v-btn @click="workspaceTooltip" class="ma-1" small>NEXT</v-btn>
              </div>
              <div v-else>
                <v-btn @click="memberTooltip" class="ma-1" small>NEXT</v-btn>
              </div>
              <v-btn @click="closeTooltip" class="ma-1 primary lighten-2" small
                >SKIP</v-btn
              >
            </div>
          </div>
        </div>
        <!-- Attandence -->
        <div
          class="common-tooltip tooltip-top primary"
          v-if="tooltipStart == true && tooltipSecond == true"
          v-show="
            user.isAdmin == false &&
            (user.allcompanies.find((data) => data.id == $route.query.companyId)
              .isAdmin == true ||
              user.allcompanies.find(
                (data) => data.id == $route.query.companyId
              ).isManager == true)
          "
        >
          <div
            class="common-class common-tooltip-three-new"
            :class="{
              'white--text': !$vuetify.theme.dark,
              'black--text': $vuetify.theme.dark,
            }"
          >
            Quickly invite users, create workspaces or projects from here <br />
            <v-checkbox
              v-model="checkboxTooltip"
              class="checktooltip mt-0"
              :color="$vuetify.theme.dark ? 'black' : 'white'"
              :class="{
                'white--text': !$vuetify.theme.dark,
                'black--text': $vuetify.theme.dark,
              }"
            >
              <template v-slot:label>
                <span
                  id="checkboxLabel"
                  class="text-caption"
                  :class="{
                    'white--text': !$vuetify.theme.dark,
                    'black--text': $vuetify.theme.dark,
                  }"
                  >Don't show again</span
                >
              </template>
            </v-checkbox>
            <v-btn @click="memberTooltip" class="ma-1" small>NEXT</v-btn>
            <v-btn @click="closeTooltip" class="ma-1 primary lighten-2" small
              >SKIP</v-btn
            >
          </div>
        </div>
        <!-- Your Companies -->
        <div
          class="common-tooltip common-tooltip-sideone primary"
          v-if="tooltipStart == true && toolTipCompany == true"
        >
          <div
            class="common-class common-tooltip-company"
            :class="{
              'white--text': !$vuetify.theme.dark,
              'black--text': $vuetify.theme.dark,
            }"
          >
            Your companies - Select the company for which you want to view the
            project/tasks for. <br />
            <v-checkbox
              v-model="checkboxTooltip"
              class="checktooltip mt-0"
              :color="$vuetify.theme.dark ? 'black' : 'white'"
              :class="{
                'white--text': !$vuetify.theme.dark,
                'black--text': $vuetify.theme.dark,
              }"
            >
              <template v-slot:label>
                <span
                  id="checkboxLabel"
                  class="text-caption"
                  :class="{
                    'white--text': !$vuetify.theme.dark,
                    'black--text': $vuetify.theme.dark,
                  }"
                  >Don't show again</span
                >
              </template>
            </v-checkbox>
            <v-btn @click="companyTooltip" class="ma-1" small>NEXT</v-btn>
            <v-btn @click="closeTooltip" class="ma-1 primary lighten-2" small
              >SKIP</v-btn
            >
          </div>
        </div>
        <div
          class="common-tooltip primary"
          v-if="tooltipStart == true && thirdTooltip == true"
        >
          <div
            class="common-class common-tooltip-three"
            :class="{
              'white--text': !$vuetify.theme.dark,
              'black--text': $vuetify.theme.dark,
            }"
          >
            My Work - View all the tasks assigned to you with the dates
            scheduled
            <br />
            <v-checkbox
              v-model="checkboxTooltip"
              class="checktooltip mt-0"
              :color="$vuetify.theme.dark ? 'black' : 'white'"
              :class="{
                'white--text': !$vuetify.theme.dark,
                'black--text': $vuetify.theme.dark,
              }"
            >
              <template v-slot:label>
                <span
                  id="checkboxLabel"
                  class="text-caption"
                  :class="{
                    'white--text': !$vuetify.theme.dark,
                    'black--text': $vuetify.theme.dark,
                  }"
                  >Don't show again</span
                >
              </template>
            </v-checkbox>
            <v-btn @click="menuTooltip" class="ma-1" small>NEXT</v-btn>
            <v-btn @click="closeTooltip" class="ma-1 primary lighten-2" small
              >SKIP</v-btn
            >
          </div>
        </div>
        <div
          class="common-tooltip primary"
          v-if="tooltipStart == true && menu_1 == true"
          v-show="
            user.isAdmin == false &&
            (user.allcompanies.find((data) => data.id == $route.query.companyId)
              .isAdmin == true ||
              user.allcompanies.find(
                (data) => data.id == $route.query.companyId
              ).isManager == true)
          "
        >
          <div
            class="common-class common-tooltip-four"
            :class="{
              'white--text': !$vuetify.theme.dark,
              'black--text': $vuetify.theme.dark,
            }"
          >
            All users - Manage invitations/users, working hours of an user
            present in your company <br />
            <v-checkbox
              v-model="checkboxTooltip"
              class="checktooltip mt-0"
              :color="$vuetify.theme.dark ? 'black' : 'white'"
              :class="{
                'white--text': !$vuetify.theme.dark,
                'black--text': $vuetify.theme.dark,
              }"
            >
              <template v-slot:label>
                <span
                  id="checkboxLabel"
                  class="text-caption"
                  :class="{
                    'white--text': !$vuetify.theme.dark,
                    'black--text': $vuetify.theme.dark,
                  }"
                  >Don't show again</span
                >
              </template>
            </v-checkbox>
            <v-btn @click="menuTooltip_1" class="ma-1" small>NEXT</v-btn>
            <v-btn @click="closeTooltip" class="ma-1 primary lighten-2" small
              >SKIP</v-btn
            >
          </div>
        </div>
        <div
          class="common-tooltip primary"
          v-if="tooltipStart == true && menu_2 == true"
        >
          <div
            class="common-class common-tooltip-five"
            :class="{
              'white--text': !$vuetify.theme.dark,
              'black--text': $vuetify.theme.dark,
            }"
          >
            Inbox - You can view all the notifications from a task or from a
            team member’s activity <br />
            <v-checkbox
              v-model="checkboxTooltip"
              class="checktooltip mt-0"
              :color="$vuetify.theme.dark ? 'black' : 'white'"
              :class="{
                'white--text': !$vuetify.theme.dark,
                'black--text': $vuetify.theme.dark,
              }"
            >
              <template v-slot:label>
                <span
                  id="checkboxLabel"
                  class="text-caption"
                  :class="{
                    'white--text': !$vuetify.theme.dark,
                    'black--text': $vuetify.theme.dark,
                  }"
                  >Don't show again</span
                >
              </template>
            </v-checkbox>
            <v-btn @click="menuTooltip_2" class="ma-1" small>NEXT</v-btn>
            <v-btn @click="closeTooltip" class="ma-1 primary lighten-2" small
              >SKIP</v-btn
            >
          </div>
        </div>
        <div
          class="common-tooltip primary"
          v-if="tooltipStart == true && menu_3 == true"
        >
          <div
            class="common-class common-tooltip-six"
            :class="{
              'white--text': !$vuetify.theme.dark,
              'black--text': $vuetify.theme.dark,
            }"
          >
            My Time - View the time spent by you for tasks <br />
            <v-checkbox
              v-model="checkboxTooltip"
              class="checktooltip mt-0"
              :color="$vuetify.theme.dark ? 'black' : 'white'"
              :class="{
                'white--text': !$vuetify.theme.dark,
                'black--text': $vuetify.theme.dark,
              }"
            >
              <template v-slot:label>
                <span
                  id="checkboxLabel"
                  class="text-caption"
                  :class="{
                    'white--text': !$vuetify.theme.dark,
                    'black--text': $vuetify.theme.dark,
                  }"
                  >Don't show again</span
                >
              </template>
            </v-checkbox>
            <v-btn @click="menuTooltip_3" class="ma-1" small>NEXT</v-btn>
            <v-btn @click="closeTooltip" class="ma-1 primary lighten-2" small
              >SKIP</v-btn
            >
          </div>
        </div>
        <div
          class="common-tooltip primary"
          v-if="tooltipStart == true && menu_4 == true"
        >
          <div
            class="common-class common-tooltip-seven"
            :class="{
              'white--text': !$vuetify.theme.dark,
              'black--text': $vuetify.theme.dark,
            }"
          >
            Chat - Chat with all of your team members present in your company
            <br />
            <v-checkbox
              v-model="checkboxTooltip"
              class="checktooltip mt-0"
              :color="$vuetify.theme.dark ? 'black' : 'white'"
              :class="{
                'white--text': !$vuetify.theme.dark,
                'black--text': $vuetify.theme.dark,
              }"
            >
              <template v-slot:label>
                <span
                  id="checkboxLabel"
                  class="text-caption"
                  :class="{
                    'white--text': !$vuetify.theme.dark,
                    'black--text': $vuetify.theme.dark,
                  }"
                  >Don't show again</span
                >
              </template>
            </v-checkbox>
            <v-btn @click="menuTooltip_4" class="ma-1" small>NEXT</v-btn>
            <v-btn @click="closeTooltip" class="ma-1 primary lighten-2" small
              >SKIP</v-btn
            >
          </div>
        </div>
        <div
          class="common-tooltip primary"
          v-if="tooltipStart == true && menu_5 == true"
        >
          <div
            class="common-class common-tooltip-eight"
            :class="{
              'white--text': !$vuetify.theme.dark,
              'black--text': $vuetify.theme.dark,
            }"
          >
            Holiday Management - Apply leave requests. Also approve, unapproved
            leave requests if you are a manager/admin<br />
            <v-checkbox
              v-model="checkboxTooltip"
              class="checktooltip mt-0"
              :color="$vuetify.theme.dark ? 'black' : 'white'"
              :class="{
                'white--text': !$vuetify.theme.dark,
                'black--text': $vuetify.theme.dark,
              }"
            >
              <template v-slot:label>
                <span
                  id="checkboxLabel"
                  class="text-caption"
                  :class="{
                    'white--text': !$vuetify.theme.dark,
                    'black--text': $vuetify.theme.dark,
                  }"
                  >Don't show again</span
                >
              </template>
            </v-checkbox>
            <v-btn @click="menuTooltip_5" class="ma-1" small>NEXT</v-btn>
            <v-btn @click="closeTooltip" class="ma-1 primary lighten-2" small
              >SKIP</v-btn
            >
          </div>
        </div>
        <div
          class="common-tooltip primary"
          v-if="tooltipStart == true && menu_6 == true"
        >
          <div
            class="common-class common-tooltip-nine"
            :class="{
              'white--text': !$vuetify.theme.dark,
              'black--text': $vuetify.theme.dark,
            }"
          >
            Featured Modules - Request a feature to the facts team and the most
            requested feature will be approved<br />
            <v-checkbox
              v-model="checkboxTooltip"
              class="checktooltip mt-0"
              :color="$vuetify.theme.dark ? 'black' : 'white'"
              :class="{
                'white--text': !$vuetify.theme.dark,
                'black--text': $vuetify.theme.dark,
              }"
            >
              <template v-slot:label>
                <span
                  id="checkboxLabel"
                  class="text-caption"
                  :class="{
                    'white--text': !$vuetify.theme.dark,
                    'black--text': $vuetify.theme.dark,
                  }"
                  >Don't show again</span
                >
              </template>
            </v-checkbox>
            <v-btn @click="menuTooltip_6" class="ma-1" small>NEXT</v-btn>
            <v-btn @click="closeTooltip" class="ma-1 primary lighten-2" small
              >SKIP</v-btn
            >
          </div>
        </div>
        <div
          class="common-tooltip primary"
          v-if="tooltipStart == true && menu_7 == true"
        >
          <div
            class="common-class common-tooltip-ten"
            :class="{
              'white--text': !$vuetify.theme.dark,
              'black--text': $vuetify.theme.dark,
            }"
          >
            <div
              v-if="
                user.isAdmin == false &&
                user.allcompanies.find(
                  (data) => data.id == $route.query.companyId
                ).isAdmin == false &&
                user.allcompanies.find(
                  (data) => data.id == $route.query.companyId
                ).isManager == false
              "
            >
              Profile - View your profile, edit your name/password <br />
            </div>
            <div v-else>
              Profile - View/edit your profile, shift hours, name and
              password<br />
            </div>
            <v-checkbox
              v-model="checkboxTooltip"
              class="checktooltip mt-0"
              :color="$vuetify.theme.dark ? 'black' : 'white'"
              :class="{
                'white--text': !$vuetify.theme.dark,
                'black--text': $vuetify.theme.dark,
              }"
            >
              <template v-slot:label>
                <span
                  id="checkboxLabel"
                  class="text-caption"
                  :class="{
                    'white--text': !$vuetify.theme.dark,
                    'black--text': $vuetify.theme.dark,
                  }"
                  >Don't show again</span
                >
              </template>
            </v-checkbox>
            <v-btn @click="menuTooltip_7" class="ma-1" small>NEXT</v-btn>
            <v-btn @click="closeTooltip" class="ma-1 primary lighten-2" small
              >SKIP</v-btn
            >
          </div>
        </div>
        <div
          class="common-tooltip primary"
          v-if="tooltipStart == true && menu_8 == true"
        >
          <div
            class="common-class common-tooltip-eleven"
            :class="{
              'white--text': !$vuetify.theme.dark,
              'black--text': $vuetify.theme.dark,
            }"
          >
            Layout Display - Browse through different colors and change the
            color of your layout from here<br />
            <v-checkbox
              v-model="checkboxTooltip"
              class="checktooltip mt-0"
              :color="$vuetify.theme.dark ? 'black' : 'white'"
              :class="{
                'white--text': !$vuetify.theme.dark,
                'black--text': $vuetify.theme.dark,
              }"
            >
              <template v-slot:label>
                <span
                  id="checkboxLabel"
                  class="text-caption"
                  :class="{
                    'white--text': !$vuetify.theme.dark,
                    'black--text': $vuetify.theme.dark,
                  }"
                  >Don't show again</span
                >
              </template>
            </v-checkbox>
            <!-- <v-btn @click="menuTooltip_8" class="ma-1" small>NEXT</v-btn> -->
            <v-btn @click="closeTooltip" class="ma-1 primary lighten-2" small
              >SKIP</v-btn
            >
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="shiftoutPopup"
      transition="dialog-top-transition"
      max-width="600"
    >
      <template>
        <v-card>
          <v-card-title class="pl-3 mb-0">
            <h2>Your shift Time OUT!!!</h2>
          </v-card-title>
          <v-card-text>
            <h3>
              Do you want to continue work?
              <v-btn
                :class="{
                  'gradient-button white--text': !$vuetify.theme.dark,
                  'white black--text': $vuetify.theme.dark,
                }"
                color="green"
                @click="OTpopup = true"
                >YES
              </v-btn>
            </h3>
            <div v-if="OTpopup && remaininghours > 0">
              you could type your expected overtime hours here.. max
              {{ remaininghours + " hrs" }}
              <v-text-field placeholder="Placeholder" dense v-model="noOfHours">
              </v-text-field>
              <div>
                <v-btn @click="OTpopup = false" color="red">cancel</v-btn>
                <v-btn @click="saveOTtime" color="primary">ok</v-btn>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="sticky-bar elevation-4">
            <div class="flex-grow-1"></div>
            <v-btn
              v-if="OTpopup == false"
              :class="{
                'gradient-button white--text': !$vuetify.theme.dark,
                'white black--text': $vuetify.theme.dark,
              }"
              tile
              contained
              @click="
                OTpopup == false;
                shiftoutPopup = false;
              "
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-snackbar v-model="showSnackBar" :bottom="true" :color="messagecolor"
      >{{ snackMessage }}
    </v-snackbar>
    <create-shortcut
      :dialog-prop="createProjectAndWorkspace"
      @close-modal="modelclosing"
    ></create-shortcut>
    <v-snackbar
      v-model="showchangeprojectSnackBar"
      :bottom="true"
      :color="'primary'"
      :timeout="7000"
    >
      <div class="pb-2 text-body-1">
        Task's project has changed successfully
      </div>
      <v-btn
        :class="{
          'white black--text': !$vuetify.theme.dark,
          'black white--text': $vuetify.theme.dark,
        }"
        @click="gototask"
        small
        >View task</v-btn
      >
    </v-snackbar>
  </v-app>
</template>

<script>
import moment from "moment";
import companychange from "./../layouts/companieschange.vue";
import Workspaces from "../pages/dashboard/_workspaces.vue";
import CreateShortcut from "src/components/CreateShortcut";
import $ from "jquery";
import { mapActions } from "vuex";
import { bus } from "../../main";
export default {
  fav: true,
  menu: false,
  data: () => ({
    lastaddedtask: "",
    showchangeprojectSnackBar: false,
    leavenotifycount: 0,
    selectedroute: null,
    remaininghours: 20,
    shiftoutPopup: false,
    OTpopup: false,
    noOfHours: "",
    showSnackBar: false,
    snackMessage: "",
    messagecolor: "red",
    layoutcolor: "",
    birthdaypopup: false,
    birthdayusers: [],
    darkMode: false,
    model: null,
    tab: null,
    moment: moment,
    projects: [],
    allprojects: [],
    searchvalue: "",
    searchMobile: false,
    pinndeTooltip: true,
    search: null,
    workspaceName: "",
    sheet: false,
    elevations: [6, 12, 18],
    fav: true,
    menu: false,
    message: false,
    hints: true,
    switch1: false,
    todayAbsandusers: [],
    showapsantusers: false,
    todayLastAttendance: "",
    chatnotificationcount: "",
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    windowMobileHeight: window.innerHeight,
    notificationcount: "",
    drawer: true,
    miniVariant: localStorage.getItem("miniVariant")
      ? localStorage.getItem("miniVariant") !== "false"
      : false,
    drawerRight: null,
    items: [],
    localworkspacename: "",
    allitems: [
      {
        id: 1,
        icon: "lightbulb_outline",
        text: "My work",
        routeName: "my-work.inbox",
        usercansee: true,
        Issuperadmin: false,
        Iscompanyadminmanager: true,
        query: {},
      },
      {
        id: 2,
        icon: "person_add",
        text: "All users",
        routeName: "invite-user",
        usercansee: false,
        Issuperadmin: false,
        Iscompanyadminmanager: true,
        query: {},
      },
      {
        id: 3,
        icon: "inbox",
        text: "Inbox",
        routeName: "notifications",
        usercansee: true,
        Issuperadmin: false,
        Iscompanyadminmanager: true,
        query: {
          page: 1,
          status: "all",
        },
      },
      {
        id: 4,
        icon: "history",
        text: "My Time",
        routeName: "my-time",
        usercansee: true,
        Issuperadmin: false,
        Iscompanyadminmanager: true,
        query: {},
      },
      {
        id: 6,
        icon: "live_help",
        text: "Help",
        routeName: "faq",
        usercansee: true,
        Issuperadmin: false,
        Iscompanyadminmanager: true,
        query: {},
      },
      {
        id: 7,
        icon: "chat",
        text: "chat",
        routeName: "chat",
        usercansee: true,
        Issuperadmin: false,
        Iscompanyadminmanager: true,
        query: {},
      },
      {
        id: 8,
        icon: "snooze",
        text: "Holiday management",
        routeName: "leave-control",
        usercansee: true,
        Issuperadmin: false,
        Iscompanyadminmanager: true,
        query: {},
      },
      {
        id: 8,
        icon: "trending_up",
        text: "subscription-flows",
        routeName: "subscriptionflows",
        usercansee: false,
        Issuperadmin: true,
        Iscompanyadminmanager: false,
        query: {},
      },
      {
        id: 9,
        icon: "loyalty",
        text: "All subscriptions",
        routeName: "allSubscriptions",
        usercansee: false,
        Issuperadmin: true,
        Iscompanyadminmanager: false,
        query: {},
      },
      {
        id: 10,
        icon: "work",
        text: "Feature Modules",
        routeName: "features",
        usercansee: true,
        Issuperadmin: true,
        Iscompanyadminmanager: true,
        query: {},
      },
      {
        id: 11,
        icon: "account_circle",
        text: "Profile",
        routeName: "profile.user-profile",
        usercansee: true,
        Issuperadmin: true,
        Iscompanyadminmanager: true,
        query: {},
      },
      {
        id: 12,
        icon: "grid_view",
        text: "Layout colour",
        routeName: "LayoutDisplay",
        usercansee: true,
        Issuperadmin: true,
        Iscompanyadminmanager: true,
        query: {},
      },
    ],
    changeroutenames: [
      "singleworkspace",
      "single-workspace",
      "single-workspace.template",
      "single-task",
      "single-project",
      "single-project-tasks",
      "single-project-tasks.board",
      "single-project-tasks.calendar",
      "single-project-tasks.ganttChart",
      "single-project-tasks.table",
      "single-project-files",
      "project-users",
      "project-settings",
      "project-value",
      "timer.project_timer",
      "timer.project_report",
      "trial-expired",
      "singleuser-info",
      "profile.subscriptions",
      "profile.payments",
      "allplans",
      "cardDetail",
      "singleuser-info.useractivity",
    ],
    searchparams: "",
    tooltipStart: JSON.parse(localStorage.getItem("tooltipInitial")),
    dontShowTool: JSON.parse(localStorage.getItem("dontShowAgain")),
    skipStartTooltip: JSON.parse(localStorage.getItem("skipStartTooltip")),
    tooltipFirst: false,
    toolTipCompany: false,
    usersMember: false,
    usersMemberOk: false,
    checkboxTooltip: false,
    drawerPinned: false,
    pinnedTasksList: [],
    thirdTooltip: Boolean,
    tooltipSecond: Boolean,
    menu_1: Boolean,
    menu_2: Boolean,
    menu_3: Boolean,
    menu_4: Boolean,
    menu_5: Boolean,
    menu_6: Boolean,
    menu_7: Boolean,
    menu_8: Boolean,
    prevRoute: null,
    createProjectAndWorkspace: false,
  }),
  created() {
    var self = this;
    self.assignitems();
    self.AssigncreatedData();
    const documentHeight = () => {
      self.tooltipmenuopen();
      const doc = document.documentElement;
      doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", documentHeight);
    documentHeight();
  },
  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  mounted() {
    var self = this;
    bus.$on("changeprojectsuccessmessage", (data) => { 
      this.lastaddedtask = data;
      this.showchangeprojectSnackBar = true; 
    });
    var quickInitialPopup = localStorage.getItem("iniatialQuickStart");
    if (
      quickInitialPopup != "false" &&
      this.user.allcompanies.find(
        (data) => data.id == this.$route.query.companyId
      ) &&
      (this.user.allcompanies.find(
        (data) => data.id == this.$route.query.companyId
      ).isAdmin == true ||
        this.user.allcompanies.find(
          (data) => data.id == this.$route.query.companyId
        ).isManager == true)
    ) {
      this.createProjectAndWorkspace = true;
      localStorage.setItem("iniatialQuickStart", false);
    }
    window.addEventListener("resize", this.getDimensions);
    if (self.$route.meta.section && self.$route.meta.section == "workspace") {
      self.$store.dispatch("workspace/getWorkspaces").then(() => {
        self.assignworkspacemountedactive();
      });
    }
    if (self.$route.meta.section && self.$route.meta.section == "project") {
      self.getSingleproject(self.$route.params.id).then(() => {
        self.assignworkspacemountedactive();
        var user = JSON.parse(localStorage.getItem("user"));
        self.$store.commit("account/commituser", { user });
      });
    }
    const listElm = document.querySelector(
      "#workspace-navbar-sidebar .v-navigation-drawer__content"
    );
    listElm.addEventListener("scroll", () => {
      if (
        Math.ceil(listElm.scrollTop) + listElm.clientHeight >=
        listElm.scrollHeight
      ) {
        var skip = self.$store.state.workspace.limit;
        var limit =
          self.$store.state.workspace.limit +
          self.$store.state.workspace.noofrecord;
        self.$store.dispatch("workspace/loadmoreworkspaces", {
          limit: limit,
          skip: skip,
        });
      }
    });
    if (JSON.parse(localStorage.getItem("user")).isAdmin == false) {
      self.$store.dispatch("workspace/getWorkspaces");
    }
    window.addEventListener("storage", () => {
      self.localworkspacename = window.localStorage.getItem(
        "selectedWorkspaceName"
      );
      if (window.localStorage.getItem("user") == null) {
        if (self.$route.meta.requiresAuth == true) {
          window.location.replace("/login?to=" + window.location.pathname);
        }
      }
    });
    if (this.windowWidth >= 600) {
      this.tooltipmenuopen();
    }
    bus.$on("singlemessageupdatecount", () => {
      self.notificationcount =
        self.notificationcount > 0 ? self.notificationcount - 1 : 0;
    });
    bus.$on("updateuserinfo", () => {
      if (self.isAdmin == false) {
        self.getuserattendancestatus();
      }
    });
    bus.$on("allmessageupdatecount", () => {
      self.notificationcount = 0;
    });
    bus.$on("updateworkspacename", (data) => {
      self.localworkspacename = data;
    });
    bus.$on("createPinnedTasks", (data) => {
      if (data.pinnedUsers.length > 0) {
        self.pinnedTasksList.push(data);
      } else {
        var labelindex = this.pinnedTasksList.findIndex(
          (dataItem) => dataItem.id == data.id
        );
        if (labelindex != -1) {
          self.pinnedTasksList.splice(labelindex, 1);
        }
      }
    });
    bus.$on("deletetask", (data) => {
      var labelindex = this.pinnedTasksList.findIndex(
        (dataItem) => dataItem.id == data.id
      );
      if (labelindex != -1) {
        self.pinnedTasksList.splice(labelindex, 1);
      }
    });
    bus.$on("delete-workspace", (busdata) => {
      busdata;
      self.getpinnedTasks();
      self.$store.dispatch("mywork/getUserTasks");
    });
    bus.$on("changeproject", (busdata) => {
      busdata;
      self.getpinnedTasks();
    });
    bus.$on("updatetitle", (data) => {
      var taskindex = self.pinnedTasksList.findIndex(
        (task) => task.id == data.id
      );
      if (taskindex != -1) {
        self.pinnedTasksList[taskindex].title = data.title;
      }
    });
    bus.$on("updatetasktype", (data) => {
      var taskindex = self.pinnedTasksList.findIndex(
        (task) => task.id == data.id
      );
      if (taskindex != -1) {
        self.pinnedTasksList[taskindex].type = data.type;
      }
    });
    bus.$on("getinboxnotification", () => {
      self.getpinnedTasks();
      self.getnotificationcount();
    });
    bus.$on("timerTooltip", (data) => {
      self.usersMember = data;
      this.usersMemberOk = true;
    });
    bus.$on("startTimeEnable", (data) => {
      self.usersMember = data;
    });
    bus.$on("popupCloseTimer", (data) => {
      if (data == true) {
        this.usersMember = false;
      }
    });
    document.body.classList.remove("has-scroll");
    var layout = JSON.parse(localStorage.getItem("user"));
    if (layout.layout.name == "Dark") {
      $(".brightness_icon").hide();
    } else {
      $(".brightness_icon").show();
    }
    if (
      this.windowWidth > 767 &&
      this.windowWidth < 991 &&
      this.tooltipStart == true
    ) {
      this.drawer = true;
      this.drawer = !this.drawer;
      $(".tooltip-background").css("display", "block");
    }
    bus.$on("change-layout-name", (name) => {
      self.layoutcolor = name;
    });
    var loggedin = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : "";
    if (loggedin.isAdmin == false) {
      this.getpinnedTasks();
      this.getnotificationcount();
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    selectedpage() {
      var index = -1;
      if (
        this.$route.name == "my-work.today" ||
        this.$route.name == "my-work.inbox" ||
        this.$route.name == "my-work.week"
      ) {
        index = this.items.findIndex(
          (elem) => elem.routeName == "my-work.inbox"
        );
      } else if (
        this.$route.name == "my-time-report" ||
        this.$route.name == "my-time"
      ) {
        index = this.items.findIndex((elem) => elem.routeName == "my-time");
      } else if (
        this.$route.name == "chat" ||
        this.$route.name == "chat.chatroom"
      ) {
        index = this.items.findIndex((elem) => elem.routeName == "chat");
      } else if (
        this.$route.name == "profile.user-profile" ||
        this.$route.name == "profile.update-password" ||
        this.$route.name == "profile.subscriptions" ||
        this.$route.name == "profile.payments"
      ) {
        index = this.items.findIndex(
          (elem) => elem.routeName == "profile.user-profile"
        );
      } else {
        index = this.items.findIndex(
          (elem) => elem.routeName == this.$route.name
        );
      }
      return index;
    },
    switchLabel: function () {
      return this.darkMode ? "light" : "dark";
    },
    user() {
      this.$store.getters["account/user"].Profileimage;
      var authuser = JSON.parse(this.$store.state.account.user);

      if (authuser.isAdmin == false) {
        var getcompanyindex = authuser.allcompanies.findIndex(
          (data) => data.id == this.$route.query.companyId
        );
        authuser.shiftHour = authuser.allcompanies[getcompanyindex].shiftHour;
        authuser.shiftMinutes =
          authuser.allcompanies[getcompanyindex].shiftMinutes;
      }
      return authuser;
    },
    workspaceId() {
      return this.$store.getters["workspace/selectedWorkspaceId"];
    },
    apsanticonshowstatus() {
      var checkstatus = [];
      var currentdate = moment().format("YYYY-MM-DD HH:mm");
      if (this.todayAbsandusers.length > 0) {
        this.todayAbsandusers.forEach((data) => {
          if (data.leaveData.halfDaystatus == true) {
            var startTime = moment(
              new Date(data.leaveData.date + " " + data.leaveData.startTime)
            ).format("YYYY-MM-DD HH:mm");
            ("");
            var endTime = moment(
              new Date(data.leaveData.date + " " + data.leaveData.endTime)
            ).format("YYYY-MM-DD HH:mm");
            if (startTime <= currentdate && endTime >= currentdate) {
              checkstatus.push(true);
            }
          } else {
            checkstatus.push(true);
          }
        });
      }
      return checkstatus.length;
    },
  },
  watch: {
    $route(to) {
      var self = this;
      if (to.name == "single-project") {
        self.getSingleproject(self.$route.params.id).then(() => {
          self.assignworkspacemountedactive();
          var user = JSON.parse(localStorage.getItem("user"));
          self.$store.commit("account/commituser", { user });
        });
      } else {
        self.assignworkspacemountedactive();
      }
      if (
        this.$store.state.mywork.filterworkspace != "" ||
        this.$store.state.mywork.filteruser != "" ||
        this.$store.state.mywork.filterproject != ""
      ) {
        var watchfilter = {
          workspace: this.$store.state.mywork.filterworkspace,
          project: this.$store.state.mywork.filterproject,
          user: this.$store.state.mywork.filteruser
            ? this.$store.state.mywork.filteruser
            : "all_users",
        };
        bus.$emit("watchfilter", watchfilter);
      }
      this.getnotificationcount();
    },
    model(val) {
      if (val != null) this.tab = 0;
      else this.tab = null;
    },
    search(val) {
      this.searchparams = val;
      var self = this;
      if (this.searchparams.length < 0) {
        this.allprojects = [];
        this.model = null;
      } else {
        this.$http
          .get(process.env.VUE_APP_ROOT_API + "task/globalsearch", {
            params: {
              searchvalue: val,
            },
          })
          .then((response) => {
            self.allprojects = response.data.projects;
          })
          .catch(() => {
            self.allprojects = [];
            self.model = null;
          });
      }
    },
  },
  methods: {
    ...mapActions("project", ["getSingleproject"]),
    gototask() {
      this.$router.push({
        name: "single-task",
        params: {
          id: this.lastaddedtask.project,
          taskId: this.lastaddedtask.id,
        },
        query: {
          companyId: this.$route.query.companyId,
        },
      });
      this.showchangeprojectSnackBar = false;
    },
    modelclosing(data) {
      this.createProjectAndWorkspace = data;
    },
    openleaveuser(data) {
      this.sheet = data;
    },
    gotomywork() {
      this.$router.push({
        name:
          this.user.isAdmin == false ? "my-work.inbox" : "subscriptionflows",
        query: {
          companyId: this.$route.query.companyId,
        },
      });
    },
    getDimensions() {
      this.windowWidth = document.documentElement.clientWidth;
      this.windowHeight = document.documentElement.clientHeight;
    },
    gettaskicon(pinnedTask) {
      var gettypeindex = pinnedTask.projectinfo.task_types.findIndex(
        (data) => data.id == pinnedTask.type
      );
      return pinnedTask.projectinfo.task_types[gettypeindex].icon;
    },
    saveOTtime() {
      if (this.noOfHours == "" || this.noOfHours == 0) {
        this.snackMessage = "over time hours is required";
        this.showSnackBar = true;
        this.messagecolor = "red";
      } else {
        if (this.noOfHours > this.remaininghours) {
          this.snackMessage =
            "overtime hours should less than the maximum OT hours";
          this.showSnackBar = true;
          this.messagecolor = "red";
        } else {
          this.$http
            .post(
              process.env.VUE_APP_ROOT_API +
                "attendance/add-overtime-attendance",
              {
                otHours: this.noOfHours,
              }
            )
            .then((res) => {
              res.data;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
    resetmyworkFilter(item) {
      if (item.routeName == "my-work.inbox") {
        this.resetfilters();
      }
    },
    resetfilters() {
      bus.$emit("resetfilter");
    },
    getpinnedTasks() {
      this.$http
        .get(process.env.VUE_APP_ROOT_API + "project/get-pinned-tasks", {
          params: {
            timestamp: new Date().getTime(),
          },
        })
        .then((res) => {
          this.pinnedTasksList = res.data;
        });
    },
    dontShowAgain() {
      this.dontShowTool = false;
      localStorage.setItem("dontShowAgain", false);
      this.usersMemberOk = false;
      var userlayout = JSON.parse(localStorage.getItem("user"));
      this.$http
        .post(process.env.VUE_APP_ROOT_API + "layout/update-layoutcolor", {
          darkstatus: userlayout.layoutDarkstatus,
          tooltipTimer: false,
        })
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },
    cancelTooltip() {
      this.usersMemberOk = false;
    },
    gotosingleuser(user) {
      this.$router.push({
        name: "singleuser-info",
        params: {
          userId: user,
        },
        query: {
          companyId: this.$route.query.companyId,
        },
      });
    },
    assignworkspacemountedactive() {
      var self = this;
      if (
        self.$route.meta.section &&
        (self.$route.meta.section == "project" ||
          self.$route.meta.section == "workspace")
      ) {
        if (self.$route.meta.section == "workspace") {
          var workspace = self.$store.state.workspace.workspaces.find(
            (data) => data.id == self.$route.params.id
          );
          if (workspace) {
            self.localworkspacename = workspace.name;
            localStorage.setItem("selectedWorkspaceId", workspace.id);
            localStorage.setItem("selectedWorkspaceName", workspace.name);
            self.$store.commit("workspace/assignselectedindex");
          }
        } else {
          var project = self.$store.state.project.projectData;
          self.localworkspacename = project.workspacename;
          localStorage.setItem("selectedWorkspaceId", project.workspace);
          localStorage.setItem("selectedWorkspaceName", project.workspacename);
          self.$store.commit("workspace/assignselectedindex");
        }
      }
    },
    focusInput() {
      this.search = "";
    },
    tooltipmenuopen() {
      var tooltip_Bar = localStorage.getItem("tooltipInitial");
      if (tooltip_Bar === "true") {
        $(".mobile-menu-leftBar").addClass("mobile-menu-animation");
      } else {
        $(".mobile-menu-leftBar").removeClass("mobile-menu-animation");
      }
    },
    drawerWorkspaceToggle() {
      bus.$emit("workspacesidebarevent");
      if (
        this.drawerRight == false ||
        this.$route.name == "task-views.gantt-chart"
      ) {
        document.body.classList.remove("has-scroll");
      }
    },
    drawerWorkspace() {
      document.body.classList.add("has-scroll");
      this.workspaceDrawer = true;
      this.drawerRight = !this.drawerRight;
    },
    pinnedTooltip() {
      this.pinndeTooltip = false;
      this.tooltipFirst = true;
    },
    companyTooltip() {
      this.toolTipCompany = false;
      this.thirdTooltip = true;
      this.tooltipmenuopen();
    },
    workspaceTooltip() {
      this.tooltipFirst = false;
      this.tooltipSecond = true;
    },
    memberTooltip() {
      this.tooltipFirst = false;
      this.tooltipSecond = false;
      this.toolTipCompany = true;
      this.tabTooltipack();
    },
    menuTooltip() {
      if (
        this.user.allcompanies.find(
          (data) => data.id == this.$route.query.companyId
        ).isAdmin == false &&
        this.user.allcompanies.find(
          (data) => data.id == this.$route.query.companyId
        ).isManager == false &&
        this.user.isAdmin == false
      ) {
        this.thirdTooltip = false;
        this.menuTooltip_1();
        $(".common-tooltip").css("top", "80px");
      } else {
        this.thirdTooltip = false;
        this.menu_1 = true;
        this.tabTooltipack();
        $(".common-tooltip").css("top", "80px");
      }
    },
    menuTooltip_1() {
      this.menu_1 = false;
      this.menu_2 = true;
      this.tabTooltipack();
      $(".common-tooltip").css("top", "130px");
    },
    menuTooltip_2() {
      this.menu_2 = false;
      this.menu_3 = true;
      this.tabTooltipack();
      if (
        this.user.allcompanies.find(
          (data) => data.id == this.$route.query.companyId
        ).isAdmin == false &&
        this.user.allcompanies.find(
          (data) => data.id == this.$route.query.companyId
        ).isManager == false &&
        this.user.isAdmin == false
      ) {
        $(".common-tooltip").css("top", "135px");
      } else {
        $(".common-tooltip").css("top", "180px");
      }
    },
    menuTooltip_3() {
      this.menu_3 = false;
      this.menu_4 = true;
      this.tabTooltipack();
      if (
        this.user.allcompanies.find(
          (data) => data.id == this.$route.query.companyId
        ).isAdmin == false &&
        this.user.allcompanies.find(
          (data) => data.id == this.$route.query.companyId
        ).isManager == false &&
        this.user.isAdmin == false
      ) {
        $(".common-tooltip").css("top", "215px");
      } else {
        $(".common-tooltip").css("top", "265px");
      }
    },
    menuTooltip_4() {
      this.menu_4 = false;
      this.menu_5 = true;
      this.tabTooltipack();

      if (
        this.user.allcompanies.find(
          (data) => data.id == this.$route.query.companyId
        ).isAdmin == false &&
        this.user.allcompanies.find(
          (data) => data.id == this.$route.query.companyId
        ).isManager == false &&
        this.user.isAdmin == false
      ) {
        $(".common-tooltip").css("top", "280px");
      } else {
        $(".common-tooltip").css("top", "325px");
      }
    },
    menuTooltip_5() {
      this.menu_5 = false;
      this.menu_6 = true;
      this.tabTooltipack();
      if (
        this.user.allcompanies.find(
          (data) => data.id == this.$route.query.companyId
        ).isAdmin == false &&
        this.user.allcompanies.find(
          (data) => data.id == this.$route.query.companyId
        ).isManager == false &&
        this.user.isAdmin == false
      ) {
        $(".common-tooltip").css("top", "325px");
      } else {
        $(".common-tooltip").css("top", "375px");
      }
    },
    menuTooltip_6() {
      this.menu_6 = false;
      this.menu_7 = true;
      this.tabTooltipack();
      if (
        this.user.allcompanies.find(
          (data) => data.id == this.$route.query.companyId
        ).isAdmin == false &&
        this.user.allcompanies.find(
          (data) => data.id == this.$route.query.companyId
        ).isManager == false &&
        this.user.isAdmin == false
      ) {
        $(".common-tooltip").css("top", "380px");
      } else {
        $(".common-tooltip").css("top", "425px");
      }
    },
    menuTooltip_7() {
      this.menu_7 = false;
      this.menu_8 = true;
      this.tabTooltipack();
      if (
        this.user.allcompanies.find(
          (data) => data.id == this.$route.query.companyId
        ).isAdmin == false &&
        this.user.allcompanies.find(
          (data) => data.id == this.$route.query.companyId
        ).isManager == false &&
        this.user.isAdmin == false
      ) {
        $(".common-tooltip").css("top", "385px");
      } else {
        $(".common-tooltip").css("top", "435px");
      }
    },
    menuTooltip_8() {
      this.menu_8 = false;
      $("body").removeClass("navigation-drawer-dashboard");
      $("div").removeClass("tooltip-background");
      if (
        this.user.allcompanies.find(
          (data) => data.id == this.$route.query.companyId
        ).isAdmin == false &&
        this.user.allcompanies.find(
          (data) => data.id == this.$route.query.companyId
        ).isManager == false &&
        this.user.isAdmin == false
      ) {
        $(".common-tooltip").css("top", "360px");
      } else {
        $(".common-tooltip").css("top", "385px");
      }
    },
    closeTooltip() {
      this.pinndeTooltip = false;
      this.tooltipFirst = false;
      this.tooltipSecond = false;
      this.thirdTooltip = false;
      this.toolTipCompany = false;
      this.menu_1 = false;
      this.menu_2 = false;
      this.menu_3 = false;
      this.menu_4 = false;
      this.menu_5 = false;
      this.menu_6 = false;
      this.menu_7 = false;
      this.menu_8 = false;
      $("div").removeClass("tooltip-background");
      $("body").removeClass("navigation-drawer-dashboard");
      localStorage.setItem("skipStartTooltip", true);
      if (this.checkboxTooltip == true) {
        var userlayout = JSON.parse(localStorage.getItem("user"));
        this.$http
          .post(process.env.VUE_APP_ROOT_API + "layout/update-layoutcolor", {
            darkstatus: userlayout.layoutDarkstatus,
            tooltipDescription: false,
          })
          .then(() => {})
          .catch((error) => {
            console.log(error);
          });
      } else {
        localStorage.setItem("tooltipInitial", false);
      }
      this.tooltipmenuopen();
    },
    tabTooltipack() {
      if (
        this.windowWidth > 767 &&
        this.windowWidth < 991 &&
        this.tooltipStart == true
      ) {
        this.drawer = true;
        $("body").addClass("navigation-drawer-dashboard");
      }
    },
    gotoredirect(item, name) {
      if (name == "task") {
        this.$router.replace({
          name: "single-task",
          params: {
            id: item.project,
            taskId: item.id,
          },
          query: {
            companyId: this.$route.query.companyId,
          },
        });
        this.allprojects = [];
      } else {
        if (name == "project") {
          this.$router.replace({
            name: "single-project",
            params: {
              id: item.id,
            },
            query: {
              companyId: this.$route.query.companyId,
            },
          });
        }
      }
    },
    checkusercanshow(Absanduser) {
      if (Absanduser.leaveData.halfDaystatus == true) {
        var currentTime = moment().format("YYYY-MM-DD HH:mm");
        var startTime =
          Absanduser.leaveData.date + " " + Absanduser.leaveData.startTime;
        var endTime =
          Absanduser.leaveData.date + " " + Absanduser.leaveData.endTime;
        if (startTime < currentTime && endTime > currentTime) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    AssigncreatedData() {
      var authuser = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : "";
      if (authuser.isAdmin == false) {
        this.getuserattendancestatus();
        this.joinsocket();
        if (this.$vuetify.breakpoint.mdAndDown) {
          this.drawer = false;
        }
      }
    },
    getuserattendancestatus() {
      this.$http
        .get(process.env.VUE_APP_ROOT_API + "leave/today-leaveusers", {
          params: {
            timestamp: new Date().getTime(),
          },
        })
        .then((response) => {
          if (response.data) {
            this.todayAbsandusers = response.data.todayleaveuser;
          }
        });
    },
    CreateChatroomChangeurl(item) {
      var self = this;
      this.$store.dispatch("chat/getCompanyUsers").then(() => {
        var userids = [];
        userids.push(item.id);
        userids.push(this.user.id);
        var formData = {
          groupName: item.firstName + "-" + this.user.firstName,
          selectedUsers: userids,
          directchatroom: true,
        };
        this.$http
          .post(
            process.env.VUE_APP_ROOT_API + "chat/create-direct-chatroom",
            formData
          )
          .then((response) => {
            var data = {
              resChatroom: response.data,
              chatroomusers: userids,
              user: {
                id: item.id,
              },
            };
            self.$store.commit("chat/updateSinglechatroomOnUser", data);
            self.$nextTick(() => {
              if (
                self.$route.params.chatroomid &&
                self.$route.params.chatroomid != ""
              ) {
                if (self.$route.params.chatroomid != response.data.id) {
                  self.$router.push({
                    name: "chat.chatroom",
                    params: {
                      chatroomid: response.data.id,
                    },
                    query: {
                      companyId: this.$route.query.companyId,
                    },
                  });
                }
              } else {
                self.$router.push({
                  name: "chat.chatroom",
                  params: {
                    chatroomid: response.data.id,
                  },
                  query: {
                    companyId: this.$route.query.companyId,
                  },
                });
              }
              self.birthdaypopup = false;
            });
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    assignitems() {
      var self = this;
      var isloggedIn = localStorage.getItem("user");
      if (isloggedIn) {
        var parsedloginuser = JSON.parse(isloggedIn);
        var userstatus =
          parsedloginuser.isAdmin == false &&
          parsedloginuser.isCompanyAdmin == false &&
          parsedloginuser.isCompanyManager == false;
        var superadminstatus = parsedloginuser.isAdmin == true;
        var compadminmanagerstatus =
          parsedloginuser.isAdmin == false &&
          (parsedloginuser.isCompanyAdmin == true ||
            parsedloginuser.isCompanyManager == true);
        if (
          this.$route.query &&
          this.$route.query.companyId &&
          parsedloginuser.allcompanies
        ) {
          let getcompanyindex = parsedloginuser.allcompanies.findIndex(
            (data) => data.id == this.$route.query.companyId
          );
          if (getcompanyindex != -1) {
            var getcompany = parsedloginuser.allcompanies[getcompanyindex];
            userstatus =
              getcompany.isAdmin == false && getcompany.isManager == false;
            compadminmanagerstatus =
              getcompany.isAdmin == true || getcompany.isManager == true;
          }
        }
        if (userstatus == true) {
          self.allitems.forEach((data) => {
            if (data.usercansee == true) {
              self.items.push(data);
            }
          });
        }
        if (superadminstatus == true) {
          self.allitems.forEach((data) => {
            if (data.Issuperadmin == true) {
              self.items.push(data);
            }
          });
        }
        if (compadminmanagerstatus == true) {
          self.allitems.forEach((data) => {
            if (data.Iscompanyadminmanager == true) {
              self.items.push(data);
            }
          });
        }
      }
    },
    toggle_dark_mode() {
      // For Gantt chart purpose popup remove
      const gantt_block = document.querySelector(".bar-wrapper.active");
      if (gantt_block) {
        const add_block = document.querySelectorAll(".popup-wrapper");
        var i;
        for (i = 0; i < add_block.length; i++) {
          add_block[i].classList.add("displayOption");
        }
      }
      // Gantt chart black close code ends
      this.$http
        .post(process.env.VUE_APP_ROOT_API + "layout/update-layoutcolor", {
          name: "",
          darkstatus: !this.$vuetify.theme.dark,
        })
        .then((res) => {
          localStorage.setItem("user", JSON.stringify(res.data.user));
          bus.$emit("change-layout");
          bus.$emit("dark_status", res.data.user);
          // self.$vuetify.theme.dark = !self.$vuetify.theme.dark;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    gotosearchpage(searchitem) {
      this.search = "";
      document.activeElement.blur();
      localStorage.setItem("SearchData", searchitem);
      this.searchparams = "";
      this.allprojects = [];
      this.model = null;
      if (searchitem != null) {
        this.$router.push({
          name: "search-expand",
          params: {
            searchdata: searchitem,
          },
          query: {
            companyId: this.$route.query.companyId,
          },
        });
        setTimeout(() => {
          this.$router.go();
        }, 500);
      }
    },
    joinsocket() {
      var self = this;
      var userid = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : "";
      var roomname = "chatroom";
      this.$io.socket.get(
        "/api/chat/join-room",
        {
          roomname: roomname,
          userid: userid.id,
        },
        function () {}
      );
      self.$io.socket.on("update-shifthours", function (data) {
        if (
          data.broadcastfor == self.user.id &&
          data.companyId == self.$route.query.companyId
        ) {
          var user = JSON.parse(localStorage.getItem("user"));
          user.shiftHour = data.user.shiftHour;
          user.shiftMinutes = data.user.shiftMinutes;
          self.$store.commit("account/commituser", { user });
        }
      });
      self.$io.socket.on("global-chatcount", function (data) {
        if (
          data.broadcastfor == userid.id &&
          data.companyId == self.$route.query.companyId
        ) {
          self.chatnotificationcount = data.unreadcount;
        }
      });
      self.$io.socket.on("birthday-socket-notify", function (data) {
        if (
          data.broadcastfor == userid.id &&
          data.companyId == self.$route.query.companyId
        ) {
          self.birthdaypopup = true;
          self.birthdayusers = data.birthdayusers;
        }
      });
      self.$io.socket.on("global-notification", function (data) {
        if (
          data.broadcastfor == userid.id &&
          data.companyId == self.$route.query.companyId
        ) {
          self.getnotificationcount();
        }
      });
      self.$io.socket.on("add-leave-count", (data) => {
        if (
          self.user.id == data.broadcastfor &&
          data.companyId == self.$route.query.companyId
        ) {
          self.leavenotifycount = self.leavenotifycount + 1;
        }
      });
      self.$io.socket.on("update-leave-count", (data) => {
        if (
          data.companyId == self.$route.query.companyId &&
          self.user.id == data.broadcastfor
        ) {
          self.getnotificationcount();
        }
      });
      self.$io.socket.on("deleteuser-logout", (data) => {
        if (self.user.id == data.broadcastfor) {
          self.logout();
        }
      });
      self.$io.socket.on("delete-activecompanyuser-logout", (data) => {
        var assigncompany = data.user.companies.filter(
          (com) => com.ActiveStatus == true
        );
        if (self.user.id == data.broadcastfor) {
          if (assigncompany.length == 0) {
            self.logout();
          } else {
            var user = JSON.parse(localStorage.getItem("user"));
            var findcompany = data.user.companies.find(
              (data) => data.id == assigncompany[0].id
            );
            user.companyId = assigncompany[0].id;
            user.isCompanyAdmin = findcompany.isAdmin;
            user.isCompanyManager = findcompany.isManager;
            user.allcompanies = assigncompany;
            self.$store.commit("account/commituser", { user });
            if (data.companyId == self.$route.query.companyId) {
              if (this.changeroutenames.includes(this.$route.name)) {
                window.location = "/mywork?companyId=" + assigncompany[0].id;
              } else {
                if (
                  this.$route.name == "invite-user" &&
                  user.isCompanyAdmin == false &&
                  user.isCompanyManager == false
                ) {
                  window.location = "/mywork?companyId=" + assigncompany[0].id;
                } else {
                  var url = document.location.href;
                  var companyidsplitedarray = url.split("companyId");
                  window.location =
                    companyidsplitedarray[0] +
                    "companyId=" +
                    assigncompany[0].id;
                }
              }
            }
          }
        }
      });
      self.$io.socket.on("activatecompanyuser", (data) => {
        var assigncompany = data.user.companies.filter(
          (com) => com.ActiveStatus == true
        );
        if (self.user.id == data.broadcastfor) {
          var user = JSON.parse(localStorage.getItem("user"));
          user.allcompanies = assigncompany;
          self.$store.commit("account/commituser", { user });
        }
      });
    },
    updatenotifiactions(item) {
      if (item.routeName == "notifications") {
        this.$http
          .patch(
            process.env.VUE_APP_ROOT_API +
              "notifications/update-notification-count"
          )
          .then((response) => {
            this.notificationcount = response.data.notificationcount;
          });
      }
    },
    toggleDrawer() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.drawer = !this.drawer;
      } else {
        this.drawer = true;
        this.miniVariant = !this.miniVariant;
        localStorage.setItem("miniVariant", this.miniVariant);
      }
    },
    logout() {
      localStorage.setItem("skipStartTooltip", false);
      var self = this;
      this.$io.socket.get(
        "/api/chat/update-online-inactive-status",
        function () {}
      );
      this.$http
        .post(process.env.VUE_APP_ROOT_API + "entrance/logout", {
          date: new Date().getTime(),
        })
        .then(() => {
          self.$store.commit("account/logout");
          window.location.replace("/login");
        })
        .catch((error) => {
          error;
          self.$store.commit("account/logout");
          window.location.replace("/login");
        });
    },
    getnotificationcount() {
      if (this.user.isAdmin == false) {
        this.$http
          .get(
            process.env.VUE_APP_ROOT_API +
              "notifications/get-read-notification",
            {
              params: {
                timestamp: new Date().getTime(),
              },
            }
          )
          .then((response) => {
            this.notificationcount = response.data.notificationcount;
            this.leavenotifycount = response.data.allleavenotify;
          });
      }
    },
    getShortName(firstName, lastName) {
      return firstName.substr(0, 1) + "" + lastName.substr(0, 1);
    },
  },
  components: {
    Workspaces,
    companychange,
    CreateShortcut,
  },
};
</script>

<style scoped>
.currentcompany {
  background-color: aqua;
}

.v-menu__content {
  z-index: 999 !important;
}
</style>

<style>
.pointeravatar .v-card__title.birthdaylink {
  cursor: pointer;
}

.popup-tooltip {
  position: absolute;
  right: 0;
  top: 4.5em;
}

.popup-tooltip::after {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 8%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

#keep .v-navigation-drawer__border {
  display: none;
}

.smallportiononicon {
  /* height: 10px;
  margin-right: 2px;
  width: 8px;
  border-radius: 3px 4px 4px 61px;
  position: absolute;
  display: none; */
  height: 10px;
  margin-right: 0;
  width: 10px;
  border-radius: 50%;
  position: absolute;
  display: none;
  left: 28px;
  background-color: #000000;
  border: 4px solid #000000;
}

.logo-header-new {
  width: 150px;
}

.v-card__title.birthdaylink:hover {
  color: blue;
}

span.capitalizeuser {
  text-transform: capitalize;
}

.v-navigation-drawer--mini-variant .smallportiononicon {
  display: block;
}

.actionsheetcontent.py-3 {
  margin-left: 22em;
}

.activeMember {
  color: white !important;
}

/* .inactiveMember {
  color: #cccccc !important;
} */

.switch-mode-button .v-messages {
  display: none;
}

.common-tooltip.tooltip-top.primary {
  z-index: 99;
  top: 2.8em;
  right: -3.8em;
}

.common-tooltip {
  position: absolute;
  right: 5px;
  top: 5em;
  padding: 1em;
  border-radius: 10px;
  width: 300px;
  z-index: 10;
}

.common-tooltip-nav {
  position: absolute;
  left: 1px;
  top: -4em;
  border: 1px solid red;
  width: 300px;
  z-index: 9;
}

.sidebar-tooltip-common .common-tooltip {
  left: 260px;
  top: 60px;
  z-index: 99;
}
.sidebar-tooltip-common .common-tooltip.common-tooltip-sideone {
  top: 0px;
}

.sidebar-tooltip-common .common-tooltip.tooltip-top {
  top: 65px;
  z-index: 99;
  right: 8px;
  left: auto;
}

.tooltip-background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: block;
}

.common-tooltip .common-class::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent #d6d4eb transparent transparent;
}

.common-tooltip .common-tooltip-one::after,
.common-tooltip .common-tooltip-two::after,
.common-tooltip .common-tooltip-three-new::after {
  content: "";
  position: absolute;
  bottom: 100%;
  top: -15px;
  right: 10%;
  margin-left: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #d6d4eb transparent;
}

.common-tooltip .common-tooltip-three-new::after {
  right: 43%;
}

.common-tooltip .common-tooltip-company::after {
  top: 30px;
}

.common-tooltip .common-tooltip-two::after {
  right: 26%;
}

.common-tooltip .common-tooltip-three::after {
  top: 30px;
}

.checktooltip .v-messages {
  display: none;
}

.checktooltip .v-icon {
  color: #ffffff;
  font-size: 18px;
}

span#checkboxLabel {
  position: relative;
  left: -6px;
  top: 1px;
}

.common-tooltip .common-tooltip-four::after,
.common-tooltip .common-tooltip-eight::after,
.common-tooltip .common-tooltip-ten::after {
  top: 35%;
}

.common-tooltip .common-tooltip-eleven::after {
  top: 55%;
}

.common-tooltip .common-tooltip-five::after,
.common-tooltip .common-tooltip-nine::after {
  top: 35%;
}

.common-tooltip .common-tooltip-six::after {
  top: 35%;
}

.common-tooltip .common-tooltip-seven::after {
  top: 45%;
}

.navigation-drawer-dashboard .toggle-add-sidebar.v-navigation-drawer--fixed {
  position: fixed;
  transform: translateX(0%) !important;
  height: 100%;
}

.navigation-drawer-dashboard .toggle-add-sidebar.v-navigation-drawer--close {
  visibility: visible;
}

.toggle-add-sidebar .v-navigation-drawer__content::-webkit-scrollbar {
  width: 10px;
  padding: 0px;
  border-radius: 6px;
}

.toggle-add-sidebar .v-navigation-drawer__content::-webkit-scrollbar-thumb {
  min-height: 50px;
  background-color: #888;
  height: 50px;
  border-radius: 6px;
}

.toggle-add-sidebar .v-navigation-drawer__content::-webkit-scrollbar-track {
  background: #e3e3e3;
  border-radius: 6px;
}

.logo-header-dark {
  /* display: none; */
  width: 120px;
}

.userActiveBar {
  z-index: 999 !important;
}

.userActive {
  position: relative;
  z-index: 9;
}

.attendance-off-on {
  background-color: #000;
  width: 100%;
  height: 64px;
  opacity: 0.5;
  position: fixed;
  z-index: 1;
  left: 0;
}

.v-overlay.popup-absolute {
  position: absolute;
  z-index: 9 !important;
}

.popup-absolute-vdialog {
  z-index: 8 !important;
}

header.header-app-bar.userActive:before {
  background-color: #000c;
  content: "";
  display: block;
  width: 100%;
  height: 64px;
  position: absolute;
  opacity: 0.5;
  z-index: 9;
}

.logo-header-light {
  /* display: block; */
  width: 120px;
}

.navigation-drawer-scroll.v-navigation-drawer.v-navigation-drawer--fixed {
  z-index: 9 !important;
}

/* ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
} */

header.header-app-bar {
  z-index: 9 !important;
}

/* .checktooltip .v-input--selection-controls__input .v-icon {
  background-color: #ffffff;
  border-radius: 4px;
} */

.test-link {
  position: fixed;
  right: 64px;
  top: 17px;
  z-index: 203;
}

@media (min-width: 960px) {
  .mobile-menu-animation {
    transform: translateX(0%) !important;
    visibility: visible;
  }
}

@media (min-width: 600px) and (max-width: 760px) {
  .common-tooltip .common-tooltip-one::after {
    right: 3%;
  }
  .common-tooltip .common-tooltip-two::after {
    right: 15%;
  }
  .common-tooltip .common-tooltip-three-new::after {
    right: 30%;
  }
  .common-tooltip .common-tooltip-company::after {
    right: 45%;
  }
}

@media (max-width: 768px) and (max-width: 991px) {
  .has-scroll.navigation-drawer-dashboard .v-overlay.v-overlay--active {
    display: none;
  }
}

@media (max-width: 767px) {
  header.header-app-bar .v-toolbar__content,
  header.header-app-bar .v-toolbar__extension {
    padding: 0;
  }

  .mobile-menu-leftBar {
    max-height: 100%;
  }

  .logo-header-new {
    width: 100px;
  }

  .logo-header-dark {
    width: 110px;
  }

  .logo-header-light {
    width: 100px;
  }

  .navigation-drawer-scroll.toggle-add-sidebar.mobile-menu-leftBar {
    z-index: 10;
  }

  /* .search-icon-button {
    position: relative;
    right: 10px;
  } */

  .mobile-search-background {
    position: fixed;
    width: 100%;
    top: 4em;
    z-index: 5;
  }

  .theme--light .mobile-search-background {
    background-color: #ffffff;
  }

  .theme--dark .mobile-search-background {
    background-color: #333333;
  }
}

:root {
  --doc-height: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100vh;
  /* fallback for Js load */
  height: var(--doc-height);
}
.quick-start-but {
  position: relative;
}
.quick-start-button {
  height: 40px !important;
  width: 40px !important;
  display: inline-block;
  z-index: 999;
}

@keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
  }
}

.quick-waves {
  position: absolute;
  width: 100px;
  height: 100px;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  border-radius: 100%;
  right: -30px;
  bottom: -70px;
  z-index: -1;
  -webkit-animation: waves 3s ease-in-out infinite;
  animation: waves 3s ease-in-out infinite;
}

.quick-wave-1 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.quick-wave-2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.quick-wave-3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
</style>
