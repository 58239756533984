<template>
  <div>
    <v-dialog
      class="singletaskpopup"
      v-model="showTask"
      @click:outside="outsidecloseModal"
      @keydown="(event) => closeModal(event)"
      width="100%"
      max-width="100%"
    >
      <v-card v-if="loading" class="mx-auto">
        <v-card-text>
          Getting task
          <v-progress-linear indeterminate color="primary" class="mb-0">
          </v-progress-linear>
        </v-card-text>
      </v-card>
      <v-card v-else :elevation="0" class="mobile-single-task">
        <v-container fluid>
          <v-row
            align="stretch"
            class="popup-task-section"
            :style="[
              windowWidth > 959 ? { height: windowHeight - 60 + 'px' } : '',
            ]"
          >
            <v-col
              cols="12"
              sm="12"
              md="7"
              :class="windowWidth > 959 ? 'popup-scroll-section' : ''"
              :style="[
                windowWidth > 959 ? { height: windowHeight - 60 + 'px' } : '',
              ]"
            >
              <div v-if="task.IssubtaskChild">
                <h6>
                  <v-row>
                    <v-col class="pt-0">
                      <b>
                        <v-icon small color="grey"> polyline </v-icon>
                        <router-link
                          :to="{
                            name: 'single-task',
                            params: {
                              id: task.project,
                              taskId: task.parenttask.id,
                            },
                            query: {
                              companyId: $route.query.companyId,
                            },
                          }"
                          class="text-decoration-none black--text"
                        >
                          {{ task.parenttask.title }}</router-link
                        >
                      </b>
                    </v-col>
                  </v-row>
                </h6>
              </div>
              <div class="description-wrapper">
                <div
                  class="Task task-header d-flex align-start align-sm-center align-md-center flex-column flex-sm-column flex-md-column justify-space-between"
                >
                  <div
                    class="d-flex align-start flex-column flex-grow-1"
                    style="width: 100%"
                  >
                    <div
                      class="text-caption d-flex align-center font-weight-light date-width py-2"
                    >
                      <div
                        v-if="task.startDate && task.endDate"
                        class="d-flex align-center flex-grow-1 calendar-view-position"
                      >
                        <v-icon small class="pr-1">calendar_today</v-icon>
                        <div class="pt-1">
                          {{
                            task.startDate
                              ? moment(task.startDate, "YYYY-MM-DD").format(
                                  "DD-MM-YYYY"
                                )
                              : ""
                          }}
                          to
                          {{
                            task.endDate
                              ? moment(task.endDate, "YYYY-MM-DD").format(
                                  "DD-MM-YYYY"
                                )
                              : ""
                          }}
                        </div>
                      </div>
                      <div class="d-flex align-center flex-grow-1 justify-end">
                        <div small class="pined-task" @click="pinnedTask(task)">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on" small>
                                <v-icon
                                  medium
                                  :class="[
                                    pinnedTaskbtn == true
                                      ? 'primary--text'
                                      : 'grey--text',
                                  ]"
                                  >push_pin</v-icon
                                >
                              </v-btn>
                            </template>
                            <span v-if="pinnedTaskbtn == true"
                              >Pinned Task</span
                            >
                            <span v-else>Unpinned Task</span>
                          </v-tooltip>
                        </div>
                        <div>
                          <v-row v-if="chackSettingstatus(task) == true">
                            <v-col class="setting-icon-set">
                              <v-menu
                                left
                                :close-on-content-click="false"
                                offset-y
                                class="delete-icon-set"
                                style="width: 200px !important"
                                attach
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    small
                                    icon
                                    color="black"
                                    v-on="on"
                                    class="setting-icon-button"
                                  >
                                    <v-icon small>settings</v-icon>
                                  </v-btn>
                                </template>
                                <v-list>
                                  <div>
                                    <div
                                      v-if="
                                        (task.IssubtaskParent == false &&
                                          task.IssubtaskChild == false) ||
                                        task.IssubtaskParent == true
                                      "
                                      class="pa-2 setting-menu-icon"
                                      :class="{
                                        'background-lite': !$vuetify.theme.dark,
                                        'background-dark': $vuetify.theme.dark,
                                      }"
                                      style="cursor: pointer"
                                    >
                                      <div @click="createDialogOpen()">
                                        Create Subtask
                                      </div>
                                    </div>
                                    <div
                                      v-if="
                                        task.IssubtaskParent == false &&
                                        task.IssubtaskChild == false &&
                                        canEditTask == true
                                      "
                                      class="pa-2 setting-menu-icon"
                                      :class="{
                                        'background-lite': !$vuetify.theme.dark,
                                        'background-dark': $vuetify.theme.dark,
                                      }"
                                      style="cursor: pointer"
                                    >
                                      <v-dialog
                                        v-model="subtaskpopup"
                                        width="500"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <div
                                            @click="openconvertTosubtask()"
                                            v-bind="attrs"
                                            v-on="on"
                                            style="cursor: pointer"
                                          >
                                            <div>Convert to subtask</div>
                                          </div>
                                        </template>
                                        <v-card>
                                          <v-card-title
                                            :class="{
                                              'grey lighten-4':
                                                !$vuetify.theme.dark,
                                              primary: $vuetify.theme.dark,
                                            }"
                                            class="d-flex flex-column align-start"
                                          >
                                            <div
                                              class="horizontal-dividing-header mb-0"
                                            >
                                              <h6
                                                class="header text-uppercase mt-0"
                                              >
                                                convert task to subtask
                                              </h6>
                                            </div>
                                          </v-card-title>
                                          <v-card-text v-if="parentasksinfo">
                                            <v-card-subtitle
                                              v-if="
                                                parentasksinfo.alltask.length >
                                                0
                                              "
                                              class="pa-0 pt-3"
                                            >
                                              Select Parent
                                              Task</v-card-subtitle
                                            >

                                            <v-text-field
                                              solo
                                              dense
                                              v-model="searchParenttask"
                                              label="Search   "
                                              append-icon="search"
                                              class="ma-2"
                                              style="width: 90%"
                                            ></v-text-field>
                                            <div
                                              v-if="
                                                parentasksinfo.alltask.length >
                                                0
                                              "
                                            >
                                              <v-radio-group
                                                v-model="selectedparent"
                                                column
                                                class="mt-0"
                                              >
                                                <v-col
                                                  cols="12"
                                                  v-for="singletask in searchedParentTask"
                                                  :key="singletask.id"
                                                  class="pl-0"
                                                >
                                                  <v-radio
                                                    :label="singletask.name"
                                                    :value="singletask.id"
                                                  ></v-radio>
                                                </v-col>
                                              </v-radio-group>
                                            </div>
                                            <div
                                              v-else
                                              class="pt-5 text-center text-h6 black--text"
                                            >
                                              <p v-if="parentasksinfo.alltask">
                                                Parent task not found
                                              </p>
                                            </div>
                                          </v-card-text>
                                          <v-card-actions>
                                            <div class="flex-grow-1"></div>
                                            <v-btn
                                              color="red"
                                              text
                                              @click="
                                                subtaskpopup = false;
                                                selectedparent = '';
                                                searchParenttask = '';
                                              "
                                              >Close</v-btn
                                            >
                                            <v-btn
                                              v-if="
                                                parentasksinfo.alltask.length >
                                                0
                                              "
                                              :class="{
                                                'gradient-button white--text':
                                                  !$vuetify.theme.dark,
                                                'white black--text':
                                                  $vuetify.theme.dark,
                                              }"
                                              :loading="btnLoading"
                                              :disabled="btnLoading"
                                              tile
                                              contained
                                              text
                                              @click="subminconvertsubtask()"
                                            >
                                              Save
                                            </v-btn>
                                          </v-card-actions>
                                        </v-card>
                                      </v-dialog>
                                    </div>
                                    <div
                                      v-if="
                                        task.IssubtaskChild == true &&
                                        canEditTask
                                      "
                                      @click="convertToTask()"
                                      class="pa-2 setting-menu-icon"
                                      :class="{
                                        'background-lite': !$vuetify.theme.dark,
                                        'background-dark': $vuetify.theme.dark,
                                      }"
                                      style="cursor: pointer"
                                    >
                                      <div>Convert to normal task</div>
                                    </div>
                                    <div
                                      v-if="canDeleteTask"
                                      class="pa-2 setting-menu-icon"
                                      :class="{
                                        'background-lite': !$vuetify.theme.dark,
                                        'background-dark': $vuetify.theme.dark,
                                      }"
                                      :disabled="canDeleteTask == false"
                                      @click="deleteConformDialog = true"
                                      style="cursor: pointer"
                                    >
                                      <div>Delete Task</div>
                                    </div>
                                    <div
                                      v-if="canDeleteTask"
                                      class="pa-2 setting-menu-icon"
                                      :class="{
                                        'background-lite': !$vuetify.theme.dark,
                                        'background-dark': $vuetify.theme.dark,
                                      }"
                                      :disabled="canDeleteTask == false"
                                      @click="getallprojects"
                                      style="cursor: pointer"
                                    >
                                      <div>Change Project</div>
                                    </div>
                                  </div>
                                </v-list>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex align-center" style="width: 100%">
                      <div class="d-flex align-center">
                        <v-btn
                          class="mx-2"
                          fab
                          x-small
                          color="primary"
                          @click="closeModal(event)"
                        >
                          <v-icon small> keyboard_return </v-icon>
                        </v-btn>
                        <userinfo
                          :clickable="true"
                          :user="task.ownername"
                          :userId="task.ownername.id"
                        ></userinfo>
                      </div>
                      <div
                        class="d-flex align-center flex-column"
                        style="width: 100%"
                      >
                        <h6
                          v-if="canEditTask"
                          class="text-body-1 text-capitalize ma-0 word-break font-weight-medium"
                          style="width: 100%"
                          @click="editTitle"
                          v-show="!showTitleInput"
                        >
                          <div class="pointercursor d-flex align-center">
                            {{ task.title }}
                            <v-btn icon>
                              <v-icon small>edit</v-icon>
                            </v-btn>
                          </div>
                        </h6>
                        <h6 v-else class="text-h5 ml-4" style="width: 100%">
                          <div>{{ task.title }}</div>
                        </h6>
                        <div
                          class="d-flex align-center flex-grow-1"
                          style="width: 100%"
                        >
                          <v-text-field
                            append-icon="edit"
                            label="title"
                            v-if="canEditTask"
                            ref="editTitle"
                            class="pl-2"
                            single-line
                            v-model="task.title"
                            @blur="saveTitle"
                            hide-details
                            v-show="showTitleInput"
                            :loading="titleLoading"
                          ></v-text-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-center">
                  <div
                    class="description mt-6 flex-grow-1"
                    v-show="!showDescriptionInput"
                    v-if="canEditTask"
                    @click="editDescription"
                  >
                    <p class="d-flex align-center">
                      <v-btn icon>
                        <v-icon>edit</v-icon>
                      </v-btn>
                      <span
                        v-if="task.description"
                        style="white-space: pre-line"
                      >
                        <div
                          class="pointercursor"
                          v-html="replaceURLs(task.description)"
                        ></div>
                      </span>
                      <span v-else>
                        <small class="pointercursor">
                          <i>NO DESCRIPTION FOR THIS TASK</i>
                        </small>
                      </span>
                    </p>
                  </div>
                  <div v-else>
                    <p>
                      <span v-if="task.description">
                        <div v-html="replaceURLs(task.description)"></div>
                      </span>
                      <span v-else>
                        <small>
                          <i>NO DESCRIPTION FOR THIS TASK</i>
                        </small>
                      </span>
                    </p>
                  </div>
                  <div v-show="showDescriptionInput" class="pb-2 flex-grow-1">
                    <v-textarea
                      prepend-icon="edit"
                      ref="editDescription"
                      v-if="canEditTask"
                      :auto-grow="true"
                      class="pl-2"
                      single-line
                      v-model="task.description"
                      @blur="saveDescription"
                      hide-details
                      :loading="descriptionLoading"
                    ></v-textarea>
                  </div>
                </div>
              </div>
              <v-dialog v-model="changeprojectpopup" width="500">
                <v-card>
                  <v-card-title
                    :class="{
                      'grey lighten-4': !$vuetify.theme.dark,
                      primary: $vuetify.theme.dark,
                    }"
                    class="d-flex flex-column align-start"
                  >
                    <h6 class="header text-uppercase mt-0">
                      Change Task of project
                    </h6>
                  </v-card-title>
                  <v-card-text>
                    <v-card-subtitle
                      v-if="allprojects.length > 0"
                      class="pa-0 pt-3"
                    >
                      Select Changing Project</v-card-subtitle
                    >
                    <v-text-field
                      solo
                      dense
                      v-model="searchproject"
                      label="Search   "
                      append-icon="search"
                      class="ma-2"
                      style="width: 90%"
                    ></v-text-field>
                    <div v-if="searchedAllproject.length > 0">
                      <v-radio-group
                        v-model="selectedproject"
                        column
                        class="mt-0"
                      >
                        <v-col
                          cols="12"
                          v-for="singleproject in searchedAllproject"
                          :key="singleproject.id"
                          class="pl-0"
                        >
                          <v-radio
                            :label="singleproject.name"
                            :value="singleproject.id"
                          ></v-radio>
                        </v-col>
                      </v-radio-group>
                    </div>
                    <div v-else class="pt-5 text-center text-h6 black--text">
                      <p v-if="allprojects">Projects not found</p>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn
                      color="red"
                      text
                      @click="
                        changeprojectpopup = false;
                        selectedproject = '';
                      "
                      >Close</v-btn
                    >
                    <v-btn
                      v-if="allprojects.length > 0"
                      :class="{
                        'gradient-button white--text': !$vuetify.theme.dark,
                        'white black--text': $vuetify.theme.dark,
                      }"
                      tile
                      contained
                      text
                      @click="switchprojectconfirm = true"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="switchprojectconfirm" max-width="500">
                <v-card>
                  <v-card-title>Are you sure?</v-card-title>
                  <v-card-text class="first-character">
                    Are you sure you want to change this task to a different
                    project?</v-card-text
                  >
                  <v-card-text class="first-character">
                    <small>
                      <h6>Please note that</h6>
                      <p>
                        *All custom fields and labels of this task would be
                        removed while moving this task to a different project
                      </p>
                      <p>
                        *All task activity history except user comments and file
                        attachments would be removed
                      </p>
                      <p v-if="task.IssubtaskParent == true">
                        *Sub tasks of this task will also be retained.
                      </p>
                    </small>
                  </v-card-text>
                  <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn
                      text
                      :loading="disablechangeproject"
                      :disabled="disablechangeproject"
                      @click="switchprojectconfirm = false"
                      class="red--text"
                      >Cancel</v-btn
                    >
                    <v-btn
                      class="primary"
                      :class="{
                        'white--text': !$vuetify.theme.dark,
                        'black--text': $vuetify.theme.dark,
                      }"
                      outlined
                      tile
                      :loading="disablechangeproject"
                      :disabled="disablechangeproject"
                      @click="submitchangetask()"
                      >Yes</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-row class="mt-0">
                <div
                  class="font-weight-bold pl-2 pt-6 pb-2 d-flex align-center justify-space-between"
                  v-if="canEditTask || sortedArray.length > 0"
                  style="width: 100%"
                >
                  Todo Lists
                  <div
                    class="d-flex align-center"
                    v-if="sortedArray.length > 0"
                  >
                    <span class="text-body-2 pt-1 font-weight-bold"
                      >Hide completed</span
                    >
                    <v-switch
                      @change="updatehidecompletedtodo()"
                      color="primary"
                      v-model="showcompletedtodoUsers"
                      hide-details
                      class="mt-0 pl-3"
                    ></v-switch>
                  </div>
                </div>
                <v-col cols="12" class="pa-0 text-body-1">
                  <template>
                    <draggable
                      @end="dragablefunction"
                      :options="{ filter: '.drag-disable' }"
                    >
                      <div
                        v-for="todolist in sortedArray"
                        :key="todolist.id"
                        :id="todolist.id"
                        :class="[
                          checkshowtodostatus(todolist)
                            ? todoAuthstatus(todolist, true) == false
                              ? 'd-flex align-center pb-3 drag-disable'
                              : 'd-flex align-center pb-3'
                            : '',
                        ]"
                      >
                        <template v-if="checkshowtodostatus(todolist)">
                          <v-btn
                            v-if="todoAuthstatus(todolist, true)"
                            depressed
                            icon
                            x-small
                            title="reorder task"
                            class="reorder-drag"
                          >
                            <v-icon>drag_indicator</v-icon>
                          </v-btn>
                          <v-checkbox
                            :class="windowWidth < 959 ? 'drag-disable' : ''"
                            :disabled="todoAuthstatus(todolist, true) == false"
                            @change="checktodo(todolist)"
                            v-model="todolist.completed_status"
                            hide-details
                            class="shrink mr-0 mt-0"
                          ></v-checkbox>
                          <v-row class="align-center">
                            <v-col
                              cols="11"
                              md="11"
                              class="d-flex align-center pa-0 mb-0"
                            >
                              <div
                                class="flex-grow-1"
                                :class="windowWidth < 959 ? 'drag-disable' : ''"
                              >
                                <div
                                  class="d-flex flex-column"
                                  v-if="
                                    current_list_todo == true &&
                                    todolist.id == current_list_todo_id
                                  "
                                >
                                  <v-textarea
                                    :disabled="
                                      todoAuthstatus(todolist, false) == false
                                    "
                                    placeholder="Placeholder"
                                    dense
                                    auto-grow
                                    rows="1"
                                    row-height="15"
                                    v-model="todolist.name"
                                    v-validate="'required'"
                                    data-vv-name="todo name"
                                    data-vv-as="todo name"
                                    data-vv-scope="edittodo"
                                    :error-messages="
                                      errors.collect('edittodo.todo name')
                                    "
                                    @keydown.enter="onEdit($event, todolist)"
                                    v-click-outside="onClickOutside"
                                  >
                                  </v-textarea>
                                </div>
                                <div
                                  @click="single_todo_edit(todolist)"
                                  :id="todolist.id"
                                  v-else
                                >
                                  <div class="d-flex align-center">
                                    <h6
                                      :class="[
                                        todolist.completed_status == true
                                          ? 'completedtodos'
                                          : '',
                                        (todolist.authstatus = false
                                          ? 'pointercursor'
                                          : ''),
                                      ]"
                                      class="align-start justify-start text-wrap text-body-2 font-weight-light flex-grow-1 todo-wrap"
                                    >
                                      <div style="white-space: pre-line">
                                        {{ todolist.name }}
                                      </div>
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </v-col>
                            <v-col
                              cols="1"
                              md="1"
                              :class="windowWidth < 959 ? 'drag-disable' : ''"
                              class="pa-0 d-flex justify-end"
                            >
                              <v-icon
                                class="red--text todo-delete-button"
                                small
                                v-if="todoAuthstatus(todolist, false)"
                                :disabled="
                                  todoAuthstatus(todolist, false) == false
                                "
                                @click="deleteTodo(todolist)"
                                >delete</v-icon
                              >
                            </v-col>
                            <v-col cols="12" class="pa-0">
                              <div
                                class="d-flex align-center todo-username pt-1"
                              >
                                <div
                                  class="text-caption flex-grow-1 grey--text"
                                >
                                  {{ gettoDOowner(todolist) }}
                                </div>
                                <div
                                  class="text-caption flex-grow-1 justify-end d-flex grey--text"
                                >
                                  {{ moment(todolist.createdAt).fromNow() }}
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                        </template>
                      </div>
                    </draggable>
                  </template>
                </v-col>
              </v-row>

              <v-row v-if="canEditTask">
                <v-col cols="12">
                  <v-row class="align-center">
                    <v-col cols="10" sm="10" md="10">
                      <v-textarea
                        placeholder="New Todo"
                        outlined
                        dense
                        @keydown.enter="onSave"
                        slot
                        auto-grow
                        rows="1"
                        row-height="15"
                        v-model="todo_name"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="2" sm="2" md="2">
                      <v-icon
                        small
                        @click="addtodotitle()"
                        :class="{
                          secondary: !$vuetify.theme.dark,
                          black: $vuetify.theme.dark,
                        }"
                        class="rounded-pill white--text mt-1 pa-2"
                        >add</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <div v-if="subtasks.length > 0" class="pb-3">
                <h6 class="font-weight-bold text-body-2 pb-2">
                  Subtasks Lists
                </h6>
                <template>
                  <div>
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header
                          >Subtasks
                          <span>
                            &nbsp;&nbsp;&nbsp;
                            <v-icon small color="grey"> polyline </v-icon
                            >{{ subtasks.length }}</span
                          >
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="subTasks-panel">
                          <draggable
                            ghost-class="ghost-card"
                            class="list-group"
                            :list="sortedsubtask"
                            :options="{ filter: '.drag-disable' }"
                            group="tasks"
                            :animation="200"
                            @end="changeOrderAndStatus($event)"
                          >
                            <div
                              class="mt-2"
                              v-for="singletask in sortedsubtask"
                              :key="singletask.id"
                              :id="singletask.id"
                            >
                              <subtasks
                                @updateattribute="updateattribute"
                                @opensubtask="opensubtask"
                                :canEditTask="editstatus(singletask)"
                                :subtask="true"
                                :task="singletask"
                              ></subtasks>
                            </div>
                          </draggable>
                          <div class="d-flex align-center justify-center">
                            <v-btn
                              outlined
                              @click="createDialogOpen()"
                              class="pa-2 d-flex justify-center"
                              :class="{
                                'primary--text': !$vuetify.theme.dark,
                                white: $vuetify.theme.dark,
                              }"
                            >
                              <v-icon>add</v-icon>
                            </v-btn>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                </template>
              </div>
              <TaskAttachements
                @commitattachements="commitattachements"
                :proptask="task"
                :canEditTask="canEditTask"
                :canDeleteTask="canDeleteTask"
              />
              <v-divider class="my-5"></v-divider>
              <div id="comments" class="d-flex flex-column">
                <div class="post-comment">
                  <div class="d-inline-flex">
                    <v-icon small>edit</v-icon>
                    <div class="font-weight-medium text-subtitle-1 pl-2">
                      Post comment
                    </div>
                  </div>
                  <tiptap-vuetify
                    @keydown="typingOnCommentField"
                    ref="tiptapPostComment"
                    v-model="tiptapPostCommentcontent"
                    :extensions="extensions"
                    name="comment"
                    :disabled="commentLoader"
                    :card-props="{
                      loading: commentLoader,
                    }"
                  />
                  <div class="d-flex justify-space-between align-center my-5">
                    <div class="d-inline-flex align-center">
                      <v-file-input
                        v-model="taskfiles"
                        v-if="canEditTask"
                        data-vv-scope="commentscope"
                        v-validate="
                          'ext:jpeg,jpg,png,svg,xls,xlsx,pdf,doc,docx,.ods,.ppt.txt,.3g2,.webm,.ogg,.mkv,.mov,.avi,.wmv,.flv,.mp4,.ogv,video/3gpp2,.ppt,audio/ogg,.asf,application/vnd.ms-asf,  application/vnd.ms-powerpoint'
                        "
                        accept="application/msword,.asf,,.ppt, application/vnd.ms-asf,application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*,.xlsx,.doc,.docx,.ods,text/plain, application/vnd.ozpenxmlformats-officedocument.wordprocessingml.document, application/msword,.3g2,.webm,.ogg,.mkv,.mov,.avi,.wmv,.flv,.mp4,.ogv"
                        @change="attachFile"
                        name="commentfile"
                        data-vv-as="file"
                        :error-messages="
                          errors.collect('commentscope.commentfile')
                        "
                        multiple
                        title="Add file"
                        :hide-input="true"
                        class="pt-0"
                      ></v-file-input>
                    </div>
                    <v-btn
                      :disabled="commentsubmitstatus || commentLoader"
                      color="primary"
                      v-if="canEditTask"
                      @click="postComment"
                    >
                      Submit
                      <v-icon small class="pl-2">send</v-icon>
                    </v-btn>
                  </div>
                  <template v-if="previewFiles.length > 0">
                    <h6>
                      {{ commentFiles.length }}
                      {{ previewFiles.length > 1 ? "files" : "file" }}
                      added
                    </h6>
                  </template>
                  <v-row>
                    <v-col
                      v-for="(file, index) in previewFiles"
                      :key="index"
                      cols="3"
                    >
                      <v-card class="d-flex justify-center align-center">
                        <template v-if="file.type == 'image'">
                          <v-img
                            :src="file.url"
                            aspect-ratio="1"
                            class="comment-file-icon"
                          ></v-img>
                        </template>
                        <template v-if="file.type == 'file'">
                          <v-icon class="comment-file-icon"
                            >file_present</v-icon
                          >
                        </template>
                        <v-btn
                          absolute
                          depressed
                          x-small
                          fab
                          class="delete-preview-button right top error"
                          :class="{
                            'white--text': !$vuetify.theme.dark,
                            'black--text': $vuetify.theme.dark,
                          }"
                          @click="removePreview(index)"
                        >
                          <v-icon>delete_outline</v-icon>
                        </v-btn>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
                <v-divider class="mb-5 mt-8"></v-divider>
                <div v-if="comments.length > 0">
                  <div class="d-inline-flex">
                    <v-icon>notes</v-icon>
                    <div class="font-weight-medium text-subtitle-1 pl-2">
                      Comments
                    </div>
                    <v-btn fab depressed color="primary" x-small class="ml-2">{{
                      comments.length
                    }}</v-btn>
                  </div>
                  <template v-for="comment in comments">
                    <comment
                      :canEditTask="canEditTask"
                      :canDeleteTask="canDeleteTask"
                      @deleteComment="deleteComment"
                      @editvalidation="editvalidation"
                      @updatetimer="updatetimer"
                      @openRemoveFileDialog="openRemoveFileDialog"
                      @openRemoveCommentDialog="openRemoveCommentDialog"
                      :propcomment="comment"
                      :project="project"
                      :task="task"
                      :priorityItems="project.priorities"
                      :statuses="project.statuses"
                      :taskTypes="project.task_types"
                      :key="comment.id"
                    ></comment>
                    <v-divider
                      class="my-2"
                      :key="'divider' + comment.id"
                    ></v-divider>
                  </template>
                </div>
              </div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col
              cols="12"
              sm="12"
              md="5"
              :class="windowWidth > 959 ? 'popup-scroll-section' : ''"
              :style="[
                windowWidth > 959 ? { height: windowHeight - 60 + 'px' } : '',
              ]"
            >
              <div
                class="d-flex flex-column flex-md-row flex-sm-row align-start justify-space-between align-sm-center align-md-center mb-5"
              >
                <p class="subtitle-2 mb-2 mb-sm-0 mb-md-0">Type</p>
                <v-select
                  :disabled="canEditTask == false"
                  v-model="task.type"
                  :items="
                    project.task_types.filter(
                      (data) => data.activeStatus == true
                    )
                  "
                  item-text="name"
                  item-value="id"
                  dense
                  outlined
                  label="Type"
                  style="max-width: 200px"
                  hide-details
                  attach
                  :menu-props="{ closeOnContentClick: true }"
                  class="hide-drop-style"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item
                      :input-value="item.id == task.type"
                      @click="changeTasktype(item)"
                    >
                      <span class="text-body-2">
                        <v-icon>{{ item.icon }}</v-icon> {{ item.name }}
                      </span>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span class="text-truncate">
                      <v-icon>{{ item.icon }}</v-icon> {{ item.name }}
                    </span>
                  </template>
                </v-select>
              </div>
              <div
                class="d-flex flex-column flex-md-row flex-sm-row align-start align-md-center align-sm-center justify-space-between mb-5"
              >
                <p class="subtitle-2 mb-2 mb-sm-0 mb-md-0">Status</p>
                <v-select
                  v-model="task.status"
                  :disabled="canEditTask == false"
                  :items="
                    project.statuses.filter((data) => data.activeStatus == true)
                  "
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  label="Status"
                  attach
                  style="max-width: 200px"
                  hide-details
                  :menu-props="{ closeOnContentClick: true }"
                  class="hide-drop-style"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item
                      :input-value="item.id == task.status"
                      @click="changeTaskStatus(item)"
                    >
                      <span class="text-body-2">{{ item.name }}</span>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span class="text-truncate">{{ item.name }}</span>
                  </template>
                </v-select>
              </div>
              <div
                class="d-flex flex-column flex-md-row flex-sm-row align-start align-md-center align-sm-center justify-space-between priority mb-5"
              >
                <p class="subtitle-2 mb-2 mb-sm-0 mb-md-0">Priority</p>
                <v-select
                  :disabled="canEditTask == false"
                  v-model="task.priority"
                  :items="
                    project.priorities.filter(
                      (data) => data.activeStatus == true
                    )
                  "
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  attach
                  label="Priority"
                  style="max-width: 200px"
                  hide-details
                  :menu-props="{ closeOnContentClick: true }"
                  class="hide-drop-style"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item
                      :input-value="item.id == task.priority"
                      @click="changePriority(item)"
                    >
                      <span class="text-body-2">{{ item.name }}</span>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span class="text-truncate">{{ item.name }}</span>
                  </template>
                </v-select>
              </div>
              <div
                class="d-flex flex-column flex-md-row flex-sm-row align-start align-md-center align-sm-center justify-space-between mb-5"
              >
                <p class="subtitle-2 mb-2 mb-md-0 mb-sm-0">Start end date</p>
                <div>
                  <v-menu
                    :disabled="canEditTask == false"
                    ref="startandEndpickermenu"
                    v-model="startandEndpickermenu"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="StartEnddates"
                    transition="scale-transition"
                    persistent
                    class="mr-2 mb-2"
                    width="290px"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :disabled="canEditTask == false"
                        @click:clear="
                          date = null;
                          startandEndpickermenu = false;
                          emptydate();
                          minEndDate = '';
                        "
                        :clearable="
                          canEditTask == true && StartEnddates.length == 2
                        "
                        label="Start and end date"
                        readonly
                        v-on="on"
                        :value="TaskStartEndRange"
                        outlined
                        dense
                        class="dateinput"
                        style="width: 200px !important"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @input="assignmindate"
                      v-model="StartEnddates"
                      range
                      no-title
                      :min="minEndDate"
                    >
                      <div class="flex-grow-1"></div>
                      <v-btn text color="primary" @click="cancelDate()"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="assignRangeofDates(StartEnddates)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </div>
              </div>
              <div
                class="d-flex flex-column flex-md-row flex-sm-row align-start align-md-center align-sm-center justify-space-between mb-5"
              >
                <p class="subtitle-2 mb-2 mb-sm-0 mb-md-0">
                  Action required by
                </p>
                <v-select
                  :disabled="canEditTask == false"
                  v-model="task.actionRequiredBy"
                  label="Action required user"
                  :items="actionrequiredbyoptions"
                  outlined
                  dense
                  attach
                  item-text="firstName"
                  item-value="_id"
                  style="max-width: 200px"
                  hide-details
                  :menu-props="{ closeOnContentClick: true }"
                  class="hide-drop-style"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item
                      :input-value="item._id == task.actionRequiredBy"
                      @click="assignactionrequiredby(item)"
                    >
                      <span class="text-capitalize word-break">{{
                        item.firstName
                      }}</span>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span class="text-capitalize word-break text-truncate">{{
                      item.firstName
                    }}</span>
                  </template>
                </v-select>
              </div>
              <div
                class="estimate-priority d-flex flex-column flex-md-row flex-sm-row align-start align-md-center align-sm-center justify-space-between mb-5"
              >
                <p class="subtitle-2 pr-2">Estimate</p>
                <div>
                  <v-menu
                    v-model="editEstimateMenu"
                    :close-on-click="false"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <v-row>
                        <v-col>
                          <div class="d-flex">
                            <v-btn
                              :disabled="canEditTask == false"
                              depressed
                              fab
                              outlined
                              x-small
                              v-on="on"
                              color="primary"
                            >
                              {{ task.estimate.hours }}H</v-btn
                            >
                            <v-btn
                              :disabled="canEditTask == false"
                              class="ml-1"
                              depressed
                              fab
                              outlined
                              x-small
                              v-on="on"
                              color="primary"
                            >
                              {{ task.estimate.minutes }}M</v-btn
                            >
                          </div>
                        </v-col>
                        <v-col
                          v-if="
                            canEditTask &&
                            (task.estimate.hours != '' ||
                              task.estimate.minutes != '')
                          "
                        >
                          <v-btn icon @click="removeEstimate">
                            <v-icon>cancel</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </template>
                    <v-card>
                      <v-subheader
                        class="text-uppercase font-weight-medium black--text"
                        >Estimate</v-subheader
                      >
                      <v-container fluid class="pt-0 pb-0">
                        <v-row>
                          <v-col>
                            <v-text-field
                              :disabled="canEditTask == false"
                              label="Hr"
                              outlined
                              v-validate="'hrzeorvalidation'"
                              data-vv-name="Hr"
                              :error-messages="
                                errors.collect('estimatetime.Hr')
                              "
                              data-vv-as="Hr"
                              data-vv-scope="estimatetime"
                              style="max-width: 200px"
                              type="number"
                              min="0"
                              dense
                              @change="(val) => (estimate.hours = val)"
                              v-model="taskEstimate.hours"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              label="Min"
                              outlined
                              min="0"
                              style="width: 100px"
                              type="number"
                              dense
                              v-model="taskEstimate.minutes"
                              v-validate="'minutevalidation'"
                              data-vv-name="Min"
                              :error-messages="
                                errors.collect('estimatetime.Min')
                              "
                              data-vv-as="Min"
                              data-vv-scope="estimatetime"
                              @change="(val) => (estimate.minutes = val)"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-card-actions v-if="canEditTask">
                        <v-spacer></v-spacer>
                        <v-btn text @click="cancelEstimate()">Cancel</v-btn>
                        <v-btn color="primary" text @click="saveEstimate"
                          >Save</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </div>
              </div>
              <div
                v-if="canEditTask"
                class="estimate-priority d-flex flex-row align-center mb-5"
              >
                <p class="subtitle-2 pr-2 mb-0">Completed status</p>
                <div>
                  <div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :disabled="canEditTask == false"
                          v-bind="attrs"
                          v-on="on"
                          depressed
                          dark
                          fab
                          small
                          :color="task.Iscompleted == true ? 'green' : 'grey'"
                          solo
                          icon
                        >
                          <v-icon @click="confirmtaskcomplete()"
                            >check_circle</v-icon
                          >
                        </v-btn>
                      </template>
                      <small class="successmessage">{{
                        task.Iscompleted == true
                          ? "Mark as Incomplete"
                          : "Mark as Complete"
                      }}</small>
                    </v-tooltip>
                  </div>
                </div>
              </div>
              <div
                class="users d-inline-flex flex-column flex-md-row flex-sm-row align-start align-md-center align-sm-center mb-5"
              >
                <p class="subtitle-2 pr-2 mb-0">Users</p>
                <div class="d-inline-flex align-center flex-wrap">
                  <template v-if="task.assignedToUsers.length > 0">
                    <div
                      class="assigned-users"
                      :class="[index == 0 ? 'mx-1' : 'ma-1']"
                      v-for="(assignedUser, index) in task.assignedToUsers"
                      :key="assignedUser._id"
                    >
                      <v-tooltip
                        bottom
                        :class="{
                          'secondary white--text': !$vuetify.theme.dark,
                          'white black--text': $vuetify.theme.dark,
                        }"
                      >
                        <template v-slot:activator="{ on }">
                          <v-avatar
                            v-on="on"
                            size="36"
                            color="secondary"
                            :class="{
                              'white--text': !$vuetify.theme.dark,
                              'black--text': $vuetify.theme.dark,
                            }"
                            class="assigned-user text-uppercase"
                          >
                            <v-img
                              @click="gotosingleuser(assignedUser._id)"
                              v-if="assignedUser.Profileimage != ''"
                              :src="assignedUser.Profileimage"
                              aspect-ratio="1"
                            >
                            </v-img>
                            <span
                              @click="gotosingleuser(assignedUser._id)"
                              v-else
                            >
                              {{
                                getShortName(
                                  assignedUser.firstName,
                                  assignedUser.lastName
                                )
                              }}
                            </span>
                            <span
                              v-if="canEditTask"
                              class="d-flex remove-user"
                              @click="
                                removeAssignedUserPopup(index, assignedUser._id)
                              "
                            >
                              <v-icon
                                :class="{
                                  'white--text': !$vuetify.theme.dark,
                                  'black--text': $vuetify.theme.dark,
                                }"
                                x-small
                                >close</v-icon
                              >
                            </span>
                          </v-avatar>
                        </template>
                        <p class="first-character mb-0">
                          {{ firstletterupercase(assignedUser.firstName) }}
                          {{ firstletterupercase(assignedUser.lastName) }}
                        </p>
                      </v-tooltip>
                    </div>
                  </template>
                  <template v-else>
                    <h6 class="text-body-2 grey--text text--darken-1">
                      No users assigned
                    </h6>
                  </template>
                  <v-btn
                    v-if="canEditTask"
                    :class="task.assignedToUsers.length == 0 ? 'ml-2' : ''"
                    @click="openassignuserpopup"
                    x-small
                    color="primary"
                    outlined
                    fab
                  >
                    <v-icon>add</v-icon>
                  </v-btn>
                </div>
              </div>
              <br />
              <br />

              <div class="icon-section d-flex flex-column align-start">
                <v-row>
                  <v-col cols="12" class="d-flex pa-0">
                    <div
                      v-if="timerupdatestatus"
                      class="users align-center pb-2"
                    >
                      <h6 class="text-subtitle-2 first-character">
                        Update your spent time for this task:
                      </h6>
                      <v-btn color="primary" @click="openaddReportTimepopup()"
                        >add</v-btn
                      >
                    </div>
                    <div v-else>
                      <div
                        v-if="
                          !task.Iscompleted ||
                          (recordwithemptyendtime && task.Iscompleted)
                        "
                        class="d-flex"
                      >
                        <div
                          v-if="recordwithemptyendtime"
                          class="d-flex align-center"
                        >
                          End Time :

                          <div class="pl-2" v-if="startedtimerloaderstatus">
                            <v-progress-circular
                              color="primary"
                              indeterminate
                              size="25"
                            ></v-progress-circular>
                          </div>

                          <v-tooltip bottom v-else>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                :disabled="stopstatus"
                                v-bind="attrs"
                                v-on="on"
                                @click="StopTimeCounter(recordwithemptyendtime)"
                                icon
                              >
                                <v-icon>timer_off</v-icon>
                              </v-btn>
                            </template>
                            <span>End Time</span>
                          </v-tooltip>
                        </div>
                        <div v-else>
                          Start Time
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                :disabled="startstatus"
                                v-bind="attrs"
                                v-on="on"
                                @click="startTimerCounter()"
                                icon
                              >
                                <v-icon>access_time</v-icon>
                              </v-btn>
                            </template>
                            <span>Start Time</span>
                          </v-tooltip>
                        </div>
                        <h5
                          class="timer-section text-h6 text-capitalize ma-0 pl-5"
                          v-if="timershowstatus"
                        >
                          <span v-if="years != 0" class="pr-2">
                            {{ years }}
                            <small>(years)</small>
                          </span>
                          <span v-if="days != 0" class="pr-2">
                            {{ days }}
                            <small>(days)</small>
                          </span>
                          <span v-if="hours != 0" class="pr-2">
                            {{ hours }}
                            <small>hrs</small>
                          </span>
                          <span v-if="minutes != 0" class="pr-2">
                            {{ minutes }}
                            <small>mins</small>
                          </span>
                          <span v-if="seconds != 0" class="pr-2">
                            {{ seconds }}
                            <small>sec</small>
                          </span>
                        </h5>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <div
                  style="width: 100%"
                  v-if="canDeleteTask && userRunningtimer.length > 0"
                >
                  <div class="mr-0 mr-md-3 mr-sm-3">
                    <v-expansion-panels class="running-user">
                      <v-expansion-panel>
                        <v-expansion-panel-header class="px-4">
                          Running time for users
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-2">
                          <v-row
                            v-for="(usertimer, index) in userRunningtimer"
                            :key="index"
                            style="width: 100%"
                            class="pa-2 elevation-0 my-1 running-expansion"
                          >
                            <v-col cols="12" class="pa-0">
                              <div
                                class="text-body-1 text-capitalize font-weight-medium"
                              >
                                {{
                                  usertimer.userInfo.firstName +
                                  " " +
                                  usertimer.userInfo.lastName
                                }}
                              </div>
                              <div>
                                <span
                                  v-if="usertimer.runningduration.years != 0"
                                  class="pr-2"
                                >
                                  {{ usertimer.runningduration.years }}
                                  <small>(years)</small>
                                </span>
                                <span
                                  v-if="usertimer.runningduration.days != 0"
                                  class="pr-2"
                                >
                                  {{ usertimer.runningduration.days }}
                                  <small>(days)</small>
                                </span>
                                <span
                                  v-if="usertimer.runningduration.hours != 0"
                                  class="pr-2"
                                >
                                  {{ usertimer.runningduration.hours }}
                                  <small>hrs</small>
                                </span>
                                <span
                                  v-if="usertimer.runningduration.minutes != 0"
                                  class="pr-2"
                                >
                                  {{ usertimer.runningduration.minutes }}
                                  <small>mins</small>
                                </span>
                                <span
                                  v-if="usertimer.runningduration.seconds != 0"
                                  class="pr-2"
                                >
                                  {{ usertimer.runningduration.seconds }}
                                  <small>sec</small>
                                </span>
                              </div>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                </div>
                <div v-if="timespendedusers.length > 0" style="width: 100%">
                  <h5
                    class="text-subtitle-1 first-character font-weight-medium mb-2"
                  >
                    Time spent for this task
                  </h5>
                  <div class="mr-0 mr-md-3 mr-sm-3">
                    <v-expansion-panels v-model="infopanel" multiple>
                      <v-expansion-panel
                        v-for="(user, index) in timespendedusers"
                        :key="index"
                      >
                        <v-expansion-panel-header
                          v-if="user.spendtime"
                          class="pa-1"
                        >
                          <v-row class="align-center">
                            <v-col cols="1">
                              <userinfo
                                :user="user"
                                :clickable="true"
                                :userId="user.id"
                              ></userinfo>
                            </v-col>
                            <v-col cols="5" class="d-flex align-center">
                              <p class="first-character mb-0 flex-grow-1 pl-2">
                                {{ firstletterupercase(user.firstName) }}
                                {{ firstletterupercase(user.lastName) }}
                              </p>
                            </v-col>
                            <v-col
                              cols="6"
                              class="d-flex align-center pa-0 justify-end"
                            >
                              <div
                                class="d-flex justify-end text-caption align-center"
                              >
                                <h6
                                  class="ma-0 font-weight-medium text-caption"
                                >
                                  Total :&nbsp;
                                </h6>
                                <span v-if="user.spendtime.textTime">{{
                                  user.spendtime.textTime
                                }}</span>
                                <div
                                  class="d-flex align-center pl-1 pr-1"
                                  v-if="
                                    user.spendtime.textTime &&
                                    user.spendtime.textOTtime
                                  "
                                >
                                  &nbsp;+&nbsp;
                                </div>
                                <span v-if="user.spendtime.textOTtime">
                                  {{ user.spendtime.textOTtime }}</span
                                >
                              </div>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content v-if="user.spendtime">
                          <div class="timeline-find-child">
                            <div
                              v-for="(hisotyobject, hindex) in user.spendtime
                                .history"
                              :key="hindex"
                            >
                              <template>
                                <v-timeline
                                  align-top
                                  dense
                                  left
                                  class="timer-task-timeline"
                                  truncate-line="end"
                                >
                                  <v-timeline-item
                                    color="primary"
                                    small
                                    align-dot="start"
                                    width="50px"
                                    :max-width="50"
                                  >
                                    <v-row class="pa-1 align-center">
                                      <v-col
                                        cols="6"
                                        class="text-caption pa-0"
                                        :class="{
                                          'grey-tag': !$vuetify.theme.dark,
                                          'black--text': $vuetify.theme.dark,
                                        }"
                                        >{{ hisotyobject.formateddate }}</v-col
                                      >
                                      <v-col
                                        cols="6"
                                        :class="{
                                          'grey-tag': !$vuetify.theme.dark,
                                          'black--text': $vuetify.theme.dark,
                                        }"
                                        class="text-caption pa-0"
                                      >
                                        <v-row class="align-center justify-end">
                                          <div v-if="hisotyobject.totalhr">
                                            {{ hisotyobject.totalhr }}
                                          </div>
                                        </v-row>
                                      </v-col>
                                    </v-row>
                                  </v-timeline-item>
                                </v-timeline>
                              </template>
                            </div>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                  <v-row>
                    <v-col cols="7"><b>Total</b></v-col>
                    <v-col cols="5" class="d-flex justify-end">
                      <b> {{ getTotaltimer }}</b>
                    </v-col>
                  </v-row>
                </div>
                <v-row style="width: 100%">
                  <v-col cols="12" class="d-flex flex-column flex-wrap pa-0">
                    <h5
                      v-if="canEditTask"
                      class="text-subtitle-1 text-capitalize font-weight-medium mb-0"
                    >
                      Add labels
                    </h5>
                    <span v-if="canEditTask">
                      <v-menu
                        v-model="projectAllLables"
                        :close-on-content-click="false"
                        offset-y
                        top
                        :elevation="1"
                        class="add-label-menu"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            text
                            @click="dispatchprojectlable"
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            color="primary"
                            class="mr-4"
                          >
                            <v-icon small color="primary" class="pr-2"
                              >label</v-icon
                            >Add Label
                          </v-btn>
                        </template>
                        <v-card style="width: 290px">
                          <p class="font-weight-medium black--text pa-2 ma-0">
                            Labels
                          </p>
                          <div>
                            <v-divider></v-divider>
                            <v-text-field
                              solo
                              dense
                              v-model="searchlable"
                              label="Search lables"
                              append-icon="search"
                              class="ma-2"
                              style="width: 90%"
                            ></v-text-field>
                            <v-row class="ma-2">
                              <div
                                v-for="label in filteredlables"
                                :key="label.id"
                              >
                                <v-chip
                                  class="ma-1 word-break text-wrap"
                                  @click="addTaskLable(label.id)"
                                  small
                                  color="primary"
                                  style="height: auto"
                                >
                                  {{ label.name }}</v-chip
                                >
                              </div>
                            </v-row>
                            <v-list></v-list>
                            <v-menu
                              v-model="AddprojectLablemenu"
                              offset-y
                              top
                              attach
                              :close-on-content-click="false"
                              class="inside-add-label"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  text
                                  @click="
                                    lable = '';
                                    $validator.reset();
                                  "
                                  class="ml-2"
                                >
                                  <v-icon small class="pr-2">add</v-icon>Add
                                  Label
                                </v-btn>
                              </template>
                              <v-card>
                                <div class="text-caption black--text pa-2">
                                  Add label
                                </div>
                                <div>
                                  <v-row>
                                    <v-col cols="10">
                                      <v-text-field
                                        v-model="lable"
                                        label="labels"
                                        v-validate="'required|max:50'"
                                        data-vv-name="lable"
                                        :error-messages="
                                          errors.collect('add-new-lable.lable')
                                        "
                                        data-vv-as="labels"
                                        data-vv-scope="add-new-lable"
                                        dense
                                        type="text"
                                        class="pl-2"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="2" class="d-flex pa-0 pb-2">
                                      <v-tooltip bottom>
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-icon
                                            color="primary"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="addNewLabels"
                                            icon
                                          >
                                            add</v-icon
                                          >
                                        </template>
                                        <span>Add Label</span>
                                      </v-tooltip>
                                    </v-col>
                                  </v-row>
                                </div>
                              </v-card>
                            </v-menu>
                          </div>
                        </v-card>
                      </v-menu>
                    </span>
                    <div class="d-flex flex-wrap pt-4">
                      <div
                        v-for="tasklable in tasklables"
                        :key="tasklable.id"
                        class="pa-1"
                      >
                        <v-chip
                          class="ma-1 word-break text-wrap"
                          close
                          small
                          color="primary"
                          outlined
                          style="height: auto"
                          @click:close="removelables(tasklable.id)"
                          >{{ tasklable.name }}</v-chip
                        >
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div v-if="task.customFields && task.customFields.length > 0">
                <h5 class="text-subtitle-1 text-capitalize font-weight-medium">
                  custom fields
                </h5>
                <div v-for="taskfield in task.customFields" :key="taskfield.id">
                  <div
                    v-for="projectcustomfield in project.customFields"
                    :key="projectcustomfield.id"
                  >
                    <div
                      v-if="projectcustomfield.id == taskfield.projectcustomId"
                    >
                      <div v-if="taskfield.inputType == 'date'">
                        <div v-if="taskfield.requiredStatus == true">
                          <div
                            class="d-flex flex-column align-start justify-space-between mb-5"
                          >
                            <p class="subtitle-2 mb-0">
                              {{ projectcustomfield.name }}
                            </p>
                            <v-menu
                              :disabled="canEditTask == false"
                              v-model="taskfield.datepopupstatus"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  :disabled="canEditTask == false"
                                  v-model="taskfield.value"
                                  :label="projectcustomfield.name"
                                  append-icon="event"
                                  readonly
                                  outlined
                                  dense
                                  :nudge-right="40"
                                  v-bind="attrs"
                                  v-on="on"
                                  v-validate="'required'"
                                  :data-vv-name="taskfield.name"
                                  :error-messages="
                                    errors.collect(
                                      'create-task.' + taskfield.name
                                    )
                                  "
                                  :data-vv-as="taskfield.name"
                                  data-vv-scope="create-task"
                                  style="width: 100%"
                                  class="dateinput"
                                >
                                </v-text-field>
                              </template>
                              <v-date-picker
                                v-model="taskfield.value"
                                @input="
                                  editCustomDate(taskfield);
                                  taskfield.datepopupstatus = false;
                                "
                                style="width: 100%"
                              ></v-date-picker>
                            </v-menu>
                          </div>
                        </div>
                        <div v-else>
                          <div
                            class="d-flex flex-column align-start justify-space-between mb-5"
                          >
                            <p class="subtitle-2 mb-0">
                              {{ projectcustomfield.name }}
                            </p>
                            <v-menu
                              :disabled="canEditTask == false"
                              v-model="taskfield.datepopupstatus"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  :disabled="canEditTask == false"
                                  v-model="taskfield.value"
                                  :label="projectcustomfield.name"
                                  append-icon="event"
                                  readonly
                                  dense
                                  :nudge-right="40"
                                  outlined
                                  v-bind="attrs"
                                  v-on="on"
                                  style="width: 100%"
                                  class="dateinput"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="taskfield.value"
                                @input="
                                  editCustomDate(taskfield);
                                  taskfield.datepopupstatus = false;
                                "
                                style="width: 100%"
                              ></v-date-picker>
                            </v-menu>
                          </div>
                        </div>
                      </div>
                      <div v-if="taskfield.inputType == 'dropdown'">
                        <div
                          class="d-flex flex-column align-start justify-space-between mb-5"
                        >
                          <p class="subtitle-2 mb-0">
                            {{ projectcustomfield.name }}
                          </p>
                          <div style="width: 100%">
                            <v-select
                              :disabled="canEditTask == false"
                              v-model="taskfield.attributeValueId"
                              :items="projectcustomfield.dropdownAttributes"
                              item-text="name"
                              item-value="id"
                              outlined
                              attach
                              dense
                              :nudge-right="40"
                              :label="projectcustomfield.name"
                              :menu-props="{
                                top: true,
                                offsetY: true,
                                closeOnContentClick: true,
                              }"
                              v-validate="'required'"
                              :data-vv-as="projectcustomfield.name"
                              :error-messages="
                                errors.collect(
                                  'create-task.' + projectcustomfield.name
                                )
                              "
                              :data-vv-name="projectcustomfield.name"
                              data-vv-scope="create-task"
                            >
                              <template v-slot:item="{ item }">
                                <v-list-item
                                  :input-value="
                                    item.id == taskfield.attributeValueId
                                  "
                                  @click="editCustomDropdown(item, taskfield)"
                                >
                                  <span class="text-body-2">{{
                                    item.name
                                  }}</span>
                                </v-list-item>
                              </template>
                              <template v-slot:selection="{ item }">{{
                                item.name
                              }}</template>
                            </v-select>
                          </div>
                        </div>
                      </div>
                      <div v-if="taskfield.inputType == 'text'">
                        <div
                          v-if="taskfield.requiredStatus == true"
                          class="d-flex flex-column align-start justify-space-between mb-5"
                        >
                          <p class="subtitle-2 mb-0">
                            {{ projectcustomfield.name }}
                          </p>
                          <v-textarea
                            :label="projectcustomfield.name"
                            outlined
                            auto-grow
                            rows="1"
                            row-height="15"
                            :nudge-right="40"
                            :disabled="canEditTask == false"
                            dense
                            :maxlength="255"
                            v-model="taskfield.value"
                            @input="editTaskcustomtext(taskfield)"
                            v-validate="'required'"
                            :data-vv-as="projectcustomfield.name"
                            :error-messages="
                              errors.collect(
                                'create-task.' + projectcustomfield.name
                              )
                            "
                            :data-vv-name="projectcustomfield.name"
                            data-vv-scope="create-task"
                            style="wdth: 100%"
                          >
                          </v-textarea>
                        </div>
                        <div
                          v-else
                          class="d-flex flex-column align-start justify-space-between mb-5"
                        >
                          <p class="subtitle-2 mb-0">
                            {{ projectcustomfield.name }}
                          </p>
                          <v-textarea
                            :maxlength="255"
                            auto-grow
                            rows="1"
                            row-height="15"
                            @input="editTaskcustomtext(taskfield)"
                            :label="taskfield.name"
                            outlined
                            :nudge-right="40"
                            :disabled="canEditTask == false"
                            dense
                            v-model="taskfield.value"
                            style="width: 100%"
                          ></v-textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <!-- TODO: REMOVE DIALOG -->
        <v-dialog v-model="reporttimeopen" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <div class="horizontal-dividing-header mb-0">
                <h6 class="header text-uppercase mt-0">Add Report Time</h6>
              </div>
            </v-card-title>
            <v-container fluid class="pt-0 pb-0">
              <v-row>
                <v-col>
                  <div>
                    <v-menu
                      ref="reportmenu"
                      v-model="reportmenu"
                      :close-on-content-click="false"
                      :return-value.sync="reportdate"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          append-icon="event"
                          dense
                          v-model="reportdate"
                          label="Picker in menu"
                          prepend-icon=""
                          readonly
                          :error-messages="errors.collect('add-reportime.date')"
                          data-vv-as="date"
                          data-vv-scope="add-reportime"
                          data-vv-name="date"
                          v-validate="'required'"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="reportdate"
                        no-title
                        :max="moment().format('YYYY-MM-DD')"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="canceladdreport">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.reportmenu.save(reportdate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Worked hours"
                    type="number"
                    min="0"
                    dense
                    :error-messages="errors.collect('add-reportime.hours')"
                    data-vv-as="hours"
                    data-vv-scope="add-reportime"
                    data-vv-name="hours"
                    v-validate="
                      'required|numeric|max_value:23|checkhoursandminutes:minutes'
                    "
                    v-model="reporthour"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Worked minutes"
                    min="0"
                    dense
                    :error-messages="errors.collect('add-reportime.minutes')"
                    data-vv-as="minutes"
                    data-vv-scope="add-reportime"
                    data-vv-name="minutes"
                    v-validate="'required|numeric|max_value:60'"
                    ref="minutes"
                    v-model="reportminutes"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="red" text @click="makeaddTimeinputempty"
                >Close</v-btn
              >
              <v-btn
                :class="{
                  'gradient-button white--text': !$vuetify.theme.dark,
                  'white black--text': $vuetify.theme.dark,
                }"
                :loading="addreportbtnLoading"
                tile
                contained
                text
                :disabled="addreportimestatus"
                @click="addreportTime()"
              >
                Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="assignUsersDialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <div class="horizontal-dividing-header mb-0">
                <h5 class="header text-uppercase mt-0">Assign Users</h5>
              </div>
            </v-card-title>
            <v-card-text>
              <v-list>
                <template v-for="user in projectUsers">
                  <v-list-item
                    class="ma-2 pl-0 pr-0 assigned-user-list"
                    :key="user.user"
                  >
                    <v-list-item-content class="pa-0 text-body-1">
                      <v-checkbox
                        v-model="selectedUsers"
                        :label="
                          firstletterupercase(user.firstName) +
                          ' ' +
                          firstletterupercase(user.lastName)
                        "
                        :value="user.user"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-card-text>
            <v-card-actions class="add-task-sticky sticky-bar elevation-4">
              <div class="flex-grow-1"></div>
              <v-btn
                color="red"
                text
                :loading="btnLoading"
                :disabled="btnLoading"
                @click="closeAssignUsersDialog"
                >Close</v-btn
              >
              <v-btn
                :class="{
                  'gradient-button white--text': !$vuetify.theme.dark,
                  'white black--text': $vuetify.theme.dark,
                }"
                :loading="btnLoading"
                :disabled="btnLoading"
                tile
                contained
                text
                @click="assignUsers"
              >
                Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <confirmation
          :propdialog="confirmdiffertimezone"
          dialogTitle="Please note that there is a change in timezone between start and stop date, are you sure you want to continue?"
          @onConfirmed="
            confirmstatus = true;
            StopTimeCounter(StopTimeCounterinfo);
          "
          @onCancel="
            confirmstatus = false;
            confirmdiffertimezone = false;
          "
          key="complete-timezoneconfirmation"
        >
        </confirmation>
        <confirmation
          :propdialog="opencompleteconfirmpopup"
          dialogTitle="Are you sure you want to mark this task complete as you are not the action required user?"
          @onConfirmed="updatetaskascompleted"
          @onCancel="opencompleteconfirmpopup = false"
          key="complete-confirmation"
        >
        </confirmation>
        <confirmation
          :propdialog="dialog"
          :dialogTitle="dialogTitle"
          @onConfirmed="removeAssignedUser"
          @onCancel="closeDeleteUserDialog"
          key="delete-user"
        ></confirmation>
        <confirmation
          :propdialog="deleteFileDialog"
          dialogTitle="Are you sure you want to delete this attachment"
          @onConfirmed="removeFile"
          @onCancel="closeDeleteFileDialog"
          key="delete-comment-attachment"
        ></confirmation>
        <confirmation
          :propdialog="deleteCommentDialog"
          dialogTitle="Are you sure you want to delete this comment"
          @onConfirmed="deleteComment"
          @onCancel="closeDeleteCommentDialog"
          key="delete-comment"
        ></confirmation>
        <confirmation
          :propdialog="deleteTaskFileDialog"
          dialogTitle="Are you sure you want to delete this attachment"
          @onConfirmed="removeTaskFile"
          @onCancel="closeDeleteTaskFileDialog"
          key="delete-task-attachment"
        >
        </confirmation>
      </v-card>
      <v-snackbar
        v-model="socketdisconnect"
        absolute
        centered
        :right="true"
        :top="true"
        :timeout="-1"
        color="red accent-2"
      >
        <strong>Oops, something went wrong. Refresh the page or click</strong>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="loadpage" color="green accent-2">
            reload
          </v-btn>
        </template>
      </v-snackbar>
      <div v-show="renderaddtask">
        <addTask
          :key="'single-task'"
          :global="false"
          :buttontext="'+Add Subtask'"
          :subtask="true"
          :parentask="task"
          :showbuttonstatus="showbuttonstatus"
          :ParamWorkspace="project.workspace"
          :ParamProject="task.project"
          @closestatus="closepopup"
        >
        </addTask>
      </div>
    </v-dialog>
    <div v-if="showTemplate">
      <v-card v-if="loading" class="mx-auto">
        <v-card-text>
          Getting task
          <v-progress-linear indeterminate color="primary" class="mb-0">
          </v-progress-linear>
        </v-card-text>
      </v-card>
      <v-card
        v-else
        :elevation="0"
        :style="[windowWidth > 767 ? { height: windowHeight - 60 + 'px' } : '']"
      >
        <v-container fluid>
          <v-row>
            <BreadCrumbs> </BreadCrumbs>
          </v-row>
          <v-row align="stretch" class="popup-task-section">
            <v-col
              cols="12"
              sm="12"
              md="7"
              :class="windowWidth > 959 ? 'popup-scroll-section' : ''"
              :style="[
                windowWidth > 959 ? { height: windowHeight - 60 + 'px' } : '',
              ]"
            >
              <div v-if="task.IssubtaskChild">
                <h6>
                  <v-row>
                    <v-col class="pt-0"
                      ><b
                        ><v-icon small color="grey"> polyline </v-icon>
                        <router-link
                          :to="{
                            name: 'single-task',
                            params: {
                              id: task.project,
                              taskId: task.parenttask.id,
                            },
                            query: {
                              companyId: $route.query.companyId,
                            },
                          }"
                          class="text-decoration-none black--text"
                        >
                          {{ task.parenttask.title }}</router-link
                        >
                      </b></v-col
                    >
                  </v-row>
                </h6>
              </div>
              <div class="description-wrapper">
                <div
                  class="Task task-header d-flex align-start align-sm-center align-md-center flex-column flex-sm-column flex-md-column justify-space-between"
                >
                  <div
                    class="d-flex align-start flex-column flex-grow-1"
                    style="width: 100%"
                  >
                    <div
                      class="text-caption d-flex align-center font-weight-light date-width py-2"
                    >
                      <div
                        v-if="task.startDate && task.endDate"
                        class="d-flex align-center flex-grow-1 calendar-view-popup"
                      >
                        <v-icon small class="pr-1">calendar_today</v-icon>
                        <div class="pt-1">
                          {{
                            task.startDate
                              ? moment(task.startDate, "YYYY-MM-DD").format(
                                  "DD-MM-YYYY"
                                )
                              : ""
                          }}
                          to
                          {{
                            task.endDate
                              ? moment(task.endDate, "YYYY-MM-DD").format(
                                  "DD-MM-YYYY"
                                )
                              : ""
                          }}
                        </div>
                      </div>
                      <div class="d-flex align-center flex-grow-1 justify-end">
                        <div small class="pined-task" @click="pinnedTask(task)">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                                small
                              >
                                <v-icon
                                  medium
                                  :class="[
                                    pinnedTaskbtn == true
                                      ? 'primary--text'
                                      : 'grey--text',
                                  ]"
                                  >push_pin</v-icon
                                >
                              </v-btn>
                            </template>
                            <span v-if="pinnedTaskbtn == true"
                              >Pinned Task</span
                            >
                            <span v-else>Unpinned Task</span>
                          </v-tooltip>
                        </div>
                        <div>
                          <v-row v-if="chackSettingstatus(task) == true">
                            <v-col class="setting-icon-set">
                              <v-menu
                                left
                                :close-on-content-click="false"
                                offset-y
                                class="delete-icon-set"
                                style="width: 200px !important"
                                attach
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    small
                                    icon
                                    color="black"
                                    v-on="on"
                                    class="setting-icon-button"
                                  >
                                    <v-icon small>settings</v-icon>
                                  </v-btn>
                                </template>
                                <div>
                                  <div
                                    v-if="
                                      (task.IssubtaskParent == false &&
                                        task.IssubtaskChild == false) ||
                                      task.IssubtaskParent == true
                                    "
                                    class="pa-2 setting-menu-icon"
                                    :class="{
                                      'background-lite': !$vuetify.theme.dark,
                                      'background-dark': $vuetify.theme.dark,
                                    }"
                                    style="cursor: pointer"
                                  >
                                    <div @click="createDialogOpen()">
                                      Create Subtask
                                    </div>
                                  </div>
                                  <div
                                    v-if="
                                      task.IssubtaskParent == false &&
                                      task.IssubtaskChild == false &&
                                      canEditTask == true
                                    "
                                    class="pa-2 setting-menu-icon"
                                    :class="{
                                      'background-lite': !$vuetify.theme.dark,
                                      'background-dark': $vuetify.theme.dark,
                                    }"
                                    style="cursor: pointer"
                                  >
                                    <v-dialog
                                      v-model="subtaskpopup"
                                      width="500"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <div
                                          @click="openconvertTosubtask()"
                                          v-bind="attrs"
                                          v-on="on"
                                          style="cursor: pointer"
                                        >
                                          <div>Convert to subtask</div>
                                        </div>
                                      </template>
                                      <v-card>
                                        <v-card-title
                                          :class="{
                                            'grey lighten-4':
                                              !$vuetify.theme.dark,
                                            primary: $vuetify.theme.dark,
                                          }"
                                          class="d-flex flex-column align-start"
                                        >
                                          <div
                                            class="horizontal-dividing-header mb-0"
                                          >
                                            <h6
                                              class="header text-uppercase mt-0"
                                            >
                                              convert task to subtask
                                            </h6>
                                          </div>
                                          <!-- <v-row>
                                          <v-card-title
                                            v-if="parentasksinfo.project"
                                            @click="
                                              gotoworkspace(
                                                parentasksinfo.project
                                              )
                                            "
                                            class="pa-0 text-body-1"
                                            :class="{
                                              'primary--text': !$vuetify.theme
                                                .dark,
                                              'black--text':
                                                $vuetify.theme.dark,
                                            }"
                                          >
                                            {{ parentasksinfo.project.name }}
                                            <v-icon
                                              class="pl-2 pr-2"
                                              small
                                              :class="{
                                                'primary--text': !$vuetify.theme
                                                  .dark,
                                                'black--text':
                                                  $vuetify.theme.dark,
                                              }"
                                              >chevron_right</v-icon
                                            >
                                          </v-card-title>
                                          <v-card-title
                                            v-if="parentasksinfo.workspace"
                                            @click="
                                              gotoworkspace(
                                                parentasksinfo.workspace
                                              )
                                            "
                                            class="pa-0 text-body-1"
                                            :class="{
                                              'primary--text': !$vuetify.theme
                                                .dark,
                                              'black--text':
                                                $vuetify.theme.dark,
                                            }"
                                          >
                                            {{ parentasksinfo.workspace.name }}
                                          </v-card-title>
                                        </v-row> -->
                                        </v-card-title>
                                        <v-card-text v-if="parentasksinfo">
                                          <v-card-subtitle
                                            v-if="
                                              parentasksinfo.alltask.length > 0
                                            "
                                            class="pa-0 pt-3"
                                          >
                                            Select Parent Task</v-card-subtitle
                                          >

                                          <v-text-field
                                            solo
                                            dense
                                            v-model="searchParenttask"
                                            label="Search   "
                                            append-icon="search"
                                            class="ma-2"
                                            style="width: 90%"
                                          ></v-text-field>
                                          <div
                                            v-if="
                                              parentasksinfo.alltask.length > 0
                                            "
                                          >
                                            <v-radio-group
                                              v-model="selectedparent"
                                              column
                                              class="mt-0"
                                            >
                                              <v-col
                                                cols="12"
                                                v-for="singletask in searchedParentTask"
                                                :key="singletask.id"
                                                class="pl-0"
                                              >
                                                <v-radio
                                                  :label="singletask.name"
                                                  :value="singletask.id"
                                                ></v-radio>
                                              </v-col>
                                            </v-radio-group>
                                          </div>
                                          <div
                                            v-else
                                            class="pt-5 text-center text-h6 black--text"
                                          >
                                            <p v-if="parentasksinfo.alltask">
                                              Parent task not found
                                            </p>
                                          </div>
                                        </v-card-text>
                                        <v-card-actions>
                                          <div class="flex-grow-1"></div>
                                          <v-btn
                                            color="red"
                                            text
                                            @click="
                                              subtaskpopup = false;
                                              selectedparent = '';
                                              searchParenttask = '';
                                            "
                                            >Close</v-btn
                                          >
                                          <v-btn
                                            v-if="
                                              parentasksinfo.alltask.length > 0
                                            "
                                            :class="{
                                              'gradient-button white--text':
                                                !$vuetify.theme.dark,
                                              'white black--text':
                                                $vuetify.theme.dark,
                                            }"
                                            :loading="btnLoading"
                                            :disabled="btnLoading"
                                            tile
                                            contained
                                            text
                                            @click="subminconvertsubtask()"
                                          >
                                            Save
                                          </v-btn>
                                        </v-card-actions>
                                      </v-card>
                                    </v-dialog>
                                  </div>
                                  <div
                                    v-if="
                                      task.IssubtaskChild == true &&
                                      canEditTask == true
                                    "
                                    @click="convertToTask()"
                                    class="pa-2 setting-menu-icon"
                                    :class="{
                                      'background-lite': !$vuetify.theme.dark,
                                      'background-dark': $vuetify.theme.dark,
                                    }"
                                    style="cursor: pointer"
                                  >
                                    <div>Convert to normal task</div>
                                  </div>
                                  <div
                                    v-if="canDeleteTask"
                                    class="pa-2 setting-menu-icon"
                                    :class="{
                                      'background-lite': !$vuetify.theme.dark,
                                      'background-dark': $vuetify.theme.dark,
                                    }"
                                    :disabled="canDeleteTask == false"
                                    @click="deleteConformDialog = true"
                                    style="cursor: pointer"
                                  >
                                    <div>Delete Task</div>
                                  </div>
                                  <div
                                    v-if="canDeleteTask"
                                    class="pa-2 setting-menu-icon"
                                    :class="{
                                      'background-lite': !$vuetify.theme.dark,
                                      'background-dark': $vuetify.theme.dark,
                                    }"
                                    :disabled="canDeleteTask == false"
                                    @click="getallprojects"
                                    style="cursor: pointer"
                                  >
                                    <div>Change Project</div>
                                  </div>
                                </div>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex align-center" style="width: 100%">
                      <userinfo
                        :clickable="true"
                        :user="task.ownername"
                        :userId="task.ownername.id"
                      ></userinfo>
                      <div
                        class="d-flex align-center flex-column"
                        style="width: 100%"
                      >
                        <h6
                          v-if="canEditTask"
                          class="text-body-1 text-capitalize ma-0 word-break font-weight-medium"
                          @click="editTitle"
                          v-show="!showTitleInput"
                          style="width: 100%"
                        >
                          <div class="pointercursor d-flex align-center">
                            {{ task.title }}
                            <v-btn icon>
                              <v-icon small>edit</v-icon>
                            </v-btn>
                          </div>
                        </h6>
                        <h6 v-else class="text-h5 ml-4" style="width: 100%">
                          <div>{{ task.title }}</div>
                        </h6>
                        <div
                          class="d-flex align-center flex-grow-1"
                          style="width: 100%"
                        >
                          <v-text-field
                            append-icon="edit"
                            label="title"
                            v-if="canEditTask"
                            ref="editTitle"
                            class="pl-2"
                            single-line
                            v-model="task.title"
                            @blur="saveTitle"
                            hide-details
                            v-show="showTitleInput"
                            :loading="titleLoading"
                          ></v-text-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-center">
                  <div
                    class="description mt-6 flex-grow-1"
                    v-show="!showDescriptionInput"
                    v-if="canEditTask"
                    @click="editDescription"
                  >
                    <p class="d-flex align-center">
                      <v-btn icon>
                        <v-icon>edit</v-icon>
                      </v-btn>
                      <span
                        v-if="task.description"
                        style="white-space: pre-line"
                      >
                        <div
                          class="pointercursor"
                          v-html="replaceURLs(task.description)"
                        ></div>
                      </span>
                      <span v-else>
                        <small class="pointercursor">
                          <i>NO DESCRIPTION FOR THIS TASK</i>
                        </small>
                      </span>
                    </p>
                  </div>
                  <div v-else>
                    <p>
                      <span v-if="task.description">
                        <div v-html="replaceURLs(task.description)"></div>
                      </span>
                      <span v-else>
                        <small>
                          <i>NO DESCRIPTION FOR THIS TASKlll</i>
                        </small>
                      </span>
                    </p>
                  </div>
                  <div v-show="showDescriptionInput" class="pb-2 flex-grow-1">
                    <v-textarea
                      prepend-icon="edit"
                      ref="editDescription"
                      v-if="canEditTask"
                      :auto-grow="true"
                      class="pl-2"
                      single-line
                      v-model="task.description"
                      @blur="saveDescription"
                      hide-details
                      :loading="descriptionLoading"
                    ></v-textarea>
                  </div>
                </div>
              </div>
              <v-dialog v-model="changeprojectpopup" width="500">
                <v-card>
                  <v-card-title
                    :class="{
                      'grey lighten-4': !$vuetify.theme.dark,
                      primary: $vuetify.theme.dark,
                    }"
                    class="d-flex flex-column align-start"
                  >
                    <h6 class="header text-uppercase mt-0">
                      Change Task of project
                    </h6>
                  </v-card-title>
                  <v-card-text>
                    <v-card-subtitle
                      v-if="allprojects.length > 0"
                      class="pa-0 pt-3"
                    >
                      Select Changing Project</v-card-subtitle
                    >
                    <v-text-field
                      solo
                      dense
                      v-model="searchproject"
                      label="Search   "
                      append-icon="search"
                      class="ma-2"
                      style="width: 90%"
                    ></v-text-field>
                    <div v-if="searchedAllproject.length > 0">
                      <v-radio-group
                        v-model="selectedproject"
                        column
                        class="mt-0"
                      >
                        <v-col
                          cols="12"
                          v-for="singleproject in searchedAllproject"
                          :key="singleproject.id"
                          class="pl-0"
                        >
                          <v-radio
                            :label="singleproject.name"
                            :value="singleproject.id"
                          ></v-radio>
                        </v-col>
                      </v-radio-group>
                    </div>
                    <div v-else class="pt-5 text-center text-h6 black--text">
                      <p v-if="allprojects">Projects not found</p>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn
                      color="red"
                      text
                      @click="
                        changeprojectpopup = false;
                        selectedproject = '';
                      "
                      >Close</v-btn
                    >
                    <v-btn
                      v-if="allprojects.length > 0"
                      :class="{
                        'gradient-button white--text': !$vuetify.theme.dark,
                        'white black--text': $vuetify.theme.dark,
                      }"
                      tile
                      contained
                      text
                      @click="switchprojectconfirm = true"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="switchprojectconfirm" max-width="500">
                <v-card>
                  <v-card-title>Are you sure?</v-card-title>
                  <v-card-text class="first-character">
                    Are you sure you want to change this task to a different
                    project?</v-card-text
                  >
                  <v-card-text class="first-character">
                    <small>
                      <h6>Please note that</h6>
                      <p>
                        *All custom fields and labels of this task would be
                        removed while moving this task to a different project
                      </p>
                      <p>
                        *All task activity history except user comments and file
                        attachments would be removed
                      </p>
                      <p v-if="task.IssubtaskParent == true">
                        *Sub tasks of this task will also be retained.
                      </p>
                    </small>
                  </v-card-text>
                  <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn
                      text
                      :loading="disablechangeproject"
                      :disabled="disablechangeproject"
                      @click="switchprojectconfirm = false"
                      class="red--text"
                      >Cancel</v-btn
                    >
                    <v-btn
                      class="primary"
                      :class="{
                        'white--text': !$vuetify.theme.dark,
                        'black--text': $vuetify.theme.dark,
                      }"
                      outlined
                      tile
                      :loading="disablechangeproject"
                      :disabled="disablechangeproject"
                      @click="submitchangetask()"
                      >Yes</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-row class="mt-0">
                <div
                  class="font-weight-bold pl-2 pt-6 pb-2 d-flex align-center justify-space-between"
                  v-if="canEditTask || sortedArray.length > 0"
                  style="width: 100%"
                >
                  Todo Lists
                  <div
                    class="d-flex align-center"
                    v-if="sortedArray.length > 0"
                  >
                    <span class="text-body-2 pt-1 font-weight-bold"
                      >Hide completed</span
                    >
                    <v-switch
                      @change="updatehidecompletedtodo()"
                      color="primary"
                      v-model="showcompletedtodoUsers"
                      hide-details
                      class="mt-0 pl-3"
                    ></v-switch>
                  </div>
                </div>
                <v-col cols="12" class="pa-0 text-body-1">
                  <template>
                    <draggable
                      @end="dragablefunction"
                      :options="{ filter: '.drag-disable' }"
                    >
                      <div
                        v-for="todolist in sortedArray"
                        :key="todolist.id"
                        :id="todolist.id"
                        :class="[
                          checkshowtodostatus(todolist)
                            ? todoAuthstatus(todolist, true) == false
                              ? 'd-flex align-center pb-3 drag-disable'
                              : 'd-flex align-center pb-3'
                            : '',
                        ]"
                      >
                        <template v-if="checkshowtodostatus(todolist)">
                          <v-btn
                            v-if="todoAuthstatus(todolist, true)"
                            depressed
                            icon
                            x-small
                            title="reorder task"
                            class="reorder-drag"
                          >
                            <v-icon>drag_indicator</v-icon>
                          </v-btn>
                          <v-checkbox
                            :class="windowWidth < 959 ? 'drag-disable' : ''"
                            :disabled="todoAuthstatus(todolist, true) == false"
                            @change="checktodo(todolist)"
                            v-model="todolist.completed_status"
                            hide-details
                            class="shrink mr-0 mt-0"
                          ></v-checkbox>
                          <v-row class="align-center">
                            <v-col
                              cols="11"
                              md="11"
                              class="d-flex align-center pa-0 mb-0"
                            >
                              <div
                                class="flex-grow-1"
                                :class="windowWidth < 959 ? 'drag-disable' : ''"
                              >
                                <div
                                  class="d-flex flex-column"
                                  v-if="
                                    current_list_todo == true &&
                                    todolist.id == current_list_todo_id
                                  "
                                >
                                  <v-textarea
                                    :disabled="
                                      todoAuthstatus(todolist, false) == false
                                    "
                                    placeholder="Placeholder"
                                    dense
                                    auto-grow
                                    rows="1"
                                    row-height="15"
                                    v-model="todolist.name"
                                    v-validate="'required'"
                                    data-vv-name="todo name"
                                    data-vv-as="todo name"
                                    data-vv-scope="edittodo"
                                    :error-messages="
                                      errors.collect('edittodo.todo name')
                                    "
                                    @keydown.enter="onEdit($event, todolist)"
                                    v-click-outside="onClickOutside"
                                  >
                                  </v-textarea>
                                </div>
                                <div
                                  @click="single_todo_edit(todolist)"
                                  :id="todolist.id"
                                  v-else
                                >
                                  <div class="d-flex align-center">
                                    <h6
                                      :class="[
                                        todolist.completed_status == true
                                          ? 'completedtodos'
                                          : '',
                                        (todolist.authstatus = false
                                          ? 'pointercursor'
                                          : ''),
                                      ]"
                                      class="align-start justify-start text-wrap text-body-2 font-weight-light flex-grow-1 todo-wrap"
                                    >
                                      <div style="white-space: pre-line">
                                        {{ todolist.name }}
                                      </div>
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </v-col>
                            <v-col
                              cols="1"
                              md="1"
                              :class="windowWidth < 959 ? 'drag-disable' : ''"
                              class="pa-0 d-flex justify-end"
                            >
                              <v-icon
                                class="red--text todo-delete-button"
                                small
                                v-if="todoAuthstatus(todolist, false)"
                                :disabled="
                                  todoAuthstatus(todolist, false) == false
                                "
                                @click="deleteTodo(todolist)"
                                >delete</v-icon
                              >
                            </v-col>
                            <v-col cols="12" class="pa-0">
                              <div
                                class="d-flex align-center todo-username pt-1"
                              >
                                <div
                                  class="text-caption flex-grow-1 grey--text"
                                >
                                  {{ gettoDOowner(todolist) }}
                                </div>
                                <div
                                  class="text-caption flex-grow-1 justify-end d-flex grey--text"
                                >
                                  {{ moment(todolist.createdAt).fromNow() }}
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                        </template>
                      </div>
                    </draggable>
                  </template>
                </v-col>
              </v-row>

              <v-row v-if="canEditTask">
                <v-col cols="12" class="pl-0">
                  <v-row class="align-center">
                    <v-col cols="11" sm="11" md="11" class="pl-0">
                      <v-textarea
                        placeholder="New Todo"
                        outlined
                        auto-grow
                        dense
                        @keydown.enter="onSave"
                        slot
                        rows="1"
                        row-height="15"
                        v-model="todo_name"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="1" sm="1" md="1" class="pl-0">
                      <v-icon
                        small
                        @click="addtodotitle()"
                        :class="{
                          secondary: !$vuetify.theme.dark,
                          black: $vuetify.theme.dark,
                        }"
                        class="rounded-pill white--text pa-2"
                        >add</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <div v-if="subtasks.length > 0" class="pb-3">
                <h6 class="font-weight-bold text-body-2 pb-2">
                  Subtasks Lists
                </h6>
                <template>
                  <div>
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header
                          >Subtasks
                          <span>
                            &nbsp;&nbsp;&nbsp;
                            <v-icon small color="grey"> polyline </v-icon
                            >{{ subtasks.length }}</span
                          >
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="subTasks-panel">
                          <draggable
                            ghost-class="ghost-card"
                            class="list-group"
                            :list="sortedsubtask"
                            :options="{ filter: '.drag-disable' }"
                            group="tasks"
                            :animation="200"
                            @end="changeOrderAndStatus($event)"
                          >
                            <div
                              class="mt-2"
                              v-for="singletask in sortedsubtask"
                              :key="singletask.id"
                              :id="singletask.id"
                            >
                              <subtasks
                                @updateattribute="updateattribute"
                                @opensubtask="opensubtask"
                                :canEditTask="editstatus(singletask)"
                                :subtask="true"
                                :task="singletask"
                              ></subtasks>
                            </div>
                          </draggable>
                          <div class="d-flex align-center justify-center">
                            <v-btn
                              outlined
                              @click="createDialogOpen()"
                              class="pa-2 d-flex justify-center"
                              :class="{
                                'primary--text': !$vuetify.theme.dark,
                                white: $vuetify.theme.dark,
                              }"
                            >
                              <v-icon>add</v-icon>
                            </v-btn>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                </template>
              </div>
              <TaskAttachements
                @commitattachements="commitattachements"
                :proptask="task"
                :canEditTask="canEditTask"
                :canDeleteTask="canDeleteTask"
              />
              <v-divider class="my-5"></v-divider>
              <div id="comments" class="d-flex flex-column">
                <div class="post-comment">
                  <div class="d-inline-flex">
                    <v-icon small>edit</v-icon>
                    <div class="font-weight-medium text-subtitle-1 pl-2">
                      Post comment
                    </div>
                  </div>
                  <tiptap-vuetify
                    @keydown="typingOnCommentField"
                    ref="tiptapPostComment"
                    v-model="tiptapPostCommentcontent"
                    :extensions="extensions"
                    name="comment"
                    :disabled="commentLoader"
                    :card-props="{
                      loading: commentLoader,
                    }"
                  />
                  <!-- class="tiptap-vuetify-editor__content pa-0 elevation-4"
                    :class="{ error: !commentFilled }" -->
                  <!-- class="post-comment-box elevation-4" -->
                  <!-- accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*, .xlsx, text/plain" -->

                  <div class="d-flex justify-space-between align-center my-5">
                    <div class="d-inline-flex align-center">
                      <v-file-input
                        v-model="taskfiles"
                        v-if="canEditTask"
                        data-vv-scope="commentscope"
                        v-validate="
                          'ext:jpeg,jpg,png,svg,xls,xlsx,pdf,doc,docx,.ods,.ppt.txt,.3g2,.webm,.ogg,.mkv,.mov,.avi,.wmv,.flv,.mp4,.ogv,video/3gpp2,.ppt,audio/ogg,.asf,application/vnd.ms-asf,  application/vnd.ms-powerpoint'
                        "
                        accept="application/msword,.asf,,.ppt, application/vnd.ms-asf,application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*,.xlsx,.doc,.docx,.ods,text/plain, application/vnd.ozpenxmlformats-officedocument.wordprocessingml.document, application/msword,.3g2,.webm,.ogg,.mkv,.mov,.avi,.wmv,.flv,.mp4,.ogv"
                        @change="attachFile"
                        name="commentfile"
                        data-vv-as="file"
                        :error-messages="
                          errors.collect('commentscope.commentfile')
                        "
                        multiple
                        title="Add file"
                        :hide-input="true"
                        class="pt-0"
                      ></v-file-input>
                    </div>
                    <v-btn
                      :disabled="commentsubmitstatus || commentLoader"
                      color="primary"
                      v-if="canEditTask"
                      @click="postComment"
                    >
                      Submit
                      <v-icon small class="pl-2">send</v-icon>
                    </v-btn>
                  </div>
                  <template v-if="previewFiles.length > 0">
                    <h6>
                      {{ commentFiles.length }}
                      {{ previewFiles.length > 1 ? "files" : "file" }}
                      added
                    </h6>
                  </template>
                  <v-row>
                    <v-col
                      v-for="(file, index) in previewFiles"
                      :key="index"
                      cols="3"
                    >
                      <v-card class="d-flex justify-center align-center">
                        <template v-if="file.type == 'image'">
                          <v-img
                            :src="file.url"
                            aspect-ratio="1"
                            class="comment-file-icon"
                          ></v-img>
                        </template>
                        <template v-if="file.type == 'file'">
                          <v-icon class="comment-file-icon"
                            >file_present</v-icon
                          >
                        </template>
                        <!-- <template v-else>
                          <v-icon large :title="file.na me">insert_link</v-icon>
                        </template> -->
                        <v-btn
                          absolute
                          depressed
                          x-small
                          fab
                          class="delete-preview-button right top error"
                          :class="{
                            'white--text': !$vuetify.theme.dark,
                            'black--text': $vuetify.theme.dark,
                          }"
                          @click="removePreview(index)"
                        >
                          <v-icon>delete_outline</v-icon>
                        </v-btn>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
                <v-divider class="mb-5 mt-8"></v-divider>

                <div v-if="comments.length > 0">
                  <div class="d-inline-flex">
                    <v-icon>notes</v-icon>
                    <div class="font-weight-medium text-subtitle-1 pl-2">
                      Comments
                    </div>
                    <v-btn fab depressed color="primary" x-small class="ml-2">{{
                      comments.length
                    }}</v-btn>
                  </div>
                  <template v-for="comment in comments">
                    <comment
                      :canEditTask="canEditTask"
                      :canDeleteTask="canDeleteTask"
                      @deleteComment="deleteComment"
                      @editvalidation="editvalidation"
                      @updatetimer="updatetimer"
                      @openRemoveFileDialog="openRemoveFileDialog"
                      @openRemoveCommentDialog="openRemoveCommentDialog"
                      :propcomment="comment"
                      :project="project"
                      :task="task"
                      :priorityItems="project.priorities"
                      :statuses="project.statuses"
                      :taskTypes="project.task_types"
                      :key="comment.id"
                    ></comment>
                    <v-divider
                      class="my-2"
                      :key="'divider' + comment.id"
                    ></v-divider>
                  </template>
                </div>
              </div>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col
              cols="12"
              sm="12"
              md="5"
              :class="windowWidth > 959 ? 'popup-scroll-section' : ''"
              :style="[
                windowWidth > 959 ? { height: windowHeight - 60 + 'px' } : '',
              ]"
            >
              <div
                class="d-flex flex-column flex-md-row flex-sm-row align-start justify-space-between align-sm-center align-md-center mb-5"
              >
                <p class="subtitle-2 mb-2 mb-sm-0 mb-md-0">Type</p>
                <v-select
                  :disabled="canEditTask == false"
                  v-model="task.type"
                  :items="
                    project.task_types.filter(
                      (data) => data.activeStatus == true
                    )
                  "
                  item-text="name"
                  item-value="id"
                  dense
                  outlined
                  label="Type"
                  style="max-width: 200px"
                  hide-details
                  attach
                  :menu-props="{ closeOnContentClick: true }"
                  class="hide-drop-style"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item
                      :input-value="item.id == task.type"
                      @click="changeTasktype(item)"
                    >
                      <span class="text-body-2">
                        <v-icon>{{ item.icon }}</v-icon> {{ item.name }}
                      </span>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span class="text-truncate">
                      <v-icon>{{ item.icon }}</v-icon> {{ item.name }}
                    </span>
                  </template>
                </v-select>
              </div>
              <div
                class="d-flex flex-column flex-md-row flex-sm-row align-start align-md-center align-sm-center justify-space-between mb-5"
              >
                <p class="subtitle-2 mb-2 mb-sm-0 mb-md-0">Status</p>
                <v-select
                  v-model="task.status"
                  :disabled="canEditTask == false"
                  :items="
                    project.statuses.filter((data) => data.activeStatus == true)
                  "
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  label="Status"
                  attach
                  style="max-width: 200px"
                  hide-details
                  :menu-props="{ closeOnContentClick: true }"
                  class="hide-drop-style"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item
                      :input-value="item.id == task.status"
                      @click="changeTaskStatus(item)"
                    >
                      <span class="text-body-2">{{ item.name }}</span>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span class="text-truncate">{{ item.name }}</span>
                  </template>
                </v-select>
              </div>
              <div
                class="d-flex flex-column flex-md-row flex-sm-row align-start align-md-center align-sm-center justify-space-between priority mb-5"
              >
                <p class="subtitle-2 mb-2 mb-sm-0 mb-md-0">Priority</p>
                <v-select
                  :disabled="canEditTask == false"
                  v-model="task.priority"
                  :items="project.priorities"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  attach
                  label="Priority"
                  style="max-width: 200px"
                  hide-details
                  :menu-props="{ closeOnContentClick: true }"
                  class="hide-drop-style"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item
                      :input-value="item.id == task.priority"
                      @click="changePriority(item)"
                    >
                      <span class="text-body-2">{{ item.name }}</span>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span class="text-truncate">{{ item.name }}</span>
                  </template>
                </v-select>
              </div>
              <div
                class="d-flex flex-column flex-md-row flex-sm-row align-start align-md-center align-sm-center justify-space-between mb-5"
              >
                <p class="subtitle-2 mb-2 mb-md-0 mb-sm-0">Start end date</p>
                <div>
                  <v-menu
                    :disabled="canEditTask == false"
                    ref="startandEndpickermenu"
                    v-model="startandEndpickermenu"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="StartEnddates"
                    transition="scale-transition"
                    persistent
                    class="mr-2 mb-2"
                    width="290px"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :disabled="canEditTask == false"
                        @click:clear="
                          date = null;
                          startandEndpickermenu = false;
                          emptydate();
                          minEndDate = '';
                        "
                        :clearable="
                          canEditTask == true && StartEnddates.length == 2
                        "
                        label="Start and end date"
                        readonly
                        v-on="on"
                        :value="TaskStartEndRange"
                        outlined
                        dense
                        class="dateinput"
                        style="width: 200px !important"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @input="assignmindate"
                      v-model="StartEnddates"
                      range
                      no-title
                      :min="minEndDate"
                    >
                      <div class="flex-grow-1"></div>
                      <v-btn text color="primary" @click="cancelDate()"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="assignRangeofDates(StartEnddates)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </div>
              </div>
              <div
                class="d-flex flex-column flex-md-row flex-sm-row align-start align-md-center align-sm-center justify-space-between mb-5"
              >
                <p class="subtitle-2 mb-2 mb-sm-0 mb-md-0">
                  Action required by
                </p>
                <v-select
                  :disabled="canEditTask == false"
                  v-model="task.actionRequiredBy"
                  label="Action required user"
                  :items="actionrequiredbyoptions"
                  outlined
                  dense
                  attach
                  item-text="firstName"
                  item-value="_id"
                  style="max-width: 200px"
                  hide-details
                  :menu-props="{ closeOnContentClick: true }"
                  class="hide-drop-style"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item
                      :input-value="item._id == task.actionRequiredBy"
                      @click="assignactionrequiredby(item)"
                    >
                      <span class="text-capitalize word-break">{{
                        item.firstName
                      }}</span>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span class="text-capitalize word-break text-truncate">{{
                      item.firstName
                    }}</span>
                  </template>
                </v-select>
              </div>
              <div
                class="estimate-priority d-flex flex-column flex-md-row flex-sm-row align-start align-md-center align-sm-center justify-space-between mb-5"
              >
                <p class="subtitle-2 pr-2">Estimate</p>
                <div>
                  <v-menu
                    v-model="editEstimateMenu"
                    :close-on-click="false"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <v-row>
                        <v-col>
                          <div class="d-flex">
                            <v-btn
                              :disabled="canEditTask == false"
                              depressed
                              fab
                              outlined
                              x-small
                              v-on="on"
                              color="primary"
                            >
                              {{ task.estimate.hours }}H</v-btn
                            >
                            <v-btn
                              :disabled="canEditTask == false"
                              class="ml-1"
                              depressed
                              fab
                              outlined
                              x-small
                              v-on="on"
                              color="primary"
                            >
                              {{ task.estimate.minutes }}M</v-btn
                            >
                          </div>
                        </v-col>
                        <v-col
                          v-if="
                            canEditTask &&
                            (task.estimate.hours != '' ||
                              task.estimate.minutes != '')
                          "
                        >
                          <v-btn icon @click="removeEstimate">
                            <v-icon>cancel</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </template>
                    <v-card>
                      <v-subheader
                        class="text-uppercase font-weight-medium black--text"
                        >Estimate</v-subheader
                      >
                      <v-container fluid class="pt-0 pb-0">
                        <v-row>
                          <v-col>
                            <v-text-field
                              :disabled="canEditTask == false"
                              label="Hr"
                              outlined
                              v-validate="'hrzeorvalidation'"
                              data-vv-name="Hr"
                              :error-messages="
                                errors.collect('estimatetime.Hr')
                              "
                              data-vv-as="Hr"
                              data-vv-scope="estimatetime"
                              style="max-width: 200px"
                              type="number"
                              min="0"
                              dense
                              @change="(val) => (estimate.hours = val)"
                              v-model="taskEstimate.hours"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              label="Min"
                              outlined
                              min="0"
                              style="width: 100px"
                              type="number"
                              dense
                              v-model="taskEstimate.minutes"
                              v-validate="'minutevalidation'"
                              data-vv-name="Min"
                              :error-messages="
                                errors.collect('estimatetime.Min')
                              "
                              data-vv-as="Min"
                              data-vv-scope="estimatetime"
                              @change="(val) => (estimate.minutes = val)"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-card-actions v-if="canEditTask">
                        <v-spacer></v-spacer>
                        <v-btn text @click="cancelEstimate()">Cancel</v-btn>
                        <v-btn color="primary" text @click="saveEstimate"
                          >Save</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </div>
              </div>

              <div
                v-if="canEditTask"
                class="estimate-priority d-flex flex-row align-center mb-5"
              >
                <p class="subtitle-2 pr-2 mb-0">Completed status</p>
                <div>
                  <div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :disabled="canEditTask == false"
                          v-bind="attrs"
                          v-on="on"
                          depressed
                          dark
                          fab
                          small
                          :color="task.Iscompleted == true ? 'green' : 'grey'"
                          solo
                          icon
                        >
                          <v-icon @click="confirmtaskcomplete()"
                            >check_circle</v-icon
                          >
                        </v-btn>
                      </template>
                      <small class="successmessage">{{
                        task.Iscompleted == true
                          ? "Mark as Incomplete"
                          : "Mark as Complete"
                      }}</small>
                    </v-tooltip>
                  </div>
                </div>
              </div>
              <div
                class="users d-inline-flex flex-column flex-md-row flex-sm-row align-start align-md-center align-sm-center mb-5"
              >
                <p class="subtitle-2 pr-2 mb-0">Users</p>
                <div class="d-inline-flex align-center flex-wrap">
                  <template v-if="task.assignedToUsers.length > 0">
                    <div
                      class="assigned-users"
                      :class="[index == 0 ? 'mx-1' : 'ma-1']"
                      v-for="(assignedUser, index) in task.assignedToUsers"
                      :key="assignedUser._id"
                    >
                      <v-tooltip
                        bottom
                        :class="{
                          'secondary white--text': !$vuetify.theme.dark,
                          'white black--text': $vuetify.theme.dark,
                        }"
                      >
                        <template v-slot:activator="{ on }">
                          <v-avatar
                            v-on="on"
                            size="36"
                            color="secondary"
                            :class="{
                              'white--text': !$vuetify.theme.dark,
                              'black--text': $vuetify.theme.dark,
                            }"
                            class="assigned-user text-uppercase"
                          >
                            <v-img
                              @click="gotosingleuser(assignedUser._id)"
                              v-if="assignedUser.Profileimage != ''"
                              :src="assignedUser.Profileimage"
                              aspect-ratio="1"
                            >
                            </v-img>
                            <span
                              @click="gotosingleuser(assignedUser._id)"
                              v-else
                            >
                              {{
                                getShortName(
                                  assignedUser.firstName,
                                  assignedUser.lastName
                                )
                              }}
                            </span>
                            <span
                              v-if="canEditTask"
                              class="d-flex remove-user"
                              @click="
                                removeAssignedUserPopup(index, assignedUser._id)
                              "
                            >
                              <v-icon
                                :class="{
                                  'white--text': !$vuetify.theme.dark,
                                  'black--text': $vuetify.theme.dark,
                                }"
                                x-small
                                >close</v-icon
                              >
                            </span>
                          </v-avatar>
                        </template>
                        <p class="first-character mb-0">
                          {{ firstletterupercase(assignedUser.firstName) }}
                          {{ firstletterupercase(assignedUser.lastName) }}
                        </p>
                      </v-tooltip>
                    </div>
                  </template>
                  <template v-else>
                    <h6 class="text-body-2 grey--text text--darken-1">
                      No users assigned
                    </h6>
                  </template>
                  <v-btn
                    v-if="canEditTask"
                    :class="task.assignedToUsers.length == 0 ? 'ml-2' : ''"
                    @click="openassignuserpopup"
                    x-small
                    color="primary"
                    outlined
                    fab
                  >
                    <v-icon>add</v-icon>
                  </v-btn>
                </div>
              </div>
              <br />
              <br />
              <div class="icon-section d-flex flex-column align-start">
                <v-row>
                  <v-col cols="12" class="d-flex pa-0">
                    <div
                      v-if="timerupdatestatus"
                      class="users align-center pb-2"
                    >
                      <h6 class="text-subtitle-2 first-character">
                        Update your spent time for this task:
                      </h6>
                      <v-btn color="primary" @click="openaddReportTimepopup()"
                        >add</v-btn
                      >
                    </div>
                    <div v-else>
                      <div
                        v-if="
                          !task.Iscompleted ||
                          (recordwithemptyendtime && task.Iscompleted)
                        "
                        class="d-flex"
                      >
                        <div
                          v-if="recordwithemptyendtime"
                          class="d-flex align-center"
                        >
                          End Time :
                          <div class="pl-2" v-if="startedtimerloaderstatus">
                            <v-progress-circular
                              color="primary"
                              indeterminate
                              size="25"
                            ></v-progress-circular>
                          </div>
                          <v-tooltip bottom v-else>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                :disabled="stopstatus"
                                v-bind="attrs"
                                v-on="on"
                                @click="StopTimeCounter(recordwithemptyendtime)"
                                icon
                              >
                                <v-icon>timer_off</v-icon>
                              </v-btn>
                            </template>
                            <span>End Time</span>
                          </v-tooltip>
                        </div>
                        <div v-else>
                          Start Time
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                :disabled="startstatus"
                                v-bind="attrs"
                                v-on="on"
                                @click="startTimerCounter()"
                                icon
                              >
                                <v-icon>access_time</v-icon>
                              </v-btn>
                            </template>
                            <span>Start Time</span>
                          </v-tooltip>
                        </div>
                        <h5
                          class="timer-section text-h6 text-capitalize ma-0 pl-5"
                          v-if="timershowstatus"
                        >
                          <span v-if="years != 0" class="pr-2">
                            {{ years }}
                            <small>(years)</small>
                          </span>
                          <span v-if="days != 0" class="pr-2">
                            {{ days }}
                            <small>(days)</small>
                          </span>
                          <span v-if="hours != 0" class="pr-2">
                            {{ hours }}
                            <small>hrs</small>
                          </span>
                          <span v-if="minutes != 0" class="pr-2">
                            {{ minutes }}
                            <small>mins</small>
                          </span>
                          <span v-if="seconds != 0" class="pr-2">
                            {{ seconds }}
                            <small>sec</small>
                          </span>
                        </h5>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <div
                  style="width: 100%"
                  v-if="canDeleteTask && userRunningtimer.length > 0"
                >
                  <div class="mr-0 mr-md-3 mr-sm-3">
                    <v-expansion-panels class="running-user">
                      <v-expansion-panel>
                        <v-expansion-panel-header class="px-4">
                          Running time for users
                        </v-expansion-panel-header>

                        <v-expansion-panel-content
                          class="pa-2"
                          v-if="userRunningtimer.length > 0"
                        >
                          <v-row
                            v-for="(usertimer, index) in userRunningtimer"
                            :key="index"
                            style="width: 100%"
                            class="pa-2 elevation-0 my-1 running-expansion"
                          >
                            <v-col cols="12" class="pa-0">
                              <div
                                class="text-body-1 text-capitalize font-weight-medium"
                              >
                                {{
                                  usertimer.userInfo.firstName +
                                  " " +
                                  usertimer.userInfo.lastName
                                }}
                              </div>
                              <div>
                                <span
                                  v-if="usertimer.runningduration.years != 0"
                                  class="pr-2"
                                >
                                  {{ usertimer.runningduration.years }}
                                  <small>(years)</small>
                                </span>
                                <span
                                  v-if="usertimer.runningduration.days != 0"
                                  class="pr-2"
                                >
                                  {{ usertimer.runningduration.days }}
                                  <small>(days)</small>
                                </span>
                                <span
                                  v-if="usertimer.runningduration.hours != 0"
                                  class="pr-2"
                                >
                                  {{ usertimer.runningduration.hours }}
                                  <small>hrs</small>
                                </span>
                                <span
                                  v-if="usertimer.runningduration.minutes != 0"
                                  class="pr-2"
                                >
                                  {{ usertimer.runningduration.minutes }}
                                  <small>mins</small>
                                </span>
                                <span
                                  v-if="usertimer.runningduration.seconds != 0"
                                  class="pr-2"
                                >
                                  {{ usertimer.runningduration.seconds }}
                                  <small>sec</small>
                                </span>
                              </div>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                </div>

                <div v-if="timespendedusers.length > 0" style="width: 100%">
                  <h5
                    class="text-subtitle-1 first-character font-weight-medium mb-2"
                  >
                    Time spent for this task
                  </h5>
                  <div class="mr-0 mr-md-3 mr-sm-3">
                    <v-expansion-panels v-model="infopanel" multiple>
                      <v-expansion-panel
                        v-for="(user, index) in timespendedusers"
                        :key="index"
                      >
                        <v-expansion-panel-header
                          v-if="user.spendtime"
                          class="pa-1"
                        >
                          <v-row class="align-center">
                            <v-col cols="1">
                              <userinfo
                                :user="user"
                                :clickable="true"
                                :userId="user.id"
                              ></userinfo>
                            </v-col>
                            <v-col cols="5" class="d-flex align-center">
                              <p class="first-character mb-0 flex-grow-1 pl-2">
                                {{ firstletterupercase(user.firstName) }}
                                {{ firstletterupercase(user.lastName) }}
                              </p>
                            </v-col>
                            <v-col
                              cols="6"
                              class="d-flex align-center pa-0 justify-end"
                            >
                              <div
                                class="d-flex justify-end text-caption align-center"
                              >
                                <h6
                                  class="ma-0 font-weight-medium text-caption"
                                >
                                  Total :&nbsp;
                                </h6>
                                <span v-if="user.spendtime.textTime">{{
                                  user.spendtime.textTime
                                }}</span>
                                <div
                                  class="d-flex align-center pl-1 pr-1"
                                  v-if="
                                    user.spendtime.textTime &&
                                    user.spendtime.textOTtime
                                  "
                                >
                                  &nbsp;+&nbsp;
                                </div>
                                <span v-if="user.spendtime.textOTtime">
                                  {{ user.spendtime.textOTtime }}</span
                                >
                              </div>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content v-if="user.spendtime">
                          <div class="timeline-find-child">
                            <div
                              v-for="(hisotyobject, hindex) in user.spendtime
                                .history"
                              :key="hindex"
                            >
                              <template>
                                <v-timeline
                                  align-top
                                  dense
                                  class="timer-task-timeline"
                                  truncate-line="end"
                                >
                                  <v-timeline-item
                                    color="primary"
                                    small
                                    align-dot="start"
                                  >
                                    <v-row class="pa-1 align-center">
                                      <v-col
                                        cols="6"
                                        class="text-caption pa-0"
                                        :class="{
                                          'grey-tag': !$vuetify.theme.dark,
                                          'black--text': $vuetify.theme.dark,
                                        }"
                                        >{{ hisotyobject.formateddate }}</v-col
                                      >
                                      <v-col
                                        cols="6"
                                        class="text-caption pa-0 d-flex justify-end align-center"
                                        :class="{
                                          'grey-tag': !$vuetify.theme.dark,
                                          'black--text': $vuetify.theme.dark,
                                        }"
                                      >
                                        <div
                                          v-if="hisotyobject.totalhr"
                                          class="d-flex align-center"
                                        >
                                          {{ hisotyobject.totalhr }}
                                        </div>
                                      </v-col>
                                    </v-row>
                                  </v-timeline-item>
                                </v-timeline>
                              </template>
                            </div>
                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                  <v-row>
                    <v-col cols="7"><b>Total</b></v-col>
                    <v-col cols="5" class="d-flex justify-end">
                      <b> {{ getTotaltimer }}</b>
                    </v-col>
                  </v-row>
                </div>
                <v-row style="width: 100%">
                  <v-col cols="12" class="d-flex flex-column flex-wrap pa-0">
                    <h5
                      v-if="canEditTask"
                      class="text-subtitle-1 text-capitalize font-weight-medium mb-0"
                    >
                      Add labels
                    </h5>
                    <span v-if="canEditTask">
                      <v-menu
                        v-model="projectAllLables"
                        :close-on-content-click="false"
                        offset-y
                        top
                        :elevation="1"
                        class="add-label-menu"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            text
                            @click="dispatchprojectlable"
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            color="primary"
                            class="mr-4"
                          >
                            <v-icon small color="primary" class="pr-2"
                              >label</v-icon
                            >Add Label
                          </v-btn>
                        </template>
                        <v-card style="width: 290px">
                          <p class="font-weight-medium black--text pa-2 ma-0">
                            Labels
                          </p>
                          <div>
                            <v-divider></v-divider>
                            <v-text-field
                              solo
                              dense
                              v-model="searchlable"
                              label="Search lables"
                              append-icon="search"
                              class="ma-2"
                              style="width: 90%"
                            ></v-text-field>
                            <v-row class="ma-2">
                              <div
                                v-for="label in filteredlables"
                                :key="label.id"
                              >
                                <v-chip
                                  class="ma-1 word-break text-wrap"
                                  @click="addTaskLable(label.id)"
                                  small
                                  color="primary"
                                  style="height: auto"
                                >
                                  {{ label.name }}</v-chip
                                >
                              </div>
                            </v-row>
                            <v-list></v-list>
                            <v-menu
                              v-model="AddprojectLablemenu"
                              offset-y
                              top
                              attach
                              :close-on-content-click="false"
                              class="inside-add-label"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  @click="
                                    lable = '';
                                    $validator.reset();
                                  "
                                  v-bind="attrs"
                                  v-on="on"
                                  text
                                  class="ml-2"
                                >
                                  <v-icon small class="pr-2">add</v-icon>Add
                                  Label
                                </v-btn>
                              </template>
                              <v-card>
                                <div class="text-caption black--text pa-2">
                                  Add label
                                </div>
                                <div>
                                  <v-row>
                                    <v-col cols="10">
                                      <v-text-field
                                        v-model="lable"
                                        label="labels"
                                        v-validate="'required|max:50'"
                                        data-vv-name="lable"
                                        :error-messages="
                                          errors.collect('add-new-lable.lable')
                                        "
                                        data-vv-as="labels"
                                        data-vv-scope="add-new-lable"
                                        dense
                                        type="text"
                                        class="pl-2"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="2" class="d-flex pa-0 pb-2">
                                      <v-tooltip bottom>
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-icon
                                            color="primary"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="addNewLabels"
                                            icon
                                          >
                                            add</v-icon
                                          >
                                        </template>
                                        <span>Add Label</span>
                                      </v-tooltip>
                                    </v-col>
                                  </v-row>
                                </div>
                              </v-card>
                            </v-menu>
                          </div>
                        </v-card>
                      </v-menu>
                    </span>
                    <div class="d-flex flex-wrap pt-4">
                      <div
                        v-for="tasklable in tasklables"
                        :key="tasklable.id"
                        class="pa-1"
                      >
                        <v-chip
                          class="ma-1 word-break text-wrap"
                          close
                          small
                          color="primary"
                          outlined
                          style="height: auto"
                          @click:close="removelables(tasklable.id)"
                          >{{ tasklable.name }}</v-chip
                        >
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div v-if="task.customFields && task.customFields.length > 0">
                <h5 class="text-subtitle-1 text-capitalize font-weight-medium">
                  custom fields
                </h5>
                <div v-for="taskfield in task.customFields" :key="taskfield.id">
                  <div
                    v-for="projectcustomfield in project.customFields"
                    :key="projectcustomfield.id"
                  >
                    <div
                      v-if="projectcustomfield.id == taskfield.projectcustomId"
                    >
                      <div v-if="taskfield.inputType == 'date'">
                        <div v-if="taskfield.requiredStatus == true">
                          <div
                            class="d-flex flex-column align-start justify-space-between mb-5"
                          >
                            <p class="subtitle-2 mb-0">
                              {{ projectcustomfield.name }}
                            </p>
                            <v-menu
                              :disabled="canEditTask == false"
                              v-model="taskfield.datepopupstatus"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="taskfield.value"
                                  :label="projectcustomfield.name"
                                  append-icon="event"
                                  disabled
                                  outlined
                                  dense
                                  :nudge-right="40"
                                  v-bind="attrs"
                                  v-on="on"
                                  v-validate="'required'"
                                  :data-vv-name="taskfield.name"
                                  :error-messages="
                                    errors.collect(
                                      'create-task.' + taskfield.name
                                    )
                                  "
                                  :data-vv-as="taskfield.name"
                                  data-vv-scope="create-task"
                                  style="width: 100%"
                                  class="dateinput"
                                >
                                </v-text-field>
                              </template>
                              <v-date-picker
                                v-model="taskfield.value"
                                @input="
                                  editCustomDate(taskfield);
                                  taskfield.datepopupstatus = false;
                                "
                                style="width: 100%"
                              ></v-date-picker>
                            </v-menu>
                          </div>
                        </div>
                        <div v-else>
                          <div
                            class="d-flex flex-column align-start justify-space-between mb-5"
                          >
                            <p class="subtitle-2 mb-0">
                              {{ projectcustomfield.name }}
                            </p>
                            <v-menu
                              :disabled="canEditTask == false"
                              v-model="taskfield.datepopupstatus"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  :disabled="canEditTask == false"
                                  v-model="taskfield.value"
                                  :label="projectcustomfield.name"
                                  append-icon="event"
                                  readonly
                                  dense
                                  :nudge-right="40"
                                  outlined
                                  v-bind="attrs"
                                  v-on="on"
                                  style="width: 100%"
                                  class="dateinput"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="taskfield.value"
                                @input="
                                  editCustomDate(taskfield);
                                  taskfield.datepopupstatus = false;
                                "
                                style="width: 100%"
                              ></v-date-picker>
                            </v-menu>
                          </div>
                        </div>
                      </div>
                      <div v-if="taskfield.inputType == 'dropdown'">
                        <div
                          class="d-flex flex-column align-start justify-space-between mb-5"
                        >
                          <p class="subtitle-2 mb-0">
                            {{ projectcustomfield.name }}
                          </p>
                          <div style="width: 100%">
                            <v-select
                              :disabled="canEditTask == false"
                              v-model="taskfield.attributeValueId"
                              :items="projectcustomfield.dropdownAttributes"
                              item-text="name"
                              item-value="id"
                              outlined
                              attach
                              dense
                              :nudge-right="40"
                              :label="projectcustomfield.name"
                              :menu-props="{
                                top: true,
                                offsetY: true,
                                closeOnContentClick: true,
                              }"
                              v-validate="'required'"
                              :data-vv-as="projectcustomfield.name"
                              :error-messages="
                                errors.collect(
                                  'create-task.' + projectcustomfield.name
                                )
                              "
                              :data-vv-name="projectcustomfield.name"
                              data-vv-scope="create-task"
                            >
                              <template v-slot:item="{ item }">
                                <v-list-item
                                  :input-value="
                                    item.id == taskfield.attributeValueId
                                  "
                                  @click="editCustomDropdown(item, taskfield)"
                                >
                                  <span class="text-body-2">{{
                                    item.name
                                  }}</span>
                                </v-list-item>
                              </template>
                              <template v-slot:selection="{ item }">{{
                                item.name
                              }}</template>
                            </v-select>
                          </div>
                        </div>
                      </div>
                      <div v-if="taskfield.inputType == 'text'">
                        <div
                          v-if="taskfield.requiredStatus == true"
                          class="d-flex flex-column align-start justify-space-between mb-5"
                        >
                          <p class="subtitle-2 mb-0">
                            {{ projectcustomfield.name }}
                          </p>
                          <v-textarea
                            :label="projectcustomfield.name"
                            outlined
                            auto-grow
                            rows="1"
                            row-height="15"
                            :nudge-right="40"
                            :disabled="canEditTask == false"
                            dense
                            v-model="taskfield.value"
                            :maxlength="255"
                            @input="editTaskcustomtext(taskfield)"
                            v-validate="'required'"
                            :data-vv-as="projectcustomfield.name"
                            :error-messages="
                              errors.collect(
                                'create-task.' + projectcustomfield.name
                              )
                            "
                            :data-vv-name="projectcustomfield.name"
                            data-vv-scope="create-task"
                            style="width: 100%"
                          >
                          </v-textarea>
                        </div>
                        <div
                          v-else
                          class="d-flex flex-column align-start justify-space-between mb-5"
                        >
                          <p class="subtitle-2 mb-0">
                            {{ projectcustomfield.name }}
                          </p>
                          <v-textarea
                            @input="editTaskcustomtext(taskfield)"
                            :label="taskfield.name"
                            outlined
                            :nudge-right="40"
                            :maxlength="255"
                            dense
                            v-model="taskfield.value"
                            :disabled="canEditTask == false"
                            style="width: 100%"
                          ></v-textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <!-- TODO: REMOVE DIALOG -->
        <v-dialog v-model="reporttimeopen" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <div class="horizontal-dividing-header mb-0">
                <h6 class="header text-uppercase mt-0">Add Report Time</h6>
              </div>
            </v-card-title>
            <v-container fluid class="pt-0 pb-0">
              <v-row>
                <v-col>
                  <div>
                    <v-menu
                      ref="reportmenu"
                      v-model="reportmenu"
                      :close-on-content-click="false"
                      :return-value.sync="reportdate"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          append-icon="event"
                          dense
                          v-model="reportdate"
                          label="Picker in menu"
                          prepend-icon=""
                          readonly
                          :error-messages="errors.collect('add-reportime.date')"
                          data-vv-as="date"
                          data-vv-scope="add-reportime"
                          data-vv-name="date"
                          v-validate="'required'"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :max="minEndDate"
                        v-model="reportdate"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="canceladdreport">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.reportmenu.save(reportdate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Worked hours"
                    type="number"
                    min="0"
                    dense
                    :error-messages="errors.collect('add-reportime.hours')"
                    data-vv-as="hours"
                    data-vv-scope="add-reportime"
                    data-vv-name="hours"
                    v-validate="
                      'required|numeric|max_value:23|checkhoursandminutes:minutes'
                    "
                    v-model="reporthour"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Worked minutes"
                    min="0"
                    dense
                    :error-messages="errors.collect('add-reportime.minutes')"
                    data-vv-as="minutes"
                    data-vv-scope="add-reportime"
                    data-vv-name="minutes"
                    v-validate="'required|numeric|max_value:60'"
                    v-model="reportminutes"
                    ref="minutes"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn
                color="red"
                text
                :loading="addreportbtnLoading"
                :disabled="addreportimestatus"
                @click="makeaddTimeinputempty"
                >Close</v-btn
              >
              <v-btn
                :class="{
                  'gradient-button white--text': !$vuetify.theme.dark,
                  'white black--text': $vuetify.theme.dark,
                }"
                tile
                contained
                text
                :loading="addreportbtnLoading"
                :disabled="addreportimestatus"
                @click="addreportTime()"
              >
                Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="assignUsersDialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <div class="horizontal-dividing-header mb-0">
                <h5 class="header text-uppercase mt-0">Assign Users</h5>
              </div>
            </v-card-title>
            <v-card-text>
              <v-list>
                <template v-for="user in projectUsers">
                  <v-list-item
                    class="ma-2 pl-0 pr-0 assigned-user-list"
                    :key="user.user"
                  >
                    <v-list-item-content class="pa-0 text-body-1">
                      <v-checkbox
                        v-model="selectedUsers"
                        :label="
                          firstletterupercase(user.firstName) +
                          ' ' +
                          firstletterupercase(user.lastName)
                        "
                        :value="user.user"
                      ></v-checkbox>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-card-text>
            <v-card-actions class="add-task-sticky sticky-bar elevation-4">
              <div class="flex-grow-1"></div>
              <v-btn
                color="red"
                text
                :loading="btnLoading"
                :disabled="btnLoading"
                @click="closeAssignUsersDialog"
                >Close</v-btn
              >
              <v-btn
                :class="{
                  'gradient-button white--text': !$vuetify.theme.dark,
                  'white black--text': $vuetify.theme.dark,
                }"
                :loading="btnLoading"
                :disabled="btnLoading"
                tile
                contained
                text
                @click="assignUsers"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <confirmation
          :propdialog="confirmdiffertimezone"
          dialogTitle="Please note that there is a change in timezone between start and stop date, are you sure you want to continue?"
          @onConfirmed="
            confirmstatus = true;
            StopTimeCounter(StopTimeCounterinfo);
          "
          @onCancel="
            confirmstatus = false;
            confirmdiffertimezone = false;
          "
          key="complete-timezoneconfirmation"
        >
        </confirmation>
        <confirmation
          :propdialog="opencompleteconfirmpopup"
          dialogTitle="Are you sure you want to mark this task complete as you are not the action required user?"
          @onConfirmed="updatetaskascompleted"
          @onCancel="opencompleteconfirmpopup = false"
          key="complete-confirmation"
        >
        </confirmation>
        <confirmation
          :propdialog="dialog"
          :dialogTitle="dialogTitle"
          @onConfirmed="removeAssignedUser"
          @onCancel="closeDeleteUserDialog"
          key="delete-user"
        ></confirmation>
        <confirmation
          :propdialog="deleteFileDialog"
          dialogTitle="Are you sure you want to delete this attachment"
          @onConfirmed="removeFile"
          @onCancel="closeDeleteFileDialog"
          key="delete-comment-attachment"
        ></confirmation>
        <confirmation
          :propdialog="deleteCommentDialog"
          dialogTitle="Are you sure you want to delete this comment"
          @onConfirmed="deleteComment"
          @onCancel="closeDeleteCommentDialog"
          key="delete-comment"
        ></confirmation>
        <confirmation
          :propdialog="deleteTaskFileDialog"
          dialogTitle="Are you sure you want to delete this attachment"
          @onConfirmed="removeTaskFile"
          @onCancel="closeDeleteTaskFileDialog"
          key="delete-task-attachment"
        >
        </confirmation>
      </v-card>
      <v-snackbar
        v-model="socketdisconnect"
        absolute
        centered
        :right="true"
        :top="true"
        :timeout="-1"
        color="red accent-2"
      >
        <strong>Oops, something went wrong. Refresh the page or click</strong>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="loadpage" color="green accent-2">
            reload
          </v-btn>
        </template>
      </v-snackbar>
      <div v-show="renderaddtask">
        <!-- :key="'single-task'+currtime" -->
        <addTask
          :key="'single-task'"
          :global="false"
          :buttontext="'+Add Subtask'"
          :subtask="true"
          :parentask="task"
          :showbuttonstatus="showbuttonstatus"
          :ParamWorkspace="project.workspace"
          :ParamProject="task.project"
          @closestatus="closepopup"
        >
        </addTask>
      </div>
    </div>
    <v-dialog v-model="deleteConformDialog" max-width="500">
      <v-card class="pa-5">
        <h6 class="text-h6 font-weight-medium">Delete Task confirmation</h6>
        <h5 class="text-body-1">Are you sure? you want to delete this Task</h5>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="red"
            text
            :loading="disabledelete"
            :disabled="disabledelete"
            @click="deleteConformDialog = false"
            >Close</v-btn
          >
          <v-btn
            class="gradient-button"
            :class="{
              'white--text': !$vuetify.theme.dark,
              'black--text': $vuetify.theme.dark,
            }"
            tile
            contained
            text
            :loading="disabledelete"
            :disabled="disabledelete"
            @click="deleteTask()"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="showSnackBar" :bottom="true" :color="messagecolor">{{
      snackMessage
    }}</v-snackbar>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import timezonemoment from "moment-timezone";
import { bus } from "./../../../../../main";
import VeeValidate from "vee-validate";
import confirmation from "src/components/ConfirmationDialog";
import draggable from "vuedraggable";
import comment from "./_comment";
import TaskAttachements from "./_task_attachements";
import { debounce } from "lodash";
import mixin from "src/mixin";
import DatetimePicker from "vuetify-datetime-picker";
import addTask from "./../../../../pages/dashboard/addTask.vue";
import subtasks from "./../../../dashboard/todayMytask.vue";
import BreadCrumbs from "../SingleProject.vue";
import userinfo from "./../../../dashboard/_userAvatar.vue";
import $ from "jquery";
import { Validator } from "vee-validate";
const dictionary = {
  en: {
    messages: {
      max_value: (name) => {
        var maxvalue = name == "minutes" ? 60 : 23;
        return (
          "The " + name + " field should be less than or equal to " + maxvalue
        );
      },
    },
  },
};
Validator.localize(dictionary);
const validator = new Validator({ minutes: "max_value", hours: "max_value" });
validator.localize("en");
Vue.use(DatetimePicker);
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  BulletList,
  Link,
  OrderedList,
  ListItem,
  Blockquote,
  HardBreak,
  HorizontalRule,
} from "tiptap-vuetify";
Vue.use(VeeValidate);
export default {
  mixins: [mixin],
  data: () => ({
    disablechangeproject: false,
    disabledelete: false,
    confirmdiffertimezone: false,
    StopTimeCounterinfo: {},
    confirmstatus: false,
    showcompleted: false,
    opencompleteconfirmpopup: false,
    showbuttonstatus: false,
    renderaddtask: false,
    addreportimestatus: false,
    timezonemoment: timezonemoment,
    changeprojectpopup: false,
    searchproject: "",
    selectedproject: "",
    allprojects: [],
    taskfiles: [],
    addreportbtnLoading: false,
    reporttimeopen: false,
    reportdate: "",
    reporthour: 0,
    reportminutes: 0,
    startstatus: false,
    reportmenu: false,
    stopstatus: false,
    infopanel: [],
    deleteConformDialog: false,
    selectedparent: "",
    taskEstimate: {
      hours: "",
      minutes: "",
    },
    spendedhrs: [],
    userRunningtimer: [],
    completetimestamp: 0,
    TaskStartEndRange: "",
    searchParenttask: "",
    subtaskpopup: false,
    pinnedTaskbtn: false,
    switchprojectconfirm: false,
    subtasks: [],
    socketdisconnect: false,
    moment: moment,
    showSnackBar: false,
    snackMessage: "",
    messagecolor: "red",
    showpasswordtext: false,
    timererrormessage: [],
    timerErrorpopup: false,
    tasktimerEndDatemenu: "",
    tasktimerStartDatemenu: "",
    StartEnddates: [],
    startandEndpickermenu: false,
    searchlable: "",
    AddprojectLablemenu: false,
    counting: false,
    showTask: false,
    showTemplate: false,
    actionrequiredby: "",
    hide_othertask_protion: true,
    todo_name: "",
    show: false,
    lable: "",
    projectlable: [],
    tiptapPostCommentcontent: "",
    tasklables: [],
    deleteFileId: "",
    extensions: [
      Blockquote,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Link,
        {
          options: {
            target: "_blank",
          },
        },
      ],
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      HardBreak,
    ],
    dialog: false,
    dialogTitle: "",
    removeAssignedUserIndex: "",
    removeAssignedUserId: "",
    loading: true,
    btnLoading: false,
    project: "",
    task: {},
    otherTasks: "",
    estimate: {
      hours: "",
      minutes: "",
    },
    selectedUsers: [],
    showTitleInput: false,
    titleLoading: false,
    showDescriptionInput: false,
    descriptionLoading: false,
    editEstimateMenu: false,
    assignUsersDialog: false,
    comments: [],
    commentFilled: true,
    commentFiles: [],
    previewFiles: [],
    commentLoader: false,
    deleteFileDialog: false,
    deleteCommentDialog: false,
    deleteCommentIndex: "",
    deleteCommentId: "",
    deleteFileIndex: "",
    deleteTaskFileDialog: false,
    deleteTaskFileIndex: "",
    deleteTaskFileId: "",
    caneditdelete: false,
    current_list_todo: false,
    current_list_todo_id: "",
    elapsedTime: 0,
    timer: undefined,
    userChanged: "",
    timershowstatus: false,
    recordwithemptyendtime: "",
    years: "",
    days: "",
    hours: "",
    minutes: "",
    seconds: "",
    miliseconds: "",
    interval: null,
    timeroftask: "",
    spendedhours: [],
    dates: [],
    datepickerUpdateTasktimeMenu: false,
    enddatemenu: false,
    endDate: "",
    minEndDate: "",
    projectAllLables: false,
    previousroute: "",
    timespendedusers: [],
    parentasksinfo: {
      workspace: "",
      project: "",
      alltask: "",
    },
    projectTaglabels: [],
    windowHeight: window.innerHeight - 87,
    windowMobileHeight: window.innerHeight,
    windowWidth: window.innerWidth,
    attendanceuserinfo: [],
    duration: {
      minutes: "",
      hours: "",
      day: "",
    },
    showcompletedtodoUsers: [],
    timerclickedstatus: false,
  }),
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      var getpathindexes = to.path.split("/");
      vm.showTemplate = from.name == null ? true : false;
      vm.showTask = from.name != null ? true : false;
      vm.previousroute = from;
      vm.getTask(getpathindexes[2], to.params.taskId).then(() => {
        vm.createBreadCrumbs("openmodel");
        vm.aftergetTask();
        vm.getComments(vm.task._id);
        vm.cancelEstimate();
        if (vm.authuser.id == vm.task.actionRequiredBy) {
          bus.$emit("updateactionRequiredReadStatus", vm.task);
        }
      });
    });
  },
  computed: {
    // showcompletedtodoUsers: {
    //   get() {
    //     return (
    //     this.task.showcompletedtodoUsers &&
    //     this.task.showcompletedtodoUsers.includes(this.authuser.id));
    //   },
    //   set(newValue) {
    //     newValue;
    //   },
    // },
    startedtimerloaderstatus() {
      var returnstatus = false;
      if (this.recordwithemptyendtime) {
        if (
          (this.years == 0 || this.years == "") &&
          (this.days == 0 || this.days == "") &&
          (this.hours == 0 || this.hours == "") &&
          (this.minutes == 0 || this.minutes == "") &&
          (this.seconds == 0 || this.seconds == "")
        ) {
          returnstatus = true;
        }
      }
      return returnstatus;
    },
    sortedsubtask() {
      var sortedtodo = this.subtasks.slice().sort(function (a, b) {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });
      return sortedtodo;
    },
    allusers() {
      return this.$store.state.mywork.myusers;
    },
    searchedAllproject() {
      var self = this;
      if (self.searchproject) {
        return self.allprojects.filter((subtask) =>
          subtask.name.toLowerCase().includes(self.searchproject.toLowerCase())
        );
      } else {
        return self.allprojects;
      }
    },
    timerupdatestatus() {
      var returnstatus = false;
      if (this.task.Iscompleted) {
        if (this.recordwithemptyendtime) {
          returnstatus = false;
        } else {
          returnstatus = true;
        }
      }
      return returnstatus;
    },
    commentsubmitstatus() {
      this.tiptapPostCommentcontent.replace("<p>", "");
      var returnstatus = true;
      if (this.tiptapPostCommentcontent != "") {
        if (
          this.tiptapPostCommentcontent != "<p></p>" &&
          this.tiptapPostCommentcontent != "<ul><li><p></p></li></ul>" &&
          this.tiptapPostCommentcontent != "<ol><li><p></p></li></ol>"
        ) {
          returnstatus = false;
        }
      }
      return returnstatus;
    },
    getTotaltimer() {
      let interval = 0;
      this.timespendedusers.forEach(function (data) {
        if (data.spendtime) {
          interval = interval + data.spendtime.timestampvalue;
        }
      });
      var minutes = Math.floor((interval % (1000 * 60 * 60)) / (1000 * 60));
      var hours = Math.floor(
        (interval % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var day = Math.floor(interval / (1000 * 60 * 60 * 24));
      var duration = {
        minutes: !isNaN(minutes) && minutes > 0 ? minutes + "m " : "0m ",
        hours:
          !isNaN(hours + day * 24) && hours + day * 24 > 0
            ? hours + day * 24 + "h "
            : "0h ",
      };
      let totalTime = duration.hours + "" + duration.minutes;
      return totalTime;
    },
    actionrequiredbyoptions() {
      var data = [{ firstName: "remove user", _id: "remove_user" }];
      if (this.task.actionRequiredBy) {
        this.task.assignedToUsers.forEach((computeduser) => {
          data.push(computeduser);
        });
        return data;
      } else {
        return this.task.assignedToUsers;
      }
    },
    authuser() {
      return JSON.parse(this.$store.state.account.user);
    },
    isstartdateempty() {
      return this.tasktimerStartDatemenu == "" ? false : true;
    },
    taskTimeRangeText() {
      return this.dates.join(" ~ ");
    },
    filteredlables() {
      var self = this;
      if (self.searchlable) {
        return self.projectTaglabels.filter((lable) => {
          return lable.name
            .toLowerCase()
            .includes(self.searchlable.toLowerCase());
        });
      } else {
        return self.projectTaglabels;
      }
    },
    searchedParentTask() {
      var self = this;
      if (self.searchParenttask) {
        return self.parentasksinfo.alltask.filter((subtask) =>
          subtask.name
            .toLowerCase()
            .includes(self.searchParenttask.toLowerCase())
        );
      } else {
        return self.parentasksinfo.alltask;
      }
    },
    computedDueDate() {
      return this.task.endDate;
    },
    priorityItems() {
      return this.$store.state.project.priorityItems.filter(
        (data) => data.activeStatus == true
      );
    },
    statuses() {
      return this.$store.state.project.statuses.filter(
        (data) => data.activeStatus == true
      );
    },
    projectTags() {
      return this.$store.state.project.projectTags;
    },
    projectUsers() {
      return this.$store.state.project.projectUsers;
    },
    canEditTask() {
      var allmanagers = [];
      this.$store.state.project.projectUsers.forEach((user) => {
        if (user.isManager == true || user.isAdmin == true) {
          allmanagers.push(user.user);
        }
      });
      this.task.assignedToUsers.forEach((assignuser) => {
        allmanagers.push(assignuser._id);
      });
      if (this.task.actionRequiredBy) {
        allmanagers.push(this.task.actionRequiredBy);
      }
      if (
        JSON.parse(this.$store.state.account.user).allcompanies.find(
          (data) => data.id == this.$route.query.companyId
        ).isAdmin
      ) {
        return true;
      } else {
        return allmanagers.includes(
          JSON.parse(this.$store.state.account.user).id
        );
      }
    },
    canDeleteTask() {
      var allmanagers = this.$store.state.project.projectUsers
        .filter((data) => data.isManager == true)
        .map((data) => data.id);
      if (
        this.authuser.allcompanies.find(
          (data) => data.id == this.$route.query.companyId
        ).isAdmin
      ) {
        return true;
      } else {
        return allmanagers.includes(this.authuser.id) ? true : false;
      }
    },
    sortedArray: function () {
      let todo = this.task.todos;
      todo.forEach((singletodo) => {
        singletodo.authstatus = this.todoAuthstatus(singletodo);
      });
      return todo.sort((a, b) => {
        var fa = a.order;
        var fb = b.order;
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
    },
  },
  watch: {
    $route(to) {
      // eslint-disable-next-line no-unused-vars
      var self = this;
      this.interval = null;
      this.recordwithemptyendtime = "";
      this.oldTitle = "";
      this.oldDescription = "";
      clearInterval(this.interval);
      this.makemeptydates();
      var getpathindexes = to.path.split("/");
      self.getTask(getpathindexes[2], to.params.taskId).then(() => {
        self.gettimerinformation(to.params.taskId);
        self.aftergetTask();
        self.getComments(to.params.taskId);
        self.cancelEstimate();
        self.getalltimesofUsers(to.params.taskId);
        self.TaskStartEndRange = "";
        self.StartEnddates = [self.task.startDate, self.task.endDate];
      });
    },
    StartEnddates() {
      if (this.StartEnddates.length == 2) {
        this.TaskStartEndRange = this.StartEnddates.join(" ~ ");
      } else {
        this.TaskStartEndRange = "";
      }
    },
  },
  created() {
    var self = this;
    this.$io.socket.on("disconnect", () => {
      self.socketdisconnect = true;
    });
    this.interval = null;
    this.recordwithemptyendtime = "";
    this.oldTitle = "";
    this.oldDescription = "";
    // LITENING COMMENTS
    this.$io.socket.on("listening-comments-section", function (data) {
      if (data.comment && data.comment.task) {
        if (data.comment.task == self.$route.params.taskId) {
          self.comments.unshift(data.comment);
        }
      }
      bus.$emit("project-add-recentactivity", data.comment);
    });
    this.$io.socket.on("stoptimer", async function (data) {
      if (
        data.authuser != self.authuser.id &&
        data.removeuser == self.authuser.id
      ) {
        if (
          data.tasktimer.taskId == self.task.id &&
          data.tasktimer.userId == self.authuser.id
        ) {
          var index = self.task.assignedToUsers.findIndex(
            (elem) => elem._id == data.removeuser
          );
          if (index != -1) {
            self.task.assignedToUsers.splice(index, 1);
          }
          await self.resettimer(data);
        }
      }
    });
    this.$io.socket.on("timer-stop-section", function (data) {
      if (
        data.returndata.companyId == self.$route.query.companyId &&
        data.returndata.task.id == self.task.id &&
        data.returndata.authuser == self.authuser.id
      ) {
        self.stopAlltabTimer(data.returndata);
      }
      bus.$emit(
        "task-spend-time",
        self.getTotaltimer,
        self.$route.params.taskId
      );
    });
    self.$io.socket.on("timer-start-section", async function (data) {
      if (
        data.returndata.companyId == self.$route.query.companyId &&
        data.returndata.recordwithemptyendtime.taskId == self.task.id &&
        data.returndata.recordwithemptyendtime.userId == self.authuser.id
      ) {
        self.startstatus = true;
        self.recordwithemptyendtime = data.returndata.recordwithemptyendtime;
        self.timershowstatus = data.recordwithemptyendtime != "" ? true : false;
        if (self.timerclickedstatus == false) {
          await self.starttimer(data.returndata);
        }
        self.startstatus = false;
      }
    });
    this.$store.dispatch("mywork/getUserTasks");
    clearInterval(this.interval);
    this.gettimerinformation(this.$route.params.taskId);
    this.getalltimesofUsers(this.$route.params.taskId);
  },
  beforeCreate() {
    if (this.windowWidth > 767) {
      this.$nextTick().then(() => document.body.classList.add("has-scroll"));
    }
  },
  destroyed() {
    if (this.windowWidth > 767) {
      document.body.classList.remove("has-scroll");
    }
  },
  mounted() {
    var self = this;
    if (this.showTask == true) {
      $("body").addClass("popup-task-dialog");
    } else {
      $("body").removeClass("popup-task-dialog");
    }
    $(".tiptap-vuetify-editor__content ul li p").contents().unwrap();
    var regex = /^[1-9]\d*$/;
    this.$validator.extend("hrzeorvalidation", {
      getMessage: (field) => "This " + field + " already exists.",
      validate: (value) => {
        var returndata = true;
        if (
          (value == "" || value == 0) &&
          (self.taskEstimate.minutes == "" || self.taskEstimate.minutes == 0)
        ) {
          returndata = false;
        } else {
          if (
            regex.test(value) == false &&
            (self.taskEstimate.minutes == "" || self.taskEstimate.minutes == 0)
          ) {
            returndata = false;
          }
        }
        return returndata;
      },
    });
    this.$validator.extend("minutevalidation", {
      getMessage: (field) => "This " + field + " already exists.",
      validate: (value) => {
        var returndata = true;
        if (
          (self.taskEstimate.hours == "" || self.taskEstimate.hours == 0) &&
          (value == "" || value == 0)
        ) {
          returndata = false;
        } else {
          if (
            regex.test(self.taskEstimate.hours) == false &&
            regex.test(value) == false
          ) {
            returndata = false;
          }
        }
        return returndata;
      },
    });
    bus.$on("createtask_lock", (data) => {
      if (
        data.IssubtaskChild == true &&
        data.subtaskParentId == self.$route.params.taskId
      ) {
        self.subtasks.push(data);
        self.task.subtaskIds.push(data.id);
      }
      if (
        data.subtaskParentId == self.$route.params.taskId &&
        self.$route.name == "single-task"
      ) {
        self.task.IssubtaskParent = true;
        self.task.IssubtaskChild = false;
      }
    });
    bus.$on("updatetimerinfo", () => {
      if (self.$route.name == "single-task") {
        self.getalltimesofUsers(self.$route.params.taskId);
      }
    });
    this.$validator.extend(
      "checkhoursandminutes",
      {
        validate: (hours, [minutes]) => {
          var returndata = true;
          if (minutes == 0 && parseInt(hours) == 0) {
            returndata = false;
          }
          return returndata;
        },
      },
      {
        hasTarget: true,
      }
    );
  },
  methods: {
    checkshowtodostatus(todo) {
      var returnstatus = false;
      if (this.showcompletedtodoUsers) {
        if (this.showcompletedtodoUsers.includes(this.authuser.id)) {
          returnstatus = todo.completed_status ? false : true;
        } else {
          returnstatus = true;
        }
      } else {
        returnstatus = false;
      }
      return returnstatus;
    },
    updatehidecompletedtodo() {
      var index = this.showcompletedtodoUsers.findIndex(
        (data) => data == this.authuser.id
      );
      if (index < 0) {
        this.showcompletedtodoUsers.push(this.authuser.id);
      } else {
        this.showcompletedtodoUsers.splice(index, 1);
      }
      // var self = this;
      // var postdata = {
      //   project_id: self.task.project,
      //   taskId: self.task.id,
      // };
      // this.$http
      //   .post(
      //     process.env.VUE_APP_ROOT_API + 'project/add-showcompleted-users',
      //     postdata
      //   )
      //   .then((response) => {
      //     self.task.showcompletedtodoUsers =
      //       response.data.showcompletedtodoUsers;
      //   })
      //   .catch((error) => {
      //     if (error.response) {
      //       if (error.response.data && error.response.data.message) {
      //         self.snackMessage = error.response.data.message;
      //         self.showSnackBar = true;
      //         self.messagecolor = 'red';
      //       }
      //     }
      //   });
    },
    todoshowstatus(todolist) {
      if (this.showcompleted == false) {
        return true;
      } else {
        return todolist.completed_status == false ? true : false;
      }
    },
    updateattribute(data) {
      var subtaskindex = this.sortedsubtask.findIndex(
        (elem) => elem.id == data.task.id
      );
      if (data.update == "completed") {
        this.sortedsubtask[subtaskindex].Iscompleted = data.value;
      }
      if (data.update == "status") {
        this.sortedsubtask[subtaskindex].status = data.value;
      }
      if (data.update == "priority") {
        this.sortedsubtask[subtaskindex].priority = data.value;
      }
      if (data.update == "type") {
        this.sortedsubtask[subtaskindex].type = data.value;
      }
    },
    createDialogOpen() {
      this.renderaddtask = true;
      this.showbuttonstatus = true;
    },
    closepopup(data) {
      if (data == true) {
        this.renderaddtask = false;
        this.showbuttonstatus = false;
      }
    },
    dispatchprojectlable() {
      this.searchlable = "";
      this.lable = "";
      this.$store.dispatch("project/getProjectLabels", this.task.project);
    },
    gettoDOowner(item) {
      var username = "";
      var userindex = this.allusers.findIndex((data) => data.id == item.owner);
      if (userindex != -1) {
        username =
          this.firstletterupercase(this.allusers[userindex].firstName) +
          " " +
          this.firstletterupercase(this.allusers[userindex].lastName);
      }
      return username;
    },
    todoAuthstatus(item, checkboxstatus) {
      var editstatus = [];
      var taskuserindex = this.task.assignedToUsers.findIndex(
        (data) => data.user == this.authuser.id || data._id == this.authuser.id
      );

      var checkstatus = checkboxstatus
        ? item.owner == this.authuser.id || taskuserindex != -1
        : item.owner == this.authuser.id && taskuserindex != -1;
      if (checkstatus) {
        editstatus.push(true);
      }
      var managerindex = this.$store.state.project.projectUsers.findIndex(
        (data) =>
          data.id == this.authuser.id &&
          data.isManager == true &&
          data.isActive == true
      );
      if (
        JSON.parse(this.$store.state.account.user).allcompanies.find(
          (data) => data.id == this.$route.query.companyId
        ).isAdmin ||
        managerindex != -1
      ) {
        editstatus.push(true);
      }
      return editstatus.length > 0 ? true : false;
    },

    makeaddTimeinputempty() {
      this.reporttimeopen = false;
      this.reportdate = "";
      this.reporthour = 0;
      this.reportminutes = 0;
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    firstletterupercase(str) {
      if (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
      return str;
    },
    openaddReportTimepopup() {
      this.reporttimeopen = true;
      this.reportdate = "";
      this.reporthour = 0;
      this.reportminutes = 0;
    },
    canceladdreport() {
      var self = this;
      self.reportmenu = false;
      self.reportdate = "";
      self.reporthour = 0;
      self.reportminutes = 0;
      self.$nextTick(() => {
        self.$validator.reset();
      });
    },
    addreportTime() {
      var self = this;
      this.$validator.validateAll("add-reportime").then((res) => {
        if (res) {
          self.addreportimestatus = true;
          this.addreportbtnLoading = true;
          this.$http
            .post(
              process.env.VUE_APP_ROOT_API +
                "task/add-report-time/" +
                this.task.id,
              {
                date: this.reportdate,
                hours: this.reporthour,
                minutes: this.reportminutes,
                userId: this.authuser.id,
                myworkatatus: false,
              }
            )
            .then((res) => {
              res.data;
              self.reporttimeopen = false;
              (this.reportdate = ""),
                (this.reporthour = 0),
                (this.reportminutes = 0);
              self.$nextTick(() => {
                self.$validator.reset();
              });
              this.getalltimesofUsers(self.$route.params.taskId);
              this.addreportbtnLoading = false;
              self.addreportimestatus = false;
              // success
              // if(success.data.report.date!= moment().format("YYYY-MM-DD")){
              //   self.snackMessage =
              //     success.data.report.userInfo.firstName +
              //     " " +
              //     success.data.report.userInfo.lastName +
              //     " reported the time for the day " +
              //     success.data.report.date +
              //     " with the total worked hrs being " +
              //     success.data.report.hours +
              //     "h:" +
              //     success.data.report.minutes +
              //     "m";
              //   self.showSnackBar = true;
              //   self.messagecolor = "green";
              // }
              bus.$emit("task-spend-time", self.getTotaltimer, self.task.id);
            })
            .catch((error) => {
              if (error.response.data && error.response.data.message) {
                this.addreportimestatus = false;
                this.addreportbtnLoading = false;
                this.snackMessage = error.response.data.message;
                this.showSnackBar = true;
                this.messagecolor = "red";
              }
            });
        }
      });
    },
    opensubtask() {
      this.interval = null;
      clearInterval(this.interval);
    },
    gotoparentask(task) {
      this.timershowstatus = false;
      this.interval = null;
      clearInterval(this.interval);
      this.$router.replace({
        name: "single-task",
        params: {
          id: task.project,
          taskId: task.parenttask.id,
        },
        query: {
          companyId: this.$route.query.companyId,
        },
      });
    },
    pinnedTask(task) {
      var self = this;
      this.pinnedTaskbtn = !this.pinnedTaskbtn;
      var postdata = {
        project_id: task.project,
        taskId: task.id,
      };
      this.$http
        .post(process.env.VUE_APP_ROOT_API + "project/pinned-tasks", postdata)
        .then((response) => {
          setTimeout(() => {
            bus.$emit("createPinnedTasks", response.data);
          }, 500);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data && error.response.data.message) {
              self.snackMessage = error.response.data.message;
              self.showSnackBar = true;
              self.messagecolor = "red";
            }
          }
        });
    },
    getallprojects() {
      this.selectedproject = "";
      this.$http
        .get(
          process.env.VUE_APP_ROOT_API +
            "task/get-all-projects/" +
            this.$route.params.taskId
        )
        .then((res) => {
          this.allprojects = res.data;
          this.changeprojectpopup = true;
        });
    },
    submitchangetask() {
      if (this.disablechangeproject) {
        return;
      }
      this.disablechangeproject = true;
      var self = this;
      if (this.selectedproject != "") {
        self.$http
          .post(process.env.VUE_APP_ROOT_API + "task/task-change-project", {
            taskid: self.task.id,
            selectedproject: self.selectedproject,
          })
          .then((success) => {
            self.changeprojectpopup = false;
            self.switchprojectconfirm = false;
            this.task.labels = [];
            bus.$emit("changeproject", this.task);
            self.timershowstatus = false;
            self.timespendedusers = [];
            self.tasklables = [];
            self.task.labels = [];
            self.interval = null;
            clearInterval(self.interval);
            const items = [
              {
                text: this.project.workspacename,
                disabled: false,
                to: {
                  name: "single-workspace",
                  params: {
                    id: this.project.workspace,
                  },
                },
              },
              {
                text: success.data.project.name,
                disabled: false,
                exact: true,
                to: {
                  name: "single-project",
                  params: {
                    id: success.data.project.id,
                  },
                },
              },
              {
                text: "Tasks",
                disabled: false,
                to: {
                  name: "single-project-tasks",
                  params: {
                    id: success.data.project.id,
                  },
                },
              },
              {
                text: this.task.title,
                disabled: true,
              },
            ];
            this.$store.commit("assignToProjectBreadCrumb", items);
            bus.$emit("changeprojectsuccessmessage", success.data.task); 
            if (this.previousroute.name == null) {
              this.$router.replace({
                name: "single-project-tasks",
                params: {
                  id: self.task.project,
                },
                query: {
                  companyId: this.$route.query.companyId,
                },
              });
            } else {
              this.$router.replace({
                name: "single-task",
                params: {
                  id: success.data.project.id,
                  taskId: success.data.task.id,
                },
                query: {
                  companyId: this.$route.query.companyId,
                },
              });
              this.$router.go(-1);
            }
          })
          .catch((error) => {
            this.disablechangeproject = false;
            if (error.response && error.response.data.message) {
              this.snackMessage = error.response.data.message;
              this.showSnackBar = true;
            }
          });
      } else {
        this.snackMessage = "Please select changing project";
        this.showSnackBar = true;
        this.disablechangeproject = false;
      }
    },
    confirmtaskcomplete() {
      var confirmstatus = false;
      if (this.task.Iscompleted == false) {
        if (
          this.task.actionRequiredBy &&
          this.task.actionRequiredBy != this.authuser.id
        ) {
          confirmstatus = true;
        }
      }
      if (confirmstatus == true) {
        this.opencompleteconfirmpopup = true;
      } else {
        this.updatetaskascompleted();
      }
    },
    updatetaskascompleted() {
      var self = this;
      if (this.canEditTask) {
        var completestatus = self.task.Iscompleted ? false : true;
        this.$http
          .post(
            process.env.VUE_APP_ROOT_API +
              "task/task-completed-status/" +
              this.task.id,
            {
              status: completestatus,
            }
          )
          .then((success) => {
            setTimeout(() => {
              bus.$emit("updatetaskascomplete", success.data);
            }, 1000);
            self.task.Iscompleted = success.data.Iscompleted;
            self.$store.commit("project/changesingletaskdata", {
              type: "Iscompleted",
              task: self.task,
            });
            self.opencompleteconfirmpopup = false;
          });
        // .catch((error) => {
        //   if (error.response && error.response.data.message) {
        //     this.snackMessage = error.response.data.message;
        //     this.showSnackBar = true;
        //   }
        // });
      }
    },
    chackSettingstatus(task) {
      var returnstatus = true;
      if (task.IssubtaskChild == true && this.editstatus(task) == false) {
        returnstatus = false;
      }
      return returnstatus;
    },
    editstatus(task) {
      var allmanagers = [];
      this.$store.state.project.projectUsers.forEach((user) => {
        if (user.isManager == true || user.isAdmin == true) {
          allmanagers.push(user.user);
        }
      });
      task.assignedToUsers.forEach((assignuser) => {
        allmanagers.push(assignuser._id);
      });
      if (task.actionRequiredBy) {
        allmanagers.push(task.actionRequiredBy);
      }
      if (
        JSON.parse(this.$store.state.account.user).allcompanies.find(
          (data) => data.id == this.$route.query.companyId
        ).isAdmin
      ) {
        return true;
      } else {
        return allmanagers.includes(
          JSON.parse(this.$store.state.account.user).id
        );
      }
    },
    gotoworkspace(project) {
      this.$router.push({
        name: "single-project",
        params: {
          id: project.id,
        },
        query: {
          companyId: this.$route.query.companyId,
        },
      });
    },
    gotoproject(ws) {
      this.$router.push({
        name: "single-workspace",
        params: {
          id: ws.id,
        },
        query: {
          companyId: this.$route.query.companyId,
        },
      });
    },
    convertToTask() {
      var self = this;
      var postdata = {
        id: this.$route.params.taskId,
      };
      this.$http
        .post(
          process.env.VUE_APP_ROOT_API + "task/convert-subtask-totask",
          postdata
        )
        .then((res) => {
          self.task = res.data.task;
          setTimeout(() => {
            bus.$emit("converttoSubtask", res.data.task);
            if (res.data.setparentasnormal != "") {
              bus.$emit("changeparentonormal", res.data.setparentasnormal);
            }
          }, 1000);
          self.$store.commit("project/changeSubtasktoNormal", res.data.task);
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            this.snackMessage = error.response.data.message;
            this.showSnackBar = true;
            this.messagecolor = "red";
          }
        });
    },
    openconvertTosubtask() {
      var self = this;
      this.$http
        .get(
          process.env.VUE_APP_ROOT_API +
            "task/getparentaskinfos/" +
            self.$route.params.taskId
        )
        .then((res) => {
          self.subtaskpopup = true;
          self.parentasksinfo = res.data;
        });
    },
    subminconvertsubtask() {
      if (this.btnLoading) {
        return;
      }
      var self = this;
      this.btnLoading = true;
      if (this.selectedparent) {
        var postdata = {
          id: this.$route.params.taskId,
          selectedParent: this.selectedparent,
        };
        this.$http
          .post(
            process.env.VUE_APP_ROOT_API + "task/convert-task-tosubtask",
            postdata
          )
          .then((res) => {
            self.task = res.data;
            self.subtaskpopup = false;
            self.deleteConformDialog = false;
            setTimeout(() => {
              bus.$emit("convert-task-tosubtask", res.data.subtaskParentId);
            }, 1000);
            self.$store.commit("project/changeNormaltoSubtask", res.data);
            self.searchParenttask = "";
            self.selectedparent = "";
            self.btnLoading = false;
          })
          .catch((error) => {
            if (error.response.data && error.response.data.message) {
              self.snackMessage = error.response.data.message;
              self.showSnackBar = true;
              self.messagecolor = "red";
            }
            self.subtaskpopup = false;
            self.btnLoading = false;
          });
      } else {
        this.snackMessage = "Parent task is required";
        this.showSnackBar = true;
        this.btnLoading = false;
      }
    },
    changeOrderAndStatus(evt) {
      var self = this;
      var taskindex = self.subtasks.findIndex(
        (data) => data._id == evt.item.id
      );
      var payload = {
        newOrderIndex: evt.newIndex + 1,
        oldOrderIndex: evt.oldIndex + 1,
        parentTaskid: this.$route.params.taskId,
        taskId: evt.item.id,
      };
      if (payload.oldOrderIndex > payload.newOrderIndex) {
        self.subtasks.forEach(function (task, key) {
          if (
            task.order >= payload.newOrderIndex &&
            task.order < payload.oldOrderIndex
          ) {
            self.subtasks[key].order = self.subtasks[key].order + 1;
          }
        });
      }
      if (payload.oldOrderIndex < payload.newOrderIndex) {
        self.subtasks.forEach(function (task, key) {
          if (
            task.order <= payload.newOrderIndex &&
            task.order > payload.oldOrderIndex
          ) {
            self.subtasks[key].order = self.subtasks[key].order - 1;
          }
        });
      }
      if (taskindex != -1) {
        self.subtasks[taskindex].order = payload.newOrderIndex;
      }
      this.$http.patch(
        process.env.VUE_APP_ROOT_API +
          "task/change-subtask-order/" +
          evt.item.id,
        {
          newOrderIndex: evt.newIndex + 1,
          oldOrderIndex: evt.oldIndex + 1,
          parentTaskid: this.$route.params.taskId,
          taskId: evt.item.id,
          fromparent: this.$route.params.taskId,
          toparent: this.$route.params.taskId,
        }
      );
    },
    removeEstimate() {
      return this.$http
        .delete(process.env.VUE_APP_ROOT_API + "task/delete-estimatetime", {
          data: {
            taskid: this.$route.params.taskId,
          },
        })
        .then(() => {
          this.taskEstimate.hours = "";
          this.taskEstimate.minutes = "";
          this.task.estimate.hours = "";
          this.task.estimate.minutes = "";
        })
        .catch((error) => {
          if (error.response.data && error.response.data.message) {
            this.snackMessage = error.response.data.message;
            this.showSnackBar = true;
            this.messagecolor = "red";
          }
        });
    },
    replaceURLs(description) {
      if (!description) return;
      var urlRegex = /(((https?:)|(www\.))[^\s]+)/g;
      return description.replace(urlRegex, function (url) {
        var hyperlink = url;
        if (!hyperlink.match("^https?:")) {
          hyperlink = "http://" + hyperlink;
        }
        return (
          '<a class="black--text word-break" href="' +
          hyperlink +
          '" target="_blank" rel="noopener noreferrer">' +
          url +
          "</a>"
        );
      });
    },
    async singletasktimer(data) {
      var self = this;
      var timerindex = this.timespendedusers.findIndex(
        (data) => data.id == this.authuser.id
      );
      var spenttime = await this.getSingleUsertimer(
        data.alltimer,
        this.authuser
      );
      if (spenttime) {
        if (timerindex != -1) {
          this.timespendedusers[timerindex].spendtime = spenttime;
        } else {
          if (this.timespendedusers.length > 0) {
            this.timespendedusers.push({
              id: self.authuser.id,
              firstName: this.firstletterupercase(this.authuser.firstName),
              lastName: this.firstletterupercase(this.authuser.lastName),
              Profileimage: this.authuser.Profileimage,
              spendtime: spenttime,
            });
          } else {
            this.timespendedusers = [
              {
                id: self.authuser.id,
                firstName: this.firstletterupercase(this.authuser.firstName),
                lastName: this.firstletterupercase(this.authuser.lastName),
                Profileimage: this.authuser.Profileimage,
                spendtime: spenttime,
              },
            ];
          }
        }
        bus.$emit(
          "task-spend-time",
          self.getTotaltimer,
          self.$route.params.taskId
        );
      }
    },
    getsortedorder(data) {
      data.sort(function (a, b) {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });
    },
    async getSingleUsertimer(tasks, userinfo) {
      userinfo;
      var completetimestamp = 0;
      var completeOTtimestamp = 0;
      const map = {};
      for (const singletimer of tasks) {
        const day = moment(singletimer.date, "YYYY-MM-DD").format(
          "Do MMMM YYYY"
        );
        map[day] = map[day] || {
          formateddate: day,
          daytimers: [],
        };
        map[day].daytimers.push({ singletimer });
      }
      var dayGroupedTimers = Object.keys(map).map((day) => map[day]);
      var spendtimehistory = [];
      for (const singledaytimer of dayGroupedTimers) {
        var workedhrperday = 0;
        if (singledaytimer.daytimers.length > 0) {
          for (const tasktimer of singledaytimer.daytimers) {
            workedhrperday =
              workedhrperday +
              (1000 * (60 * tasktimer.singletimer.minutes) +
                1000 * 60 * (60 * tasktimer.singletimer.hours));
          }
        }
        completetimestamp = completetimestamp + workedhrperday;
        var spendhr = this.timestampToDatetime(workedhrperday);
        spendtimehistory.push({
          formateddate: singledaytimer.formateddate,
          totalhr: spendhr,
        });
      }
      if (completetimestamp > 0) {
        var totalspentTime = this.timestampToDatetime(completetimestamp);
      }
      if (completeOTtimestamp > 0) {
        var totalspentOTTime = this.timestampToDatetime(completeOTtimestamp);
      }
      if (totalspentTime || totalspentOTTime) {
        return {
          textTime: totalspentTime,
          textOTtime: totalspentOTTime,
          timestampvalue: completetimestamp + completeOTtimestamp,
          history: spendtimehistory,
        };
      }
    },
    async getalltimesofUsers(taskId) {
      const returntimerinfo = await this.$http.get(
        process.env.VUE_APP_ROOT_API + "task/single-tasktimer/" + taskId
      );
      var returntimer = [];
      if (returntimerinfo.data.length > 0) {
        for (var singleuser of returntimerinfo.data) {
          if (singleuser.tasks.length > 0) {
            var spenttime = await this.getSingleUsertimer(
              singleuser.tasks,
              singleuser
            );
            if (spenttime) {
              singleuser.spendtime = spenttime;
              returntimer.push(singleuser);
            }
          }
        }
      }
      this.timespendedusers = returntimer;
      bus.$emit("task-spend-time", this.getTotaltimer, this.task.id);
    },
    timestampToDatetime(timestamp) {
      const returnHrs = (interval) => {
        return {
          minutes: Math.floor((interval % (1000 * 60 * 60)) / (1000 * 60)),
          hours: Math.floor(
            (interval % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          ),
          day: Math.floor(interval / (1000 * 60 * 60 * 24)),
        };
      };
      var totalspentTime = returnHrs(timestamp);
      var spendtimeformate = "";
      if (
        totalspentTime.day != 0 ||
        totalspentTime.hours != 0 ||
        totalspentTime.minutes != 0
      ) {
        var returndate = {
          minutes: totalspentTime.minutes + "m ",
          hours: totalspentTime.hours + totalspentTime.day * 24 + "h ",
        };
        spendtimeformate = returndate.hours + returndate.minutes;
      }
      return spendtimeformate;
    },
    getDatesBetweenDates(startDate, endDate, prenextstatus) {
      let dates = [];
      const theDate = new Date(startDate);
      while (theDate <= endDate) {
        dates = [...dates, new Date(theDate)];
        theDate.setDate(theDate.getDate() + 1);
      }
      dates = [...dates];
      var formatedendDate = moment(parseInt(endDate)).format("YYYY-MM-DD");
      var datesLastdate = moment(dates[dates.length - 1]).format("YYYY-MM-DD");
      if (formatedendDate != datesLastdate) {
        dates.push(new Date(endDate));
      }
      var returndates = dates.map((data) => moment(data).format("YYYY-MM-DD"));
      if (prenextstatus == true && returndates.length > 0) {
        var predate = moment(returndates[0], "YYYY-MM-DD")
          .subtract(1, "d")
          .format("YYYY-MM-DD");
        var apdate = moment(returndates[returndates.length - 1], "YYYY-MM-DD")
          .add(1, "d")
          .format("YYYY-MM-DD");
        if (predate || apdate) {
          if (predate) {
            returndates.push(predate);
          }
          if (apdate) {
            returndates.push(apdate);
          }
        }
      }
      return returndates;
    },
    assignmindate() {
      if (this.StartEnddates.length > 0) {
        this.minEndDate = this.StartEnddates[0];
      }
    },
    loadpage() {
      window.location.reload();
    },
    openassignuserpopup() {
      this.selectedUsers = this.task.assignedToUsers.map((val) => val._id);
      this.assignUsersDialog = true;
    },
    cancelEstimate() {
      this.editEstimateMenu = false;
      this.taskEstimate.hours = this.task.estimate.hours;
      this.taskEstimate.minutes = this.task.estimate.minutes;
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    gotosingleuser(user) {
      this.$router.push({
        name: "singleuser-info",
        params: {
          userId: user,
        },
        query: {
          companyId: this.$route.query.companyId,
        },
      });
    },
    editCustomDate(item) {
      if (item.value) {
        var postdata = {
          taskId: this.$route.params.taskId,
          textName: item.value,
          customfieldId: item.id,
        };
        return this.$http
          .post(
            process.env.VUE_APP_ROOT_API + "task/update-customtextinput",
            postdata
          )
          .then((response) => {
            this.task.customFields = response.data;
            this.$store.commit("project/changesingletaskdata", {
              type: "customFields",
              task: this.task,
            });
          })
          .catch((error) => {
            if (error.response.data && error.response.data.message) {
              this.snackMessage = error.response.data.message;
              this.showSnackBar = true;
              this.messagecolor = "red";
            }
          });
      }
    },
    editTaskcustomtext: debounce(function (item) {
      if (item.value) {
        this.editCustomDate(item);
      }
    }, 1000),
    editCustomDropdown(item, taskfield) {
      var self = this;
      var postdata = {
        taskId: this.$route.params.taskId,
        customfieldId: taskfield.id,
        dropdown_attr: item,
      };
      return this.$http
        .post(
          process.env.VUE_APP_ROOT_API + "task/update-taskcustomdropdown",
          postdata
        )
        .then((response) => {
          var indexofdropitem = self.task.customFields.findIndex(
            (data) => data.id == taskfield.id
          );
          self.task.customFields[indexofdropitem].attributeValueId =
            response.data.attributeValueId;
          self.$store.commit("project/changesingletaskdata", {
            type: "customFields",
            task: self.task,
          });
        })
        .catch((error) => {
          if (error.response.data && error.response.data.message) {
            self.snackMessage = error.response.data.message;
            self.showSnackBar = true;
            self.messagecolor = "red";
          }
        });
    },
    cancelDate() {
      this.$http
        .get(
          process.env.VUE_APP_ROOT_API +
            "task/get-taskinfo/" +
            this.$route.params.taskId
        )
        .then((res) => {
          this.startandEndpickermenu = false;
          if (res.data.startDate != "" && res.data.endDate != "") {
            this.StartEnddates = [];
            this.StartEnddates.push(res.data.startDate);
            this.StartEnddates.push(res.data.endDate);
          } else {
            this.StartEnddates = [];
          }
          this.minEndDate = "";
          this.$refs.startandEndpickermenu.save(this.StartEnddates);
        });
    },
    assignRangeofDates(items) {
      var self = this;
      var datearray = [];
      if (this.StartEnddates.length > 0) {
        for (const date of this.StartEnddates) {
          if (date != "" || date != undefined) {
            datearray.push(new Date(date));
          }
        }
      }
      if (datearray.length > 0) {
        var sorted = datearray.slice().sort(function (a, b) {
          return new Date(a) - new Date(b);
        });
        var startdate = sorted[0],
          endDate = sorted[sorted.length - 1];
        if (
          startdate &&
          startdate != "Invalid Date" &&
          endDate &&
          endDate != "Invalid Date" &&
          this.StartEnddates.length > 1
        ) {
          this.$http
            .patch(
              process.env.VUE_APP_ROOT_API +
                "task/update-task-startenddate/" +
                this.$route.params.taskId,
              {
                startdate: startdate,
                enddate: endDate,
              }
            )
            .then((res) => {
              self.task.startDate = res.data.startDate;
              self.task.endDate = res.data.endDate;
              self.startandEndpickermenu = false;
              this.$refs.startandEndpickermenu.save(items);
              self.task.projectinfo = self.project;
              setTimeout(() => {
                bus.$emit("updatestartenddate", self.task);
              }, 1000);
              self.$store.commit("project/changesingletaskdata", {
                type: "endDate",
                task: self.task,
              });
              self.$nextTick(() => {
                self.$validator.reset();
              });
            })
            .catch((error) => {
              if (error.response && error.response.data.message) {
                self.snackMessage = error.response.data.message;
                self.showSnackBar = true;
              }
            });
        } else {
          this.snackMessage = "Start date and end date is required";
          this.showSnackBar = true;
        }
      } else {
        this.snackMessage = "Start date and end date is required";
        this.showSnackBar = true;
      }
    },
    emptydate() {
      var self = this;
      this.$http
        .patch(
          process.env.VUE_APP_ROOT_API +
            "task/empty-task-startenddate/" +
            self.$route.params.taskId
        )
        .then((res) => {
          self.startandEndpickermenu = false;
          self.task.startDate = res.data.startDate
            ? moment(res.data.startDate).format("YYYY-MM-DD")
            : "";
          self.task.endDate = res.data.endDate
            ? moment(res.data.endDate).format("YYYY-MM-DD")
            : "";
          if (res.data.startDate != "" && res.data.endDate != "") {
            this.StartEnddates = [];
            this.StartEnddates.push(res.data.startDate);
            this.StartEnddates.push(res.data.endDate);
          } else {
            this.StartEnddates = [];
          }
          self.task.projectinfo = self.project;
          self.$refs.startandEndpickermenu.save(self.StartEnddates);
          bus.$emit("updatestartenddate", self.task);
          self.$nextTick(() => {
            self.$validator.reset();
          });
          self.$store.commit("project/changesingletaskdata", {
            type: "endDate",
            task: self.task,
          });
        })
        .catch((error) => {
          if (error.response && error.response.data.message) {
            this.snackMessage = error.response.data.message;
            this.showSnackBar = true;
          }
        });
    },
    closeModal(event = null) {
      if (this.previousroute.name == "single-project-tasks.calendar") {
        bus.$emit("calendarloadstart");
      }
      clearInterval(this.interval);
      this.interval = null;
      if (event) {
        if (event.key != "Escape") {
          bus.$emit("popupCloseTimer", true);
          return;
        } else {
          bus.$emit("popupCloseTimer", true);
          this.$router.replace({
            name: this.previousroute.name,
            params: this.previousroute.params,
            query: {
              companyId: this.$route.query.companyId,
            },
          });
        }
      } else {
        if (window.history.length > 1) {
          this.$router.replace({
            name: this.previousroute.name,
            params: this.previousroute.params,
            query: {
              companyId: this.$route.query.companyId,
            },
          });
          this.createBreadCrumbs("closemodel");
        } else {
          this.$router.replace(
            "/mywork?companyId=" + this.$route.query.companyId
          );
        }
      }
      this.createBreadCrumbs("closemodel");
    },
    outsidecloseModal() {
      if (this.previousroute.name == "single-project-tasks.calendar") {
        bus.$emit("calendarloadstart");
      }
      bus.$emit("popupCloseTimer", true);
      clearInterval(this.interval);
      this.interval = null;
      this.$router.replace({
        name: this.previousroute.name,
        params: this.previousroute.params,
        query: {
          companyId: this.$route.query.companyId,
        },
      });
      this.createBreadCrumbs("closemodel");
      bus.$emit("popupCloseTimer");
    },
    createBreadCrumbs(model) {
      const items = [
        {
          text: this.project.workspacename,
          disabled: false,
          to: {
            name: "single-workspace",
            params: {
              id: this.project.workspace,
            },
          },
        },
        {
          text: this.project.name,
          disabled: false,
          exact: true,
          to: {
            name: "single-project",
            params: {
              id: this.project.id,
            },
          },
        },
        {
          text: "Tasks",
          disabled: false,
          to: {
            name: "single-project-tasks",
            params: {
              id: this.project.id,
            },
          },
        },
        {
          text: this.task.title,
          disabled: true,
        },
      ];
      if (model == "closemodel") {
        var index = 3;
        items.splice(index, 1);
      }
      this.$store.commit("assignToProjectBreadCrumb", items);
    },
    assignactionrequiredby(item) {
      var self = this;
      var postdata = {
        assignedtouser: item._id,
      };
      this.$http
        .post(
          process.env.VUE_APP_ROOT_API +
            "task/update-action-required-to/" +
            self.$route.params.taskId,
          postdata
        )
        .then((response) => {
          self.task.actionRequiredBy = response.data.task.actionRequiredBy;
          self.$store.commit(
            "project/assignActionreqiredto",
            response.data.task
          );
          self.$store.dispatch(
            "project/getProjectUsers",
            self.$route.params.id
          );
          self.task.projectinfo = this.project;
          setTimeout(() => {
            bus.$emit("change-actionrequiredby", response.data.task);
          }, 1000);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data && error.response.data.message) {
              self.snackMessage = error.response.data.message;
              self.showSnackBar = true;
              self.messagecolor = "red";
              self.messagecolor = "red";
              self.messagecolor = "red";
            }
          }
        });
    },
    addtodotitle() {
      if (this.todo_name.trim()) {
        var postdata = {
          taskid: this.$route.params.taskId,
          todo_name: this.todo_name.trim(),
        };
        this.todo_name = "";
        return this.$http
          .post(process.env.VUE_APP_ROOT_API + "task/create-todo", postdata)
          .then((res) => {
            this.task.todos = res.data;
          })
          .catch((error) => {
            if (error.response.data && error.response.data.message) {
              this.snackMessage = error.response.data.message;
              this.showSnackBar = true;
              this.messagecolor = "red";
            }
          });
      } else {
        this.snackMessage = "Todo field name is required";
        this.showSnackBar = true;
      }
    },
    deleteTodo(item) {
      if (this.tododeletestatus) {
        return;
      }
      this.taskEstimate.hours = this.task.estimate.hours;
      this.taskEstimate.minutes = this.task.estimate.minutes;
      this.tododeletestatus = true;
      return this.$http
        .delete(process.env.VUE_APP_ROOT_API + "task/delete-todo", {
          data: {
            taskid: this.$route.params.taskId,
            todo_id: item.id,
          },
        })
        .then((res) => {
          this.task.todos = res.data;
          this.tododeletestatus = false;
        })
        .catch((error) => {
          this.tododeletestatus = false;
          if (error.response.data && error.response.data.message) {
            this.snackMessage = error.response.data.message;
            this.showSnackBar = true;
            this.messagecolor = "red";
          }
        });
    },
    editTodo(item) {
      var self = this;
      this.$validator.validateAll("create-task").then((res) => {
        if (res) {
          var todolistname = item.name;
          if (todolistname.trim() != "") {
            self.$http
              .patch(process.env.VUE_APP_ROOT_API + "task/edit-todo", {
                taskid: self.$route.params.taskId,
                todo_data: item,
              })
              .then((res) => {
                self.task.todos = res.data;
              })
              .catch((error) => {
                if (error.response.data && error.response.data.message) {
                  self.snackMessage = error.response.data.message;
                  self.showSnackBar = true;
                  self.messagecolor = "red";
                }
              });
          } else {
            self.$http
              .get(
                process.env.VUE_APP_ROOT_API +
                  "task/get-todo-item/" +
                  self.$route.params.taskId
              )
              .then((res) => {
                self.task.todos = res.data;
              });
          }
        }
      });
    },
    checktodo(item) {
      var self = this;
      self.$http
        .patch(process.env.VUE_APP_ROOT_API + "task/check-todo", {
          taskid: self.$route.params.taskId,
          todo_data: item,
        })
        .then((res) => {
          self.task.todos = res.data;
        })
        .catch((error) => {
          if (error.response.data && error.response.data.message) {
            self.snackMessage = error.response.data.message;
            self.showSnackBar = true;
            self.messagecolor = "red";
          }
        });
    },
    onClickOutside() {
      var gettodoindex = this.sortedArray.findIndex(
        (data) => data.id == this.current_list_todo_id
      );
      if (gettodoindex != -1) {
        this.editTodo(this.sortedArray[gettodoindex]);
      }
      this.current_list_todo = false;
    },
    onSave(event) {
      const keyCode = event.which || event.keyCode;
      if (keyCode === 13 && !event.shiftKey) {
        this.addtodotitle();
        event.preventDefault();
      }
    },
    onEdit(event, todolist) {
      const keyCode = event.which || event.keyCode;
      if (keyCode === 13 && !event.shiftKey) {
        this.editTodo(todolist);
        this.current_list_todo = false;
        event.preventDefault();
      }
    },
    single_todo_edit(item) {
      var gettodoindex = this.sortedArray.findIndex(
        (data) => data.id == this.current_list_todo_id
      );
      if (gettodoindex != -1) {
        this.editTodo(this.sortedArray[gettodoindex]);
      }
      if (this.todoAuthstatus(item, false)) {
        this.current_list_todo = true;
        this.current_list_todo_id = item.id;
      }
    },
    assignlables(tasklable) {
      this.projectTaglabels = [];
      this.projectTags.forEach((lable) => {
        if (
          tasklable.map((data) => data.lable_id).includes(lable.id) == false
        ) {
          this.projectTaglabels.push(lable);
        }
      });
    },
    getTask(projectid, taskid) {
      return this.$http
        .get(
          process.env.VUE_APP_ROOT_API +
            "project/" +
            projectid +
            "/task/" +
            taskid
        )
        .then((res) => {
          this.pinnedTaskbtn =
            res.data.task.pinnedUsers.includes(this.authuser.id) > 0
              ? true
              : false;
          this.project = res.data.project;
          this.$store.dispatch(
            "project/getProjectUsers",
            res.data.task.project
          );
          this.$store.dispatch(
            "project/getProjectLabels",
            res.data.task.project
          );
          this.$store.commit(
            "project/assignProjectLabels",
            res.data.project.labels
          );
          this.projectlable = res.data.project.labels;
          this.subtasks = res.data.subtasks;
          this.tasklables = res.data.project.tasklables;
          this.task = res.data.task;
          this.otherTasks = res.data.otherTasks;
          var user = JSON.parse(this.$store.state.account.user);
          var userIdArray = [];
          this.StartEnddates.push(res.data.task.startDate);
          this.StartEnddates.push(res.data.task.endDate);
          res.data.project.assignedToUsers.forEach((data) => {
            if (user.id == data._id && data.isManager) {
              userIdArray.push(data._id);
            }
          });
          if (res.data.task.owner._id == user.id || userIdArray.length > 0) {
            this.caneditdelete = true;
          } else {
            this.caneditdelete = false;
          }
          this.assignlables(res.data.task.labels);
        });
    },
    dragablefunction(event) {
      if (event.oldIndex != event.newIndex) {
        this.$http
          .patch(
            process.env.VUE_APP_ROOT_API + "task/change-order-of-tasktodo",
            {
              oldOrderIndex: event.oldIndex + 1,
              newOrderIndex: event.newIndex + 1,
              todo_id: event.item.id,
              taskid: this.$route.params.taskId,
            }
          )
          .then(() => {});
      }
    },
    addNewLabels() {
      var self = this;
      var postdata = {
        lable: this.lable,
        projectid: this.project.id,
        taskId: this.$route.params.taskId,
      };
      this.$validator.validateAll("add-new-lable").then((res) => {
        if (res) {
          self.$http
            .post(process.env.VUE_APP_ROOT_API + "project/add-labels", postdata)
            .then((res) => {
              self.$store.commit(
                "project/assignProjectLabels",
                res.data.project
              );
              self.assignlables(res.data.task.labels);
              self.tasklables = res.data.task.labels;
              self.AddprojectLablemenu = false;
              self.lable = "";
              self.$nextTick(() => {
                self.$validator.reset();
              });
            })
            .catch((error) => {
              if (error.response.data && error.response.data.message) {
                this.snackMessage = error.response.data.message;
                this.showSnackBar = true;
                this.messagecolor = "red";
              }
            });
        }
      });
    },
    addTaskLable(lable_id) {
      var self = this;
      var postdata = {
        taskid: this.$route.params.taskId,
        lableid: lable_id,
      };
      var labelindex = this.projectlable.findIndex(
        (data) => data.id == lable_id
      );
      this.tasklables.push(self.projectlable[labelindex]);
      if (labelindex != -1) {
        self.projectlable.splice(labelindex, 1);
      }
      this.$http
        .post(process.env.VUE_APP_ROOT_API + "task/add-task-labels", postdata)
        .then((res) => {
          self.tasklables = res.data.task.labels;
          self.assignlables(res.data.task.labels);
          self.lable = "";
          self.$store.commit("project/assignTaskLable", res.data.task);
          self.projectlable = res.data.task.labels;
        })
        .catch((error) => {
          if (error.response && error.response.data.message) {
            this.snackMessage = error.response.data.message;
            this.showSnackBar = true;
          }
        });
    },
    removelables(labelId) {
      return this.$http
        .delete(process.env.VUE_APP_ROOT_API + "task/remove-task-lable", {
          data: {
            taskid: this.$route.params.taskId,
            lable_id: labelId,
          },
        })
        .then((res) => {
          this.tasklables = res.data.task.labels;
          this.assignlables(res.data.task.labels);
          this.$store.commit("project/assignTaskLable", res.data);
        })
        .catch((error) => {
          if (error.response && error.response.data.message) {
            this.snackMessage = error.response.data.message;
            this.showSnackBar = true;
          }
        });
    },
    aftergetTask() {
      this.loading = false;
      this.estimate.hours = this.task.estimate.hours;
      this.estimate.minutes = this.task.estimate.minutes;
      this.selectedUsers = this.task.assignedToUsers
        ? this.task.assignedToUsers.map((val) => val._id)
        : [];
    },
    changeTaskStatus(item) {
      if (item.id != this.task.id) {
        this.$http
          .patch(
            process.env.VUE_APP_ROOT_API +
              "task/change-status/" +
              this.$route.params.taskId,
            {
              status: item.id,
            }
          )
          .then(() => {
            this.task.status = item.id;
            setTimeout(() => {
              bus.$emit("updatestatus", this.task);
            }, 1000);
            this.$store.commit("project/changesingletaskdata", {
              type: "status",
              task: this.task,
            });
            this.task.projectinfo = this.project;
          })
          .catch((error) => {
            if (error.response.data && error.response.data.message) {
              this.snackMessage = error.response.data.message;
              this.showSnackBar = true;
              this.messagecolor = "red";
            }
          });
      }
    },
    deleteTask() {
      if (this.disabledelete) {
        return;
      }
      this.disabledelete = true;
      var self = this;
      this.$http
        .delete(
          process.env.VUE_APP_ROOT_API +
            "task/delete/" +
            this.$route.params.taskId,
          {
            data: {
              id: this.task._id,
            },
          }
        )
        .then((res) => {
          var index = self.subtasks.findIndex(
            (data) => data.id == self.task._id
          );
          if (index != -1) {
            self.subtasks.splice(index, 1);
          }
          bus.$emit("deletetask", self.task);
          bus.$emit("getinboxnotification", this.task);
          if (res.data.setparentasnormal != "") {
            bus.$emit("changeparentonormal", res.data.setparentasnormal);
          }
          this.$store.dispatch("project/deleteTask", this.task);
          this.$store.commit(
            "workspace/updateTaskdeletecount",
            this.project.workspace
          );
          this.task.projectinfo = this.project;
          this.deleteConformDialog = false;
          self.createBreadCrumbs("closemodel");
          if (this.showTemplate || this.task.IssubtaskParent) {
            this.$router.replace({
              name: "single-project-tasks",
              params: {
                id: this.$route.params.id,
              },
              query: {
                companyId: this.$route.query.companyId,
              },
            });
          } else {
            self.$router.go(-1);
          }
          this.disabledelete = false;
        })
        .catch((error) => {
          this.disabledelete = false;
          if (error.response.data && error.response.data.message) {
            this.snackMessage = error.response.data.message;
            this.showSnackBar = true;
            this.messagecolor = "red";
          } else {
            setTimeout(() => {
              bus.$emit("deletetask", this.task);
            }, 1000);
            this.$store.dispatch("project/deleteTask", this.task);
            this.$store.commit(
              "workspace/updateTaskdeletecount",
              this.project.workspace
            );
            this.task.projectinfo = this.project;
            self.createBreadCrumbs("closemodel");
            if (this.showTemplate) {
              this.$router.replace({
                name: "single-project-tasks",
                params: {
                  id: this.$route.params.id,
                },
                query: {
                  companyId: this.$route.query.companyId,
                },
              });
            } else {
              self.$router.go(-1);
            }
          }
        });
    },
    changeTasktype(data) {
      this.$http
        .patch(
          process.env.VUE_APP_ROOT_API +
            "task/update-tasktype/" +
            this.$route.params.taskId,
          {
            type: data.id,
          }
        )
        .then((res) => {
          this.task.type = data.id;
          setTimeout(() => {
            bus.$emit("updatetasktype", res.data);
          }, 1000);
          this.$store.commit("project/changesingletaskdata", {
            type: "type",
            task: this.task,
          });
        });
    },
    changePriority(item) {
      this.$http
        .patch(
          process.env.VUE_APP_ROOT_API +
            "task/change-priority/" +
            this.$route.params.taskId,
          {
            priority: item.id,
          }
        )
        .then(() => {
          this.task.priority = item.id;
          setTimeout(() => {
            bus.$emit("updatepriority", this.task);
          }, 1000);
          this.$store.commit("project/changesingletaskdata", {
            type: "priority",
            task: this.task,
          });
          this.task.projectinfo = this.project;
        });
      // eslint-disable-next-line no-unused-vars
    },
    editTitle() {
      var self = this;
      this.oldTitle = this.task.title;
      this.showTitleInput = true;
      this.$nextTick(() => {
        self.$refs.editTitle.focus();
      });
    },
    saveTitle() {
      if (this.task.title == "") {
        this.$http
          .get(
            process.env.VUE_APP_ROOT_API +
              "task/get-taskinfo/" +
              this.$route.params.taskId
          )
          .then((res) => {
            this.task.title = res.data.title;
          });
      } else {
        if (this.oldTitle != this.task.title) {
          this.titleLoading = true;
          this.$http
            .patch(
              process.env.VUE_APP_ROOT_API +
                "task/update-title/" +
                this.$route.params.taskId,
              {
                title: this.task.title,
              }
            )
            // eslint-disable-next-line no-unused-vars
            .then(() => {
              this.showTitleInput = false;
              this.titleLoading = false;
              this.task.projectinfo = this.project;
              bus.$emit("updatetitle", this.task);
            })
            .catch((error) => {
              if (error.response.data && error.response.data.message) {
                this.snackMessage = error.response.data.message;
                this.showSnackBar = true;
                this.messagecolor = "red";
              }
            });
        } else {
          this.showTitleInput = false;
        }
      }
    },
    editDescription() {
      this.oldDescription = this.task.description;
      this.showDescriptionInput = true;
      this.$nextTick(() => {
        this.$refs.editDescription.focus();
      });
    },
    saveDescription() {
      if (this.oldDescription != this.task.description) {
        this.descriptionLoading = true;
        this.$http
          .patch(
            process.env.VUE_APP_ROOT_API +
              "task/update-description/" +
              this.$route.params.taskId,
            {
              description: this.task.description,
            }
          )
          // eslint-disable-next-line no-unused-vars
          .then(() => {
            this.showDescriptionInput = false;
            this.descriptionLoading = false;
          })
          .catch((error) => {
            if (error.response.data && error.response.data.message) {
              this.snackMessage = error.response.data.message;
              this.showSnackBar = true;
              this.messagecolor = "red";
            }
          });
      } else {
        this.showDescriptionInput = false;
      }
    },
    saveEstimate() {
      if (this.taskEstimate.hours == "" && this.taskEstimate.minutes == "") {
        this.snackMessage = "Hours or minutes field is required";
        this.showSnackBar = true;
      } else {
        this.$validator.validateAll("estimatetime").then((res) => {
          if (res) {
            this.$http
              .patch(
                process.env.VUE_APP_ROOT_API +
                  "task/update-estimate/" +
                  this.$route.params.taskId,
                {
                  hours: this.taskEstimate.hours,
                  minutes: this.taskEstimate.minutes,
                }
              )
              .then(() => {
                this.task.estimate.hours = this.taskEstimate.hours;
                this.task.estimate.minutes = this.taskEstimate.minutes;
                this.editEstimateMenu = false;
                this.cancelEstimate();
              })
              .catch((error) => {
                if (error.response.data && error.response.data.message) {
                  this.snackMessage = error.response.data.message;
                  this.showSnackBar = true;
                  this.messagecolor = "red";
                }
              });
          }
        });
      }
    },
    closeAssignUsersDialog() {
      this.assignUsersDialog = false;
      this.selectedUsers = this.task.assignedToUsers.map((val) => val._id);
    },
    async assignUsers() {
      if (this.btnLoading) {
        return;
      }
      this.btnLoading = true;
      var self = this;
      this.$http
        .patch(
          process.env.VUE_APP_ROOT_API +
            "task/update-assigned-users/" +
            this.$route.params.taskId,
          {
            users: this.selectedUsers,
          }
        )
        .then((response) => {
          if (response.data.timerRemovedstatus) {
            self.assignstoptimer(response.data.tasktimerstopinfo);
          }
          self.task.assignedToUsers = self.projectUsers.filter((val) =>
            self.selectedUsers.includes(val._id)
          );
          self.assignUsersDialog = false;
          self.btnLoading = false;
          var taskresponse = response.data.returntask;
          setTimeout(() => {
            bus.$emit("updatedAssignedUsers", response.data.returntask);
          }, 1000);
          self.task.assignedToUsers = response.data.returntask.assignedToUsers;
          self.$store.dispatch(
            "project/getProjectUsers",
            self.$route.params.id
          );
          taskresponse.returnuser = response.data.returntask.assignedToUsers;
          self.$store.commit(
            "project/Assignactionrequiredtousers",
            taskresponse
          );
        })
        .catch((error) => {
          this.btnLoading = false;
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            this.snackMessage = error.response.data.message;
            this.showSnackBar = true;
            this.messagecolor = "red";
          }
        });
    },
    async assignstoptimer(response) {
      var self = this;
      var timerindex = self.timespendedusers.findIndex(
        (data) => data.id == self.authuser.id
      );
      if (
        response &&
        response.alltimer &&
        response.alltimer.length > 0 &&
        response.lessthan1m == false
      ) {
        await self.resettimer(response);
        bus.$emit("timerTooltip", true);
        var spenttime = await self.getSingleUsertimer(
          response.alltimer,
          self.authuser
        );
        if (spenttime) {
          if (timerindex != -1) {
            self.timespendedusers[timerindex].spendtime = spenttime;
          } else {
            if (self.timespendedusers.length > 0) {
              self.timespendedusers.push({
                id: self.authuser.id,
                firstName: self.firstletterupercase(self.authuser.firstName),
                lastName: self.firstletterupercase(self.authuser.lastName),
                Profileimage: self.authuser.Profileimage,
                spendtime: spenttime,
              });
            } else {
              self.timespendedusers = [
                {
                  id: self.authuser.id,
                  firstName: self.firstletterupercase(self.authuser.firstName),
                  lastName: self.firstletterupercase(self.authuser.lastName),
                  Profileimage: self.authuser.Profileimage,
                  spendtime: spenttime,
                },
              ];
            }
          }
        }
      }
    },
    removeAssignedUserPopup(index, id) {
      if (id == this.task.actionRequiredBy) {
        this.snackMessage =
          "You can't remove action required person from the users directly, Please try after remove the user from the action required";
        this.showSnackBar = true;
      } else {
        this.dialog = true;
        this.dialogTitle =
          "Are you sure you want to remove this user from this task? Please note that any timer started by the user will be ended";
        this.removeAssignedUserIndex = index;
        this.removeAssignedUserId = id;
      }
    },
    async removeAssignedUser() {
      try {
        const response = await this.$http.delete(
          process.env.VUE_APP_ROOT_API +
            "task/remove-assigned-user/" +
            this.$route.params.taskId,
          {
            data: {
              userId: this.removeAssignedUserId,
            },
          }
        );
        var index = this.task.assignedToUsers.findIndex(
          (elem) => elem._id == this.removeAssignedUserId
        );
        if (index != -1) {
          this.task.assignedToUsers.splice(this.removeAssignedUserIndex, 1);
        }
        var getrunningtimerindex = this.userRunningtimer.findIndex(
          (data) => data.userInfo.id == this.removeAssignedUserId
        );
        if (getrunningtimerindex != -1) {
          this.userRunningtimer.splice(getrunningtimerindex, 1);
          await this.getalltimesofUsers(this.$route.params.taskId);
        }
        bus.$emit("updatedAssignedUsers", this.task);
        if (this.removeAssignedUserId === this.authuser.id) {
          await this.resettimer(response.data.tasktimerstopinfo);
        }
        await this.closeDeleteUserDialog();
        this.$store.dispatch("project/getProjectUsers", this.$route.params.id);
      } catch (error) {
        if (error.response.data && error.response.data.message) {
          this.snackMessage = error.response.data.message;
          this.showSnackBar = true;
          this.messagecolor = "red";
        }
      }
    },
    closeDeleteUserDialog() {
      this.dialog = false;
      this.dialogTitle = "";
      this.removeAssignedUserIndex = "";
      this.removeAssignedUserId = "";
    },
    getComments(taskId) {
      this.$http
        .get(process.env.VUE_APP_ROOT_API + "task/get-comments/" + taskId)
        .then((res) => {
          this.comments = res.data;
        });
    },
    attachTaskFiles(files) {
      var taskFiles = [];
      files.forEach((file) => {
        var typeOfFiles = [
          "video/mp4",
          "video/x-msvideo",
          "video/quicktime",
          "audio/ogg",
          "video/x-flv",
          "video/3gpp2",
          "video/ogg",
          "video/x-ms-wmv",
          "video/webm",
          "video/x-ms-asf",
          "video/x-matroska",
          "application/vnd.ms-asf",
          "video/avi",
          "video/msvideo",
          "video/x-msvideo/x-msvideovideo",
          "image/jpeg",
          "image/jpg",
          "image/png",
          "image/svg",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/pdf",
          "application/vnd.ms-powerpoint",
          "text/plain",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/msword",
          "application/vnd.ms-excel",
          "video/mp4",
        ];
        const allowedToUpload = typeOfFiles.includes(file.type);
        if (allowedToUpload) {
          taskFiles.push(file);
        }
      });
      if (taskFiles.length > 0) {
        var formData = new FormData();
        taskFiles.forEach((elem) => {
          formData.append("files", elem);
        });
        this.$http
          .patch(
            process.env.VUE_APP_ROOT_API +
              "task/upload-files-to-task/" +
              this.task.id,
            formData
          )
          .then((res) => {
            res.data.forEach((elem) => {
              this.task.attachments.push(elem);
            });
            this.$nextTick(() => {
              this.$validator.reset();
            });
          })
          .catch((error) => {
            if (error.response.data && error.response.data.message) {
              this.snackMessage = error.response.data.message;
              this.showSnackBar = true;
              this.messagecolor = "red";
            }
          });
      }
    },
    attachFile(files) {
      files.forEach((file) => {
        var typeOfFiles = [
          "video/mp4",
          "video/x-msvideo",
          "video/quicktime",
          "audio/ogg",
          "video/x-flv",
          "video/3gpp2",
          "video/ogg",
          "video/x-ms-wmv",
          "video/webm",
          "video/x-ms-asf",
          "video/x-matroska",
          "application/vnd.ms-asf",
          "video/avi",
          "video/msvideo",
          "video/x-msvideo/x-msvideovideo",
          "image/jpeg",
          "image/jpg",
          "image/png",
          "image/svg",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/pdf",
          "application/vnd.ms-powerpoint",
          "text/plain",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/msword",
          "application/vnd.ms-excel",
          "video/mp4",
        ];
        const typeOfFile = file.type.split("/")[0];
        const allowedToUpload = typeOfFiles.includes(file.type);
        if (allowedToUpload) {
          this.commentFiles.push(file);
          if (typeOfFile == "image") {
            this.previewFiles.push({
              type: "image",
              url: URL.createObjectURL(file),
            });
          } else {
            this.previewFiles.push({
              type: "file",
              name: file.name,
            });
          }
        }
      });
      this.taskfiles = [];
    },
    typingOnCommentField() {
      this.commentFilled = true;
    },
    postComment() {
      var self = this;
      if (this.$refs.tiptapPostComment.editor.getHTML() == "<p></p>") {
        this.commentFilled = false;
        return;
      }
      this.$validator.validateAll("commentscope").then((result) => {
        if (result) {
          var comment = this.$refs.tiptapPostComment.editor.getHTML();
          self.commentLoader = true;
          var formData = new FormData();
          formData.set("id", self.task.id);
          formData.set("comment", comment);
          var filetotalsize = 0;
          self.commentFiles.forEach((elem) => {
            filetotalsize = filetotalsize + elem.size;
            formData.append("files", elem);
          });
          if (filetotalsize < 50000000) {
            self.$http
              .post(
                process.env.VUE_APP_ROOT_API +
                  "task/add-comment/" +
                  self.$route.params.taskId,
                formData
              )
              .then(() => {
                self.$refs.tiptapPostComment.editor.clearContent();
                self.commentFiles = [];
                self.previewFiles = [];
                self.commentFilled = true;
                self.commentLoader = false;
                self.tiptapPostCommentcontent = "";
                self.$nextTick(() => {
                  self.$validator.reset();
                });
              })
              .catch((error) => {
                self.commentLoader = false;
                self.commentFilled = false;
                if (error.response.data && error.response.data.message) {
                  self.snackMessage = error.response.data.message;
                  self.showSnackBar = true;
                  self.messagecolor = "red";
                }
              });
          } else {
            self.snackMessage = "File size should be less than 50mb";
            self.showSnackBar = true;
            self.commentLoader = false;
          }
        } else {
          self.snackMessage = "Please add comment";
          self.showSnackBar = true;
        }
      });
    },
    deleteComment() {
      this.$http
        .delete(
          process.env.VUE_APP_ROOT_API +
            "task/delete-comment/" +
            this.deleteCommentId
        )
        .then(() => {
          var index = this.comments.findIndex(
            (elem) => elem.id == this.deleteCommentId
          );
          this.comments.splice(index, 1);
          this.closeDeleteCommentDialog();
        })
        .catch((error) => {
          if (error.response.data && error.response.data.message) {
            this.snackMessage = error.response.data.message;
            this.showSnackBar = true;
            this.messagecolor = "red";
          }
        });
    },
    editvalidation(message) {
      this.snackMessage = message;
      this.showSnackBar = true;
    },
    updatetimer(data) {
      var index = this.comments.findIndex(
        (elem) => elem.id == data.timer.command.id
      );
      if (index != -1) {
        if (data.type == "timer") {
          this.comments[index].timerinfo = data.timer.command.timerinfo;
          this.comments[index].commentTimerhistory =
            data.timer.command.commentTimerhistory;
          this.comments[index].commentFromUser =
            data.timer.command.commentFromUser;
        } else if (data.type == "report") {
          this.comments[index].reportedinfo = data.timer.command.reportedinfo;
          this.comments[index].commentTimerhistory =
            data.timer.command.commentTimerhistory;
          this.comments[index].commentFromUser =
            data.timer.command.commentFromUser;
        } else if (data.type == "delete") {
          this.comments.splice(index, 1);
        }
      }
      this.getalltimesofUsers(this.$route.params.taskId);
    },
    removeTaskFile() {
      this.$http
        .delete(
          process.env.VUE_APP_ROOT_API +
            "task/delete-attachment-from-task/" +
            this.task.id,
          {
            data: {
              fileId: this.deleteTaskFileId,
            },
          }
        )
        .then(() => {
          this.task.attachments.splice(this.deleteTaskFileId, 1);
          this.closeDeleteTaskFileDialog();
        })
        .catch((error) => {
          if (error.response.data && error.response.data.message) {
            this.snackMessage = error.response.data.message;
            this.showSnackBar = true;
            this.messagecolor = "red";
          }
        });
    },
    removeFile() {
      this.$http
        .delete(
          process.env.VUE_APP_ROOT_API +
            "task/delete-attachment-from-comment/" +
            this.deleteCommentId,
          {
            data: {
              fileId: this.deleteFileId,
            },
          }
        )
        .then(() => {
          var index = this.comments.findIndex(
            (elem) => elem.id == this.deleteCommentId
          );
          this.comments[index].attachments.splice(this.deleteFileIndex, 1);
          this.closeDeleteFileDialog();
        })
        .catch((error) => {
          if (error.response.data && error.response.data.message) {
            this.snackMessage = error.response.data.message;
            this.showSnackBar = true;
            this.messagecolor = "red";
          }
        });
    },
    async gettimerinformation(taskId) {
      var self = this;
      if (taskId) {
        const response = await this.$http.get(
          process.env.VUE_APP_ROOT_API + "mytime/get-task-timeinfo/" + taskId
        );
        for (const singleusertime of response.data.runningtimer) {
          if (singleusertime.startTime !== "") {
            var alluserelapsedtime =
              new Date().getTime() - parseInt(singleusertime.startTime);
            if (alluserelapsedtime) {
              var days = Math.floor(alluserelapsedtime / (1000 * 60 * 60 * 24));
              var years = Math.floor(days / 365);
              var hours = Math.floor(
                (alluserelapsedtime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
              );
              var minutes = Math.floor(
                (alluserelapsedtime % (1000 * 60 * 60)) / (1000 * 60)
              );
              var seconds = Math.floor(
                (alluserelapsedtime % (1000 * 60)) / 1000
              );
              singleusertime.runningduration = {
                years: years,
                days: days,
                hours: hours,
                minutes: minutes,
                seconds: seconds,
              };
            }
          }
        }
        self.userRunningtimer = response.data.runningtimer;
        if (response.data && response.data.recordwithemptyendtime) {
          self.recordwithemptyendtime = response.data.recordwithemptyendtime;
          await self.starttimer(response.data);
        }
        self.timeroftask = response.data.tasktimer;
        self.timershowstatus =
          response.data.recordwithemptyendtime != "" ? true : false;
        await self.getarrayoftimehistory(response.data);
        bus.$emit(
          "task-spend-time",
          self.getTotaltimer,
          self.$route.params.taskId
        );
        //  await this.$http
        //     .get(
        //       process.env.VUE_APP_ROOT_API + "mytime/get-task-timeinfo/" + taskId
        //     )
        //     .then((response) => {

        //     });
      }
    },
    async starttimer(timeroftask) {
      var self = this;
      if (timeroftask.recordwithemptyendtime != "" && self.interval == null) {
        self.interval = setInterval(async function printTime() {
          var elapsedtime =
            new Date().getTime() -
            parseInt(self.recordwithemptyendtime.startTime);
          if (elapsedtime) {
            await self.gettimefromseconds(elapsedtime);
          }
        }, 1000);
      }
    },
    async gettimefromseconds(secnods) {
      var temp_days = Math.floor(secnods / (1000 * 60 * 60 * 24));
      var temp_years = Math.floor(this.days / 365);
      var temp_hours = Math.floor(
        (secnods % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var temp_minutes = Math.floor((secnods % (1000 * 60 * 60)) / (1000 * 60));
      var temp_seconds = Math.floor((secnods % (1000 * 60)) / 1000);
      if (
        temp_days > -1 &&
        temp_years > -1 &&
        temp_hours > -1 &&
        temp_minutes > -1 &&
        temp_seconds > -1
      ) {
        this.timershowstatus = true;
        this.days = temp_days;
        this.years = temp_years;
        this.hours = temp_hours;
        this.minutes = temp_minutes;
        this.seconds = temp_seconds;
      }
    },
    async startTimerCounter() {
      var self = this;
      self.startstatus = true;
      if (
        self.task.assignedToUsers
          .map((data) => data._id)
          .includes(self.authuser.id)
      ) {
        await self.starttimer({
          recordwithemptyendtime: {
            createdAt: "",
            startTime: new Date().getTime(),
            endTime: "",
            taskId: "",
            updatedAt: "",
            userId: "",
          },
        });
        self.timershowstatus = true;
        self.recordwithemptyendtime = {
          createdAt: "",
          startTime: new Date().getTime(),
          endTime: "",
          taskId: "",
          updatedAt: "",
          userId: "",
        };
        await self.savestartimer();
      } else {
        if (
          self.task.assignedToUsers
            .map((data) => data._id)
            .includes(self.authuser.id) == false
        ) {
          self.snackMessage = "Your not assigned for this task";
          self.showSnackBar = true;
        }
      }
      self.startstatus = false;
    },
    async savestartimer() {
      var self = this;
      try {
        const response = self.$http.post(
          process.env.VUE_APP_ROOT_API +
            "mytime/start-task-timercount/" +
            self.$route.params.taskId,
          {
            timezone: self.timezonemoment.tz.guess(),
          }
        );

        if (response.data) {
          self.timeroftask = response.data.tasktimer;
          self.recordwithemptyendtime.id =
            response.data.recordwithemptyendtime.id;
          self.recordwithemptyendtime.createdAt =
            response.data.recordwithemptyendtime.createdAt;
          self.recordwithemptyendtime.endTime =
            response.data.recordwithemptyendtime.endTime;
          self.recordwithemptyendtime.taskId =
            response.data.recordwithemptyendtime.taskId;
          self.recordwithemptyendtime.updatedAt =
            response.data.recordwithemptyendtime.updatedAt;
          self.recordwithemptyendtime.userId =
            response.data.recordwithemptyendtime.userId;
          await self.getarrayoftimehistory(response.data);
        }
      } catch (error) {
        if (error.response.data && error.response.data.message) {
          this.snackMessage = error.response.data.message;
          this.showSnackBar = true;
          this.messagecolor = "red";
        }
      }
    },
    async stopAlltabTimer(response) {
      var self = this;
      await self.resettimer(response);
      var timerindex = self.timespendedusers.findIndex(
        (data) => data.id == self.authuser.id
      );
      if (
        response.alltimer &&
        response.alltimer.length > 0 &&
        response.lessthan1m == false
      ) {
        var spenttime = await this.getSingleUsertimer(
          response.alltimer,
          self.authuser
        );
        if (spenttime != undefined && spenttime != "") {
          if (timerindex != -1) {
            (self.timespendedusers[timerindex].id = self.authuser.id),
              (self.timespendedusers[timerindex].spendtime = spenttime);
          } else {
            if (self.timespendedusers.length > 0) {
              self.timespendedusers.push({
                id: self.authuser.id,
                firstName: self.firstletterupercase(self.authuser.firstName),
                lastName: self.firstletterupercase(self.authuser.lastName),
                Profileimage: self.authuser.Profileimage,
                spendtime: spenttime,
              });
            } else {
              self.timespendedusers = [
                {
                  id: self.authuser.id,
                  firstName: self.firstletterupercase(self.authuser.firstName),
                  lastName: self.firstletterupercase(self.authuser.lastName),
                  Profileimage: self.authuser.Profileimage,
                  spendtime: spenttime,
                },
              ];
            }
          }
        }
      }
    },
    async StopTimeCounter(item) {
      if (
        this.timezonemoment.tz.guess() != item.startTimezone &&
        this.confirmstatus == false
      ) {
        this.StopTimeCounterinfo = item;
        this.confirmdiffertimezone = true;
      } else {
        var self = this;
        this.stopstatus = true;
        if (item.id) {
          const status = await this.$http.get(
            process.env.VUE_APP_ROOT_API +
              "task/checktimerpresetedornot/" +
              self.$route.params.taskId +
              "/" +
              item.id
          );
          if (status.data.status == true) {
            self.timershowstatus = false;
            self.timerclickedstatus == false;
            var postdata = {
              tasktimerecord_id: item.id,
            };
            const response = await this.$http.post(
              process.env.VUE_APP_ROOT_API +
                "mytime/cancel-mytime-daytask/" +
                this.$route.params.taskId,
              postdata
            );
            await self.resettimer(response.data);
            if (
              response.data.alltimer &&
              response.data.alltimer.length > 0 &&
              response.data.lessthan1m == false
            ) {
              var timerindex = self.timespendedusers.findIndex(
                (data) => data.id == self.authuser.id
              );
              bus.$emit("timerTooltip", true);
              var spenttime = await this.getSingleUsertimer(
                response.data.alltimer,
                this.authuser
              );
              if (spenttime) {
                if (timerindex != -1) {
                  this.timespendedusers[timerindex].spendtime = spenttime;
                } else {
                  if (this.timespendedusers.length > 0) {
                    this.timespendedusers.push({
                      id: this.authuser.id,
                      firstName: this.firstletterupercase(
                        this.authuser.firstName
                      ),
                      lastName: this.firstletterupercase(
                        this.authuser.lastName
                      ),
                      Profileimage: this.authuser.Profileimage,
                      spendtime: spenttime,
                    });
                  } else {
                    this.timespendedusers = [
                      {
                        id: this.authuser.id,
                        firstName: this.firstletterupercase(
                          this.authuser.firstName
                        ),
                        lastName: this.firstletterupercase(
                          this.authuser.lastName
                        ),
                        Profileimage: this.authuser.Profileimage,
                        spendtime: spenttime,
                      },
                    ];
                  }
                }
              }
            }
          }
        }
        this.stopstatus = false;
        this.getalltimesofUsers(this.$route.params.taskId);
        this.StopTimeCounterinfo = "";
      }
    },
    async resettimer(data) {
      this.interval = "";
      this.timeroftask = data ? data.tasktimer : "";
      this.recordwithemptyendtime = data ? data.recordwithemptyendtime : "";
      await this.starttimer(data);
      this.timershowstatus = false;
      await this.getarrayoftimehistory(data);
      this.days = "";
      ``;
      this.years = "";
      this.hours = "";
      this.minutes = "";
      this.seconds = "";
    },
    makemeptydates() {
      this.timershowstatus = false;
      this.days = "";
      this.years = "";
      this.hours = "";
      this.minutes = "";
      this.seconds = "";
    },
    async getarrayoftimehistory(timeroftask) {
      if (timeroftask.tasktimer && timeroftask.tasktimer.length > 0) {
        var spendedhours = [];
        timeroftask.tasktimer.forEach((times) => {
          if (times.endTime && times.startTime) {
            var spendedtime = times.endTime - times.startTime;
            var day = "";
            var hour = "";
            var minute = "";
            var days = Math.floor(spendedtime / (1000 * 60 * 60 * 24));
            var hours = Math.floor(
              (spendedtime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            var minutes = Math.floor(
              (spendedtime % (1000 * 60 * 60)) / (1000 * 60)
            );
            if (days != 0) {
              day = days + " day  ";
            }
            if (hours != 0) {
              hour = hours + " hr ";
            }
            if (minutes != 0) {
              minute = minutes + " min ";
            }
            if (days != 0 || hours != 0 || minutes != 0) {
              spendedhours.push(day + " " + hour + " " + minute);
            }
          }
        });
        this.spendedhours = spendedhours;
      } else {
        this.spendedhours = [];
      }
    },
    updateDuedate(item) {
      var self = this;
      this.$http
        .patch(
          process.env.VUE_APP_ROOT_API +
            "task/update-task-enddate/" +
            this.$route.params.taskId,
          {
            endDate: item.endDate,
          }
        )
        .then((response) => {
          self.task.endDate = response.data.endDate;
          self.enddatemenu = false;
        });
    },
    removePreview(index) {
      this.previewFiles.splice(index, 1);
      this.commentFiles.splice(index, 1);
    },
    openRemoveFileDialog(commentId, file, index) {
      this.deleteFileDialog = true;
      this.deleteFileIndex = index;
      this.deleteFileId = file.id;
      this.deleteCommentId = commentId;
    },
    openRemoveCommentDialog(commentId) {
      this.deleteCommentDialog = true;
      this.deleteCommentId = commentId;
    },
    commitattachements(attachements) {
      this.task.attachments = attachements;
    },
    closeDeleteFileDialog() {
      this.deleteFileDialog = false;
      this.deleteFileIndex = "";
      this.deleteFileId = "";
      this.deleteCommentId = "";
    },
    closeDeleteTaskFileDialog() {
      this.deleteTaskFileDialog = false;
      this.deleteTaskFileIndex = "";
      this.deleteTaskFileId = "";
    },
    closeDeleteCommentDialog() {
      this.deleteCommentDialog = false;
      this.deleteCommentId = "";
    },
  },
  components: {
    TaskAttachements,
    TiptapVuetify,
    confirmation,
    comment,
    BreadCrumbs,
    draggable,
    addTask,
    subtasks,
    userinfo,
  },
};
</script>
<style>
@media (max-width: 959px) {
  .popup-task-dialog .v-dialog {
    margin: 0 !important;
    top: 25px;
    position: relative;
  }

  .test-link {
    right: 65px;
    top: 13px;
  }
}
</style>
<style lang="scss">
.pointercursor,
.assigned-user {
  cursor: pointer;
}

body.popup-task-dialog {
  z-index: 999 !important;
}

.pined-task .v-icon {
  font-size: 20px !important;
}

.hideheader-divider {
  display: none;
}

.running-user .v-expansion-panel-header {
  min-height: 45px;
}

.running-user .v-expansion-panel-content__wrap .running-expansion:last-child {
  border-bottom: 0;
}

.running-expansion {
  border-bottom: 1px solid #ccc;
}

.date-width {
  width: 100%;
}

h6.completedtodos {
  color: #afb0b2;
}

.todoName::first-letter {
  text-transform: uppercase;
}

.setting-icon-set {
  position: relative;
}

.setting-icon-button {
  position: absolute;
  right: -5px;
  top: 0em;
}

.todoowner {
  font-family: Arial;
  font-size: 10px;
}

.setting-menu-icon {
  width: 180px;
}

div.v-dialog::-webkit-scrollbar {
  width: 6px;
  padding: 0px;
  border-radius: 6px;
}

div.v-dialog::-webkit-scrollbar-thumb {
  min-height: 50px;
  background-color: #888;
  height: 50px;
  border-radius: 6px;
}

// .v-timeline.timer-task-timeline {
//   padding-top: 5px;
// }

div.v-dialog::-webkit-scrollbar-track {
  background: #e3e3e3;
  border-radius: 6px;
}

.calendar-view-position {
  position: relative;
  left: 8em;
}

.calendar-view-popup {
  position: relative;
  left: 4em;
}

.popup-scroll-section {
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
    opacity: 0;
    border-radius: 6px;
  }
}

.timer-task-timeline .v-timeline-item {
  padding-bottom: 0 !important;
}

.popup-scroll-section:hover {
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 6px;
    opacity: 1;
  }

  &::-webkit-scrollbar-thumb {
    min-height: 50px;
    background-color: #888;
    height: 50px;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #e3e3e3;
    border-radius: 6px;
  }
}

@media (hover: none) {
  .scrollbox {
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #333;
    }
  }
}

.background-lite {
  background-color: #ffffff;
}

.background-dark {
  background-color: #333333;
}

.subTasks-panel .v-expansion-panel-content__wrap {
  padding: 5px !important;
}

blockquote {
  border-left: 3px solid rgba(#0d0d0d, 0.1);
  padding-left: 1rem;
}

.tiptap-vuetify-editor .ProseMirror {
  height: auto !important;
  cursor: text !important;
  min-height: 120px;
}

.view-type {
  width: 150px;
}

.white-link {
  color: #fff;
}

.other-tasks {
  position: absolute;
  height: 100%;
  overflow: auto;
  border-right: 1px solid #efefef;

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $light-blue;
  }
}

.assigned-user {
  overflow: unset;
  position: relative;
  top: 0;
  right: 5px;

  .remove-user {
    position: absolute;
    right: -5px;
    bottom: 0px;
    background: #000000;
    color: #333;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
}

.todo-wrap {
  word-break: break-word;
}

.add-file {
  .v-input {
    justify-content: center;
  }
}

.time-spent-border {
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 10px;
}

.comment-file-icon {
  font-size: 120px !important;
  height: 150px;
}

.attachments::v-deep,
#comments::v-deep {
  .delete-preview-button {
    right: -10px;
    top: -10px;
  }

  .add-file {
    .v-input {
      justify-content: center;
    }
  }
}

.post-comment::v-deep {
  .post-comment-box {
    &.error .v-card {
      border-color: #ff5252;
    }

    .v-card {
      overflow-y: auto;
      height: 180px;
    }
  }
}

.scrollview {
  height: 250px;
  width: 277px;
}

.hide-drop-style input {
  display: none;
}

.tiptap-vuetify-editor__toolbar header.theme--dark.v-toolbar {
  background-color: #000000 !important;
}

.first-character::first-letter {
  text-transform: uppercase;
}

.popup-task-section .v-text-field {
  width: 100%;
}

.theme--dark .tiptap-vuetify-editor__content p {
  color: #ffffff !important;
}

.theme--light .tiptap-vuetify-editor__content p {
  color: #000000 !important;
}

.theme--light .setting-menu-icon:hover {
  background-color: #f7f7f7;
}

.theme--dark .setting-menu-icon:hover {
  background-color: #333333;
}

.grey-tag {
  color: #333333;
}

.running-user .v-expansion-panel-content__wrap {
  padding: 5px;
}

// .timer-task-timeline .v-timeline-item {
//   padding-top: 20px;
//   padding-bottom: 0;
// }

// .timer-task-timeline .v-timeline-item__divider {
//   align-items: start;
//   justify-content: start;
// }

// .timer-task-timeline.v-timeline::before {
//   left: calc(12px - 1px) !important;
// }

@media (max-width: 767px) {
  .scrollview {
    height: auto !important;
    width: auto !important;
  }

  button.setting-icon-button {
    top: -10px;
    right: 0px;
  }

  .comment-file-icon {
    font-size: 65px !important;
    height: 100px;
    width: 100px;
  }

  .comment-file-icon .v-image__image--cover {
    background-size: contain;
  }

  .test-link {
    right: 47px;
    top: 13px;
  }

  // .mobile-single-task {
  //   position: relative;
  //   top: 4em;
  //   left: 0;
  // }
}
</style>
