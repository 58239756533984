<template>
  <div
    class="fill-height pa-0 ml-0"
    :class="[buttontext != 'Create Subtask' ? 'text-center' : '']"
  >
    <div>
      <div v-if="showbuttonstatus == false">
        <div v-if="view != 'calendar'">
          <v-tooltip bottom v-if="buttontext != 'Create Subtask'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                v-if="weekviewbutton == true"
                @click="createDialogOpen()"
                class="secondary white--text rounded-pill"
                >add</v-icon
              >
              <v-btn
                v-bind="attrs"
                v-on="on"
                v-else
                outlined
                @click="createDialogOpen()"
                class="pa-2"
                :class="{
                  'primary--text': !$vuetify.theme.dark,
                  white: $vuetify.theme.dark,
                }"
              >
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <span>{{ buttontext }}</span>
          </v-tooltip>
          <div v-else @click="createDialogOpen()">Create Subtask</div>
        </div>
      </div>
      <v-dialog
        v-model="createDialog"
        @keydown="(event) => checksubmitevent(event)"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title class="pb-0">
            <div class="horizontal-dividing-header mb-0">
              <h5 class="header text-uppercase text-h6 ma-0">
                {{ buttontext }}
              </h5>
            </div>
          </v-card-title>
          <v-card-text class="pa-2 pa-sm-3 pa-md-3">
            <v-row v-if="parentask">
              <v-col class="d-flex align-center">
                <div class="text-body-2 pr-2 font-weight-medium">
                  Parent Task:
                </div>
                <v-chip>
                  <v-icon v-if="projectobject.task_types" small class="pr-1">
                    {{
                      projectobject.task_types.find(
                        (data) => data.id == parentask.type
                      ).icon
                    }}</v-icon
                  >
                  <p class="mb-0 text-truncate">
                    {{ parentask.title }}
                  </p></v-chip
                ></v-col
              >
            </v-row>
            <div>
              <v-row v-if="global == false">
                <input
                  v-if="global == false && computedworkspace"
                  type="hidden"
                  name="workspace"
                  v-model="workspace"
                />
                <input
                  v-if="global == false && computedworkspace && computedproject"
                  type="hidden"
                  name="project"
                  v-model="project"
                />
                <v-col
                  v-else-if="
                    global == false && computedworkspace && !computedproject
                  "
                >
                  <v-select
                    :error-messages="errors.collect('create-task.project')"
                    v-model="project"
                    :items="projectarray"
                    item-text="name"
                    item-value="id"
                    outlined
                    attach
                    data-vv-name="project"
                    data-vv-scope="create-task"
                    label="Project"
                    :menu-props="{ closeOnContentClick: true }"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item
                        :input-value="item.id == project"
                        @click="selectproject(item)"
                      >
                        <span class="text-body-2">{{ item.name }}</span>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
                <v-col v-else>
                  <v-select
                    :error-messages="errors.collect('create-task.project')"
                    v-model="project"
                    :items="projectarray"
                    item-text="name"
                    item-value="id"
                    outlined
                    attach
                    data-vv-name="project"
                    data-vv-scope="create-task"
                    label="Project"
                    :menu-props="{ closeOnContentClick: true }"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item
                        :input-value="item.id == project"
                        @click="selectproject(item)"
                      >
                        <span class="text-body-2">{{ item.name }}</span>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12" md="6" sm="6">
                  <v-select
                    v-model="workspace"
                    :items="workspaces"
                    item-text="name"
                    data-vv-name="workspace"
                    data-vv-as="workspace"
                    data-vv-scope="create-task"
                    item-value="id"
                    outlined
                    dense
                    attach
                    label="Workspace"
                    :error-messages="errors.collect('create-task.workspace')"
                    v-validate="'required'"
                    :menu-props="{ closeOnContentClick: true }"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item
                        :input-value="item.id == workspace"
                        @click="selectworkspace(item)"
                      >
                        <span class="text-body-2 word-break pt-1 pb-1">{{
                          item.name
                        }}</span>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" md="6" sm="6">
                  <v-select
                    :error-messages="errors.collect('create-task.project')"
                    v-model="project"
                    :items="projectarray"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    attach
                    data-vv-name="project"
                    data-vv-as="project"
                    data-vv-scope="create-task"
                    label="Project"
                    v-validate="'required'"
                    :menu-props="{ closeOnContentClick: true }"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item
                        :input-value="item.id == project"
                        @click="selectproject(item)"
                      >
                        <span class="text-body-2">{{ item.name }}</span>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </div>
            <v-row class="mt-0 mb-1">
              <v-col cols="12">
                <v-text-field
                  label="Title*"
                  :error-messages="errors.collect('create-task.task name')"
                  data-vv-name="task name"
                  data-vv-scope="create-task"
                  v-model="taskName"
                  v-validate="'required|max:255'"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0 mb-1">
              <v-col cols="12">
                <v-textarea
                  label="Description"
                  data-vv-scope="create-task"
                  v-model="taskDescription"
                  rows="2"
                  outlined
                  dense
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" sm="6">
                <v-menu
                  ref="startdatemenu"
                  :close-on-content-click="false"
                  :close-on-click="false"
                  v-model="startdatemenu"
                  :return-value.sync="startDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      label="Start Date"
                      append-icon="event"
                      readonly
                      v-on="on"
                      :value="computedStartDate"
                      :error-messages="errors.collect('create-task.start date')"
                      v-validate="endDate != '' ? 'required' : ''"
                      data-vv-name="start date"
                      data-vv-as="start date"
                      data-vv-scope="create-task"
                      outlined
                      dense
                      class="dateinput"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="startDate" no-title scrollable>
                    <div class="flex-grow-1"></div>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.startdatemenu.save('');
                        startdatemenu = false;
                      "
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="
                        startDate != ''
                          ? $refs.startdatemenu.save(startDate)
                          : ''
                      "
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-menu
                  ref="enddatemenu"
                  v-model="enddatemenu"
                  :close-on-content-click="false"
                  :close-on-click="false"
                  :return-value.sync="endDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      label="End Date"
                      append-icon="event"
                      readonly
                      v-on="on"
                      class="dateinput"
                      :value="computedEndDate"
                      v-validate="startDate != '' ? 'required' : ''"
                      :error-messages="errors.collect('create-task.end date')"
                      data-vv-name="end date"
                      data-vv-as="end date"
                      data-vv-scope="create-task"
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    :min="startDate"
                    no-title
                    scrollable
                  >
                    <div class="flex-grow-1"></div>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.enddatemenu.save('');
                        enddatemenu = false;
                      "
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="
                        endDate != '' ? $refs.enddatemenu.save(endDate) : ''
                      "
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" sm="6">
                <v-select
                  :disabled="!projectobject"
                  v-model="selectedUsers"
                  :items="computedprojectObject"
                  attach
                  label="Add users"
                  item-text="firstName"
                  :deletable-chips="true"
                  item-value="user"
                  class="add-users-lists"
                  small-chips
                  multiple
                  dense
                  outlined
                  solo
                  chips
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-select
                  class="pr-0 pr-sm-0 pr-md-0 action-required-req"
                  :disabled="!projectobject"
                  v-model="actionRequiredBy"
                  :items="computedprojectObject"
                  label="Action required to"
                  item-text="firstName"
                  :deletable-chips="true"
                  item-value="user"
                  outlined
                  dense
                  solo
                  attach
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="d-flex justify-space-between align-top">
                  <v-subheader class="text-uppercase pl-0"
                    >Estimate:</v-subheader
                  >
                  <v-text-field
                    class="mr-2"
                    label="Hr"
                    v-validate="'hrzeorvalidation'"
                    data-vv-name="hours"
                    data-vv-as="hours"
                    data-vv-scope="create-task"
                    :error-messages="errors.collect('create-task.hours')"
                    outlined
                    dense
                    style="width: 120px"
                    type="number"
                    min="0"
                    v-model="hours"
                  ></v-text-field>
                  <v-text-field
                    label="Min"
                    outlined
                    dense
                    min="0"
                    :error-messages="errors.collect('create-task.minutes')"
                    v-validate="'minutevalidation'"
                    style="width: 120px"
                    type="number"
                    data-vv-as="minutes"
                    data-vv-name="minutes"
                    data-vv-scope="create-task"
                    v-model="minutes"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="project">
              <v-col class="pt-0 pb-0">
                <div
                  class="d-flex justify-space-between align-top radio-button-section"
                >
                  <v-radio-group row v-model="tasktype" style="width: 100%">
                    <v-col
                      cols="6"
                      sm="4"
                      md="4"
                      v-for="taskType in getActiveitem(
                        projectobject.task_types
                      )"
                      :key="taskType.id"
                      class="pl-0"
                    >
                      <div>
                        <v-row>
                          <v-col cols="2" class="pa-0">
                            <v-radio
                              :value="taskType.id"
                              class="v-list__group__header__prepend-icon"
                            >
                            </v-radio>
                          </v-col>
                          <v-col cols="10" class="pa-0 pl-2">
                            <v-tooltip
                              bottom
                              :max-width="250"
                              v-if="taskType.name.length > 14"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  class="d-flex pt-1 align-start"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon small>{{ taskType.icon }}</v-icon>
                                  <p class="pl-1 mb-0 text-truncate">
                                    {{ taskType.name }}
                                  </p>
                                </div>
                              </template>
                              <p class="mb-0 first-character">
                                {{ taskType.name }}
                              </p>
                            </v-tooltip>
                            <div v-if="taskType.name.length <= 14">
                              <p class="mb-0 first-character">
                                {{ taskType.name }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-radio-group>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" sm="6">
                <v-select
                  v-model="status"
                  :items="getActiveitem(projectobject.statuses)"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  label="Status"
                  :menu-props="{ closeOnContentClick: true }"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item
                      :input-value="item.id == status"
                      @click="changeStatus(item)"
                    >
                      <span class="text-body-2 word-break">{{
                        item.name
                      }}</span>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-select
                  v-model="priority"
                  :items="getActiveitem(projectobject.priorities)"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  label="Priority"
                  :menu-props="{ closeOnContentClick: true }"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item
                      :input-value="item.id == priority"
                      @click="changePriority(item)"
                    >
                      <span class="text-body-2 word-break">{{
                        item.name
                      }}</span>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="12">
                <div
                  v-if="
                    projectobject.customFields &&
                    projectobject.customFields.length > 0
                  "
                >
                  <h5
                    class="text-capitalize text-uppercase text-body-2 ma-0 pb-2"
                  >
                    custom fields
                  </h5>
                  <div
                    v-for="customfield in projectobject.customFields"
                    :key="customfield.id"
                  >
                    <v-row>
                      <v-col class="pa-0 pb-2">
                        <v-row v-if="customfield.inputType == 'date'">
                          <v-col cols="12" class="pa-0 pb-2">
                            <div v-if="customfield.requiredStatus == true">
                              <v-menu
                                v-model="customfield.datepopupstatus"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="customfield.value"
                                    :label="customfield.name"
                                    append-icon="event"
                                    readonly
                                    outlined
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                    v-validate="'required'"
                                    :data-vv-name="customfield.name"
                                    :error-messages="
                                      errors.collect(
                                        'create-task.' + customfield.name
                                      )
                                    "
                                    :data-vv-as="customfield.name"
                                    data-vv-scope="create-task"
                                    class="dateinput"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="customfield.value"
                                  @input="customfield.datepopupstatus = false"
                                >
                                </v-date-picker>
                              </v-menu>
                            </div>
                            <div v-else>
                              <v-menu
                                v-model="customfield.datepopupstatus"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="customfield.value"
                                    :label="customfield.name"
                                    append-icon="event"
                                    readonly
                                    dense
                                    outlined
                                    v-bind="attrs"
                                    v-on="on"
                                    class="dateinput"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="customfield.value"
                                  @input="customfield.datepopupstatus = false"
                                >
                                </v-date-picker>
                              </v-menu>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row v-if="customfield.inputType == 'dropdown'">
                          <v-col cols="12" class="pa-0 pb-2">
                            <div v-if="customfield.requiredStatus == true">
                              <v-select
                                v-model="customfield.value"
                                :items="customfield.dropdownAttributes"
                                item-text="name"
                                item-value="name"
                                outlined
                                dense
                                :label="customfield.name"
                                :menu-props="{
                                  top: true,
                                  offsetY: true,
                                  closeOnContentClick: true,
                                }"
                                v-validate="'required'"
                                :data-vv-as="customfield.name"
                                :error-messages="
                                  errors.collect(
                                    'create-task.' + customfield.name
                                  )
                                "
                                :data-vv-name="customfield.name"
                                data-vv-scope="create-task"
                                class="ml-0 custom-field-dropdown"
                              >
                                <template v-slot:item="{ item }">
                                  <div
                                    class="text-body-2 v-list-item v-list-item--link"
                                    @click="
                                      assigntaskcustomfield(customfield, item)
                                    "
                                  >
                                    {{ item.name }}
                                  </div>
                                  <v-list-item
                                    :input-value="
                                      item.name == customfield.value
                                    "
                                    style="display: none"
                                  >
                                  </v-list-item>
                                </template>
                                <template v-slot:selection="{ item }">
                                  <span>{{ item.name }}</span>
                                </template>
                              </v-select>
                            </div>
                            <div v-else>
                              <v-select
                                v-model="customfield.value"
                                :items="customfield.dropdownAttributes"
                                item-text="name"
                                item-value="name"
                                outlined
                                dense
                                :label="customfield.name"
                                :menu-props="{
                                  top: true,
                                  offsetY: true,
                                  closeOnContentClick: true,
                                }"
                                class="ml-0"
                              >
                                <template v-slot:item="{ item }">
                                  <div
                                    class="text-body-2 v-list-item v-list-item--link"
                                    @click="
                                      assigntaskcustomfield(customfield, item)
                                    "
                                  >
                                    {{ item.name }}
                                  </div>
                                  <v-list-item
                                    :input-value="
                                      item.name == customfield.value
                                    "
                                    style="display: none"
                                  >
                                  </v-list-item>
                                </template>
                                <template v-slot:selection="{ item }">
                                  <span>{{ item.name }}</span>
                                </template>
                              </v-select>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row v-if="customfield.inputType == 'text'">
                          <v-col cols="12" class="pa-0 pb-2">
                            <div v-if="customfield.requiredStatus == true">
                              <v-text-field
                                :label="customfield.name"
                                outlined
                                dense
                                v-model="customfield.value"
                                v-validate="'required'"
                                :data-vv-as="customfield.name"
                                :error-messages="
                                  errors.collect(
                                    'create-task.' + customfield.name
                                  )
                                "
                                :data-vv-name="customfield.name"
                                data-vv-scope="create-task"
                              ></v-text-field>
                            </div>
                            <div v-else>
                              <v-text-field
                                :label="customfield.name"
                                outlined
                                dense
                                v-model="customfield.value"
                              >
                              </v-text-field>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-col>
            </v-row>
            <div class="black--text text-body-2 pl-4 pl-sm-0 pl-md-0 pb-2">
              * Indicates required field
            </div>
          </v-card-text>
          <v-card-actions class="add-task-sticky sticky-bar elevation-4">
            <div class="flex-grow-1"></div>
            <v-btn
              color="red"
              :disabled="submitdisablestatus"
              text
              @click="closeCreateDialog"
              >Close</v-btn
            >
            <v-btn
              :disabled="submitdisablestatus"
              :class="{
                'primary white--text': !$vuetify.theme.dark,
                'primary black--text': $vuetify.theme.dark,
              }"
              tile
              contained
              text
              @click="addTask"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-snackbar
      v-if="this.$route.name !== 'single-task'"
      v-model="showSnackBar"
      :bottom="true"
      :color="messagecolor"
      :timeout="timeout"
    >
      <div class="pb-2 text-body-1">{{ snackMessage }}</div>
      <v-btn
        :class="{
          'white black--text': !$vuetify.theme.dark,
          'black white--text': $vuetify.theme.dark,
        }"
        @click="gototask"
        small
        >View task</v-btn
      >
    </v-snackbar>
  </div>
</template>
<script>
import moment from "moment";
import { bus } from "./../../../main";
/* eslint-disable vue/no-side-effects-in-computed-properties */
export default {
  props: {
    subtask: {
      required: false,
      type: Boolean,
    },
    parentask: {
      required: false,
      type: Object,
    },
    buttontext: {
      required: false,
      type: String,
    },
    global: {
      required: false,
      type: Boolean,
    },
    weekviewbutton: {
      required: false,
      type: Boolean,
    },
    boxtaskcreate: {
      required: false,
      type: Boolean,
    },
    myworktask: {
      required: false,
      type: Boolean,
    },
    dates: {
      required: false,
      type: String,
    },
    ParamWorkspace: {
      required: false,
      type: String,
    },
    ParamProject: {
      required: false,
      type: String,
    },
    Paramstatus: {
      required: false,
      type: String,
    },
    view: {
      required: false,
      type: String,
    },
    calendardate: {
      required: false,
      type: String,
    },
    showbuttonstatus: {
      required: false,
      type: Boolean,
      default: false,
    },
    singlesubtaskaddbutton: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    submitdisablestatus: false,
    projectchangestaus:false,
    moment: moment,
    lastaddedtask: {
      name: "",
      project: "",
      taskid: "",
    },
    showSnackBar: false,
    timeout: 3000,
    snackMessage: "",
    messagecolor: "",
    newtasktitle: "",
    actionRequiredBy: "",
    addtask: {
      statusid: "",
      status: false,
    },
    subtaskparentId: "",
    activeinput: false,
    startDate: "",
    endDate: "",
    startdatemenu: false,
    enddatemenu: false,
    projectobject: {},
    projectarray: [],
    createDialog: false,
    workspace: "",
    project: "",
    taskName: "",
    taskDescription: "",
    hours: "",
    minutes: "",
    tasktype: "",
    priority: "",
    status: "",
    startEndDate: [],
    selectedUsers: [],
    taskcustomfields: [],
    customfielddate: moment().format("YYYY-MM-DD"),
    customfielddatemenu: false,
    minStartDate: moment().format("YYYY-MM-DD"),
    minEndDate: moment().format("YYYY-MM-DD"),
  }),
  mounted() {
    var regex = /^[1-9]\d*$/;
    var self = this;
    this.$validator.extend("hrzeorvalidation", {
      getMessage: (field) => "This " + field + " already exists.",
      validate: (value) => {
        var returndata = true;
        if (
          (value == "" || value == 0) &&
          (self.minutes == "" || self.minutes == 0)
        ) {
          returndata = false;
        } else {
          if (
            regex.test(value) == false &&
            (self.minutes == "" || self.minutes == 0)
          ) {
            returndata = false;
          }
        }
        return returndata;
      },
    });
    this.$validator.extend("minutevalidation", {
      getMessage: (field) => "This " + field + " already exists.",
      validate: (value) => {
        var returndata = true;
        if (
          (self.hours == "" || self.hours == 0) &&
          (value == "" || value == 0)
        ) {
          returndata = false;
        } else {
          if (regex.test(self.hours) == false && regex.test(value) == false) {
            returndata = false;
          }
        }
        return returndata;
      },
    });
  },
  watch: {
    dates(val) {
      this.startDate(val);
    },
    startDate(val) {
      this.minEndDate = val;
      if (this.endDate) {
        var startDate = new Date(val);
        var endDate = new Date(this.endDate);
        if (endDate.getTime() < startDate.getTime()) {
          this.endDate = val;
        }
      }
    },
    showbuttonstatus(data) {
      if (data == true) {
        this.startDate = "";
        this.endDate = "";
        this.dateitem = "";
        this.taskName = "";
        this.taskDescription = "";
        this.hours = "";
        this.minutes = "";
        this.actionRequiredBy = "";
        this.workspace = "";
        this.taskcustomfields = [];
        if (
          this.projectobject.customFields &&
          this.projectobject.customFields.length > 0
        ) {
          for (const taskcustomfield of this.projectobject.customFields) {
            taskcustomfield.value = "";
          }
        }
        this.$nextTick(() => {
          this.$validator.reset();
        });

        this.createDialogOpen();
      }
    },
  },
  computed: {
    computedprojectObject() {
      var returnproject = [];
      if (this.projectobject && this.projectobject.assignedToUsers) {
        if (this.projectobject.assignedToUsers.length > 0) {
          returnproject = this.projectobject.assignedToUsers.filter(
            (element) => element.isActive
          );
        }
      }
      var firstletterupercase = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
      };
      for (var singleuser of returnproject) {
        singleuser.firstName = firstletterupercase(singleuser.firstName);
        singleuser.lastName = firstletterupercase(singleuser.lastName);
      }
      return returnproject;
    },
    computedworkspace() {
      this.projectarray = this.$store.state.mywork.userprojects;
      this.workspace = this.ParamWorkspace ? this.ParamWorkspace : "";
      return this.workspace;
    },
    computedproject() {
      var self = this;
      var singleprojectitem = {};
      var singleprojectitemindex = this.projectarray.findIndex(
        (data) => data.id == this.ParamProject
      );
      if (singleprojectitemindex > -1) {
        singleprojectitem = this.projectarray[singleprojectitemindex];
        self.tasktype = singleprojectitem.task_types.find(
          (elem) => elem.default == true
        ).id;
        self.priority = singleprojectitem.priorities.find(
          (elem) => elem.default == true
        ).id;
        self.status = singleprojectitem.statuses.find(
          (elem) => elem.default == true
        ).id;
        self.projectobject = singleprojectitem;
      }
      this.project = this.ParamProject ? this.ParamProject : "";
      return this.project;
    },
    computedstatus() {
      if (this.Paramstatus) {
        this.status = this.Paramstatus;
      }
      return (this.status = this.Paramstatus ? this.Paramstatus : "");
    },
    computedStartDate() {
      return this.startDate ? this.startDate : "";
    },
    computedEndDate() {
      return this.endDate ? this.endDate : "";
    },
    workspaces() {
      return this.$store.state.mywork.userworkspaces;
    },
    user() {
      return JSON.parse(this.$store.state.account.user);
    },
  },
  methods: {
    assigntaskcustomfield(items, dropattribute) {
      for (var i = 0; i < this.projectobject.customFields.length; i++) {
        if (items.id == this.projectobject.customFields[i].id) {
          if (dropattribute) {
            this.projectobject.customFields[i].value = dropattribute.name;
            this.projectobject.customFields[i].attributeValueId =
              dropattribute.id;
          } else {
            this.projectobject.customFields[i].value = items.value;
            this.projectobject.customFields[i].attributeValueId = "";
          }
        }
      }
    },
    getActiveitem(data) {
      var returndata = [];
      if (data && data.length > 0) {
        returndata = data.filter((data) => data.activeStatus == true);
      }
      return returndata;
    },
    checksubmitevent(event) {
      const keyCode = event.which || event.keyCode;
      if (keyCode === 13 && !event.shiftKey && this.btnLoading != true) {
        this.addTask();
      }
    },
    gototask() {
      this.$router.push({
        name: "single-task",
        params: {
          id: this.lastaddedtask.project,
          taskId: this.lastaddedtask.taskid,
        },
        query: {
          companyId: this.$route.query.companyId,
        },
      });
      this.showSnackBar = false;
    },
    createDialogOpen() {
      var formatedcalendar = moment(this.calendardate).format("YYYY-MM-DD");
      if (this.view == "calendar" && this.calendardate) {
        this.minEndDate = formatedcalendar;
        this.startDate = formatedcalendar;
        this.endDate = formatedcalendar;
      } else {
        this.minEndDate = "";
        this.startDate = "";
        this.endDate = "";
      }
      this.createDialog = true;
      const filter_button_today = document.querySelector(".bar-wrapper.active");
      this.$nextTick(() => {
        this.$validator.reset();
      });
      if (filter_button_today) {
        const add_block = document.querySelectorAll(".popup-wrapper");
        var i;
        for (i = 0; i < add_block.length; i++) {
          add_block[i].classList.add("displayOption");
        }
      }
    },
    openinputboxforstatusaddtask() {
      this.addtask.statusid = this.Paramstatus;
      this.addtask.status = true;
      this.status = this.Paramstatus ? this.Paramstatus : "";
      this.project = this.ParamProject ? this.ParamProject : "";
      this.workspace = this.ParamWorkspace ? this.ParamWorkspace : "";
    },
    disablecontent() {
      this.activeinput = false;
    },
    selectworkspace(item) {
      this.workspace = item.id;
      this.emptyprojectidrelateddata();
      var projectarray = [];
      this.$store.state.mywork.userprojects.forEach((data) => {
        if (item.id == data.workspace) {
          projectarray.push(data);
        }
      });
      this.projectarray = projectarray;
    },
    selectproject(item) {
      this.tasktype = item.task_types.find((elem) => elem.default == true).id;
      this.priority = item.priorities.find((elem) => elem.default == true).id;
      this.status = item.statuses.find((elem) => elem.default == true).id;
      this.project = item.id;
      item.statuses = this.orderdata(item.statuses);
      item.priorities = this.orderdata(item.priorities);
      this.projectobject = item;
    },
    orderdata(array) {
      return array.sort(function (a, b) {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });
    },
    addTask() {
      var self = this;
      const filter_button_today = document.querySelector(".bar-wrapper.active");
      if (filter_button_today) {
        const add_block = document.querySelectorAll(".popup-wrapper");
        var i;
        for (i = 0; i < add_block.length; i++) {
          add_block[i].classList.add("displayOption");
        }
      }
      this.$validator.validateAll("create-task").then((res) => {
        if (res) {
          this.submitdisablestatus = true;
          var dateitem = [];
          if (self.startDate && self.endDate) {
            dateitem.push(new Date(self.startDate).getTime());
            dateitem.push(new Date(self.endDate).getTime());
          }
          if (
            this.projectobject.customFields &&
            this.projectobject.customFields.length > 0
          ) {
            for (const taskcustomfield of this.projectobject.customFields) {
              try {
                this.taskcustomfields.push({
                  projectcustomId: taskcustomfield.id,
                  name: taskcustomfield.name,
                  attributeValueId: taskcustomfield.attributeValueId
                    ? taskcustomfield.attributeValueId
                    : "",
                  value: taskcustomfield.value ? taskcustomfield.value : "",
                  requiredStatus: taskcustomfield.requiredStatus,
                  inputType: taskcustomfield.inputType,
                });
              } catch (error) {
                console.log(error);
              }
            }
          }
          var formData = {
            taskName: self.taskName,
            taskDescription: self.taskDescription,
            selectedUsers: self.selectedUsers,
            hours: self.hours,
            minutes: self.minutes,
            priority: self.priority,
            status: self.status,
            actionRequiredBy: self.actionRequiredBy,
            startEndDate: dateitem,
            tasktype: self.tasktype,
            projectid: self.project,
            taskcustomfields: self.taskcustomfields,
            parentaskid: self.parentask ? self.parentask.id : "",
            isSubtask: self.subtask,
          };
          self.btnLoading = true;
          self.$http
            .post(
              process.env.VUE_APP_ROOT_API + "task/create-task/" + self.project,
              formData
            )
            .then((response) => {
              var res = response.data.task;
              bus.$emit("createtask_lock", res);
              self.$store.dispatch("project/addTask", res);
              self.$store.commit(
                "workspace/updatetaskcount",
                response.data.task.projectinfo.workspace
              );
              if (response.data.projectusers.length > 0) {
                self.$store.commit("mywork/assignProjectUsers", response.data);
                self.$store.dispatch(
                  "project/getProjectUsers",
                  response.data.task.project
                );
              }
              self.addtask.statusid = "";
              self.addtask.status = false;
              self.$nextTick(() => {
                if (self.actionRequiredBy) {
                  self.$store.dispatch(
                    "project/getProjectUsers",
                    response.data.task.project
                  );
                }
                self.lastaddedtask.name = res.title;
                self.lastaddedtask.project = res.project;
                self.lastaddedtask.taskid = res._id;
                self.messagecolor = "primary";
                self.snackMessage = "Task created successfully.";
                self.showSnackBar = true;
                self.btnLoading = false;
                self.closeCreateDialog();
              });
            })
            .catch((error) => {
              self.btnLoading = false;
              if (
                error.response &&
                error.response.data &&
                error.response.data.message
              ) {
                self.messagecolor = "red";
                self.snackMessage = error.response.data.message;
                self.showSnackBar = true;
              }
            });
        }
      });
    },
    changePriority(item) {
      this.priority = item.id;
    },
    changeStatus(item) {
      this.status = item.id;
    },
    closeCreateDialog() {
      this.$emit("closestatus", true);
      this.createDialog = false;
      this.selectedUsers = [];
      this.submitdisablestatus = false;
      if (this.global == true) {
        this.project = "";
      }
      this.startDate = "";
      this.endDate = "";
      if (this.global == true) {
        this.priority = "";
        this.status = "";
        this.tasktype = "";
        if (
          this.projectobject.customFields &&
          this.projectobject.customFields.length > 0
        ) {
          for (const taskcustomfield of this.projectobject.customFields) {
            taskcustomfield.value = "";
          }
        }
        this.projectobject = {};
        this.projectarray = [];
        // this.workspaces = [];
      } else {
        this.tasktype = this.projectobject.task_types.find(
          (elem) => elem.default == true
        ).id;
        this.priority = this.projectobject.priorities.find(
          (elem) => elem.default == true
        ).id;
        this.status = this.projectobject.statuses.find(
          (elem) => elem.default == true
        ).id;
      }
      this.dateitem = "";
      this.taskName = "";
      this.taskDescription = "";
      this.hours = "";
      this.minutes = "";
      this.actionRequiredBy = "";
      this.workspace = "";
      this.$nextTick(() => {
        this.$validator.reset();
      });
      this.taskcustomfields = [];
      if (
        this.projectobject.customFields &&
        this.projectobject.customFields.length > 0
      ) {
        for (const taskcustomfield of this.projectobject.customFields) {
          taskcustomfield.value = "";
        }
      }
    },
    emptyprojectidrelateddata() {
      // this.projectarray = "";[WHILE EXICUTING THIS LINE COMPUTED FUNCTION THROWING AN ERROR]
      this.selectedUsers = [];
      this.priority = "";
      this.status = "";
      this.tasktype = "";
    },
  },
};
</script>

<style>
.add-task-sticky.sticky-bar {
  position: sticky;
  bottom: 0;
}

.theme--light .add-task-sticky.sticky-bar {
  background: #fff;
}

.theme--dark .add-task-sticky.sticky-bar {
  background: #333;
}

.add-users-lists .v-list-item__action {
  margin-right: 5px !important;
}

.add-users-lists .v-select__slot {
  margin: 5px 0 !important;
}

.add-users-lists .v-list-item__title {
  white-space: normal;
}

.add-users-lists .v-chip {
  margin: 3px !important;
}

.action-required-req .v-list-item__title {
  white-space: normal;
}

.radio-group-width {
  width: 150px;
}

.radio-button-section .v-radio.v-list__group__header__prepend-icon {
  align-items: flex-start;
  padding-bottom: 1em;
}

.radio-button-section .v-radio.v-list__group__header__prepend-icon label {
  width: 200px;
}

.add-tasks-status .v-select__selections input {
  display: none;
}

.theme--light .add-task-sticky.sticky-bar {
  background: #fff;
}

.theme--dark .add-task-sticky.sticky-bar {
  background: #333;
}

.add-users-lists .v-list-item__action {
  margin-right: 5px !important;
}

.add-users-lists .v-list-item__title {
  white-space: normal;
}

.action-required-req .v-list-item__title {
  white-space: normal;
}

.radio-group-width {
  width: 150px;
}

.radio-button-section .v-radio.v-list__group__header__prepend-icon {
  align-items: flex-start;
  padding-bottom: 1em;
}

.radio-button-section .v-radio.v-list__group__header__prepend-icon label {
  width: 200px;
}

.add-tasks-status .v-select__selections input {
  display: none;
}

.v-list-item__title {
  white-space: normal !important;
}

.add-users-lists .v-chip {
  height: auto;
}

.add-users-lists .v-chip__content {
  max-width: 100%;
  height: auto;
  min-height: 25px;
  white-space: pre-wrap;
}

@media (max-width: 767px) {
  .radio-group-width {
    width: 120px;
  }

  .radio-button-section .v-input--selection-controls.v-input {
    margin-top: 0;
  }

  .radio-button-section .v-messages {
    display: none;
  }

  .radio-button-section .v-radio.v-list__group__header__prepend-icon {
    align-items: flex-start;
    padding-bottom: 0em;
  }
}
</style>
